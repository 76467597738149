import { Box, SxProps, Theme, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useIntl } from 'react-intl';
import { useSingleSourceStore } from '../../../../store/single-source-store/single-source-store';
import { useCallback } from 'react';

export const AddSpace = () => {
  const { formatMessage: f } = useIntl();
  const setShowCreateSpaceModal = useSingleSourceStore.use.setShowCreateSpaceModal();

  const openCreateSpaceModal = useCallback(() => {
    setShowCreateSpaceModal(true);
  }, [setShowCreateSpaceModal]);

  return (
    <Box sx={addSpaceSx} onClick={openCreateSpaceModal}>
      <AddCircleOutlineIcon color="primary" />
      <Typography variant="subtitle1" color="text.primary">
        {f({ id: 'create-new-space' })}
      </Typography>
    </Box>
  );
};

const addSpaceSx: SxProps<Theme> = {
  border: '1px solid',
  borderColor: 'primary.main',
  borderRadius: 2,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 1.5,
  p: 1,
  height: '200px',
  '&:hover': {
    backgroundColor: 'base.primaryBackground',
    borderWidth: '2px',
    cursor: 'pointer',
  },
};

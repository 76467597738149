import { Stack } from '@mui/material';
import { Allotment, AllotmentHandle } from 'allotment';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Sidebar } from '../../components/spaces/sidebar/sidebar';
import { useAuth } from '../../auth';
import { Spaces } from '../../components/spaces/spaces';
import { CreateWorkspace } from '../../pages/create-workspace/create-workspace';
import { useSingleSourceStore } from '../../store/single-source-store/single-source-store';
import { SpaceRenameModal } from '../../components/space-rename-modal/space-rename-modal';
import { SpaceDeleteModal } from '../../components/space-delete-modal/space-delete-modal';
import { SpacesSidebarLinkValues } from '../../components/spaces/sidebar/list/links-list';

export const SpacesRoute = () => {
  const ref = useRef<AllotmentHandle>(null);
  const { user } = useAuth();
  const [collapse, setCollapse] = useState<boolean>(false);
  const [selectedView, setSelectedView] = useState(SpacesSidebarLinkValues.All);
  const showCreateSpaceModal = useSingleSourceStore.use.showCreateSpaceModal();

  const defaultSizes = useMemo(() => {
    const DEFAULT = [300, 2000];
    const x = JSON.parse(localStorage.getItem('spaces-allotments') ?? 'null');
    if (!x || x.length !== DEFAULT.length) localStorage.setItem('spaces-allotments', JSON.stringify(DEFAULT));
    return x ?? DEFAULT;
  }, []);

  const onDragEnd = useCallback((sizes: number[]) => {
    localStorage.setItem('spaces-allotments', JSON.stringify(sizes));
  }, []);

  return (
    <Stack data-testid="spaces-route" height="100%" width="100%">
      <Allotment
        separator
        defaultSizes={defaultSizes}
        proportionalLayout={true}
        vertical={false}
        ref={ref}
        onDragEnd={onDragEnd}
      >
        <Allotment.Pane minSize={collapse ? 64 : 300} maxSize={collapse ? 64 : 1000}>
          <Sidebar
            profile={user}
            collapsed={collapse}
            onCollapse={() => setCollapse(!collapse)}
            selectedView={selectedView}
            setSelectedView={setSelectedView}
          />
        </Allotment.Pane>
        <Allotment.Pane>
          <Spaces type={selectedView} />
        </Allotment.Pane>
      </Allotment>
      {showCreateSpaceModal ? <CreateWorkspace /> : null}
      <SpaceRenameModal />
      <SpaceDeleteModal />
    </Stack>
  );
};

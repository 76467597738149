import { Box, Stack, Typography } from '@mui/material';
import { useCallback } from 'react';
import { getRoutePathFromParams, RoutePaths } from '../../config/route-paths/route-paths';
import { MenuItem, MenuOption } from './menu-option/menu-option';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { NavMenuLabels } from './labels/nav-menu-labels';
import { useActiveWorkspace } from '../../hooks/use-active-workspace';
import { useActiveOrganization } from '../../hooks/use-active-organization';
import HomeIcon from '@mui/icons-material/Home';
import { NavBarIconButton } from '../nav-bar/nav-bar-icon-button';

const NavMenuOptions = (props: { options: MenuItem[] }) => {
  const { options } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        width: { xs: '237px', md: '100%' },
        height: { md: '100%' },
      }}
    >
      {options.map((option) => (
        <MenuOption key={`NavMenu${option.id}`} option={option} />
      ))}
    </Box>
  );
};

export const NavMenu = ({ hideWorkspaces }: { hideWorkspaces?: boolean }) => {
  const { formatMessage: f } = useIntl();
  const navigate = useNavigate();

  const { organization } = useActiveOrganization();
  const { workspace, setWorkspace } = useActiveWorkspace();

  const navigateToSpaces = useCallback(() => {
    if (!organization) return;
    navigate(getRoutePathFromParams(RoutePaths.Spaces, { organizationName: organization.name }), {
      state: {
        preventNavigation: true,
      },
    });
  }, [navigate, organization]);

  return (
    <Box height="100%">
      {!hideWorkspaces ? (
        <Stack direction="row" alignItems="center" gap={1} height="100%">
          <NavBarIconButton
            id="nav-spaces-button"
            icon={<HomeIcon sx={{ color: 'text.secondary', cursor: 'pointer' }} />}
            onClick={navigateToSpaces}
          />
          <Box sx={{ display: 'flex' }}>
            {workspace ? <Typography sx={{ my: 'auto' }}>{workspace.name}</Typography> : null}
          </Box>
          <Box
            sx={{
              height: '100%',
              display: { xs: 'none', md: 'block' },
            }}
          >
            <NavMenuLabels />
          </Box>
        </Stack>
      ) : null}
    </Box>
  );
};

import { Handle, NodeProps, Position, Node } from '@xyflow/react';
import { AttachmentItem } from '../attachment-item/attachment-item';
import { Box, IconButton, SxProps, Theme } from '@mui/material';
import { FocusEventHandler, Fragment, memo, MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { AttachmentNodeData, EntityType, Upload } from '@xspecs/single-source-model';
import { useApplication } from '../../../../../wrappers/application-context/application-context';
import { Icon } from '@xspecs/design-system';
import { FloatingMenu, useFloatingMenu } from '../../floating-menu/floating-menu';

export const AttachmentNode = (props: NodeProps<Node<AttachmentNodeData>>) => {
  const { id, type, selected, data } = props;
  const { application } = useApplication();
  const {
    showFloating,
    floatingStyles,
    referenceRef,
    floatingRef,
    context,
    arrowRef,
    referenceProps,
    floatingProps,
    onManageLabels,
  } = useFloatingMenu();
  const [clickCount, setClickCount] = useState(0);

  const onToggleEntityVisibility = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e.stopPropagation();
      application?.model.interactor.toggleEntityVisibilityExpanded(id);
    },
    [application?.model.interactor, id],
  );

  const onWrapperClick = useCallback<MouseEventHandler<HTMLDivElement>>((event) => {
    setClickCount(Math.min(event.detail, 2));
  }, []);

  const onWrapperBlur = useCallback<FocusEventHandler<HTMLDivElement>>(() => {
    setClickCount(0);
  }, []);

  const wrapperSx = useMemo<SxProps<Theme>>(
    () => ({
      backgroundColor: 'transparent',
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 1,
    }),
    [],
  );

  useEffect(() => {
    if (!selected) setClickCount(0);
  }, [selected]);

  return (
    <>
      <Box ref={referenceRef} position="relative" {...referenceProps} width="100%" height="100%">
        <Box position="relative" onClick={onWrapperClick} onBlur={onWrapperBlur}>
          {clickCount < 1 ? <Box sx={wrapperSx} /> : null}
          <AttachmentItem attachmentId={id} data={data} selected={Boolean(selected)} hasOverlay={data.hasOverlay} />
        </Box>
        {data.hasParent && !data.isDragging ? <Staples type={data.subType} /> : null}
        {data.subType === EntityType.Upload ? (
          <IconButton sx={expandButtonSx} onClick={onToggleEntityVisibility}>
            <Icon name="expand-image" width={20} height={20} />
          </IconButton>
        ) : null}
      </Box>
      {showFloating && data.asset ? (
        <FloatingMenu
          ref={floatingRef}
          id={data.asset.id}
          constructToPanTo={data.id}
          styles={floatingStyles}
          arrowRef={arrowRef}
          context={context}
          type={type}
          labels={data.labels}
          onManageLabels={onManageLabels}
          disableLabels
          name={data.name}
          withCopy
          subType={data.subType}
          url={data.asset instanceof Upload ? data.asset.url : undefined}
          {...floatingProps}
        />
      ) : null}
      {data.subType === EntityType.Upload ? <Handles /> : null}
    </>
  );
};

const Handles = () => {
  return (
    <Fragment>
      <Handle id={Position.Left} type="source" position={Position.Left} style={style} />
      <Handle id={Position.Right} type="source" position={Position.Right} style={style} />
      <Handle id={Position.Top} type="source" position={Position.Top} style={style} />
      <Handle id={Position.Bottom} type="source" position={Position.Bottom} style={style} />
    </Fragment>
  );
};

const style = {
  opacity: 0,
};

const Staples = memo(({ type }: { type: string }) => {
  const top = type === EntityType.Actor ? 26 : -12;
  return (
    <Fragment>
      <div
        style={{
          width: '2px',
          minWidth: '2px',
          height: 12,
          backgroundColor: '#aeaeae',
          position: 'absolute',
          top,
          left: `${20}%`,
        }}
      />
      <div
        style={{
          width: '2px',
          minWidth: '2px',
          height: 12,
          backgroundColor: '#aeaeae',
          position: 'absolute',
          top,
          left: `${22}%`,
        }}
      />
      <div
        style={{
          width: '2px',
          minWidth: '2px',
          height: 12,
          backgroundColor: '#aeaeae',
          position: 'absolute',
          top,
          left: `${80}%`,
        }}
      />
      <div
        style={{
          width: '2px',
          minWidth: '2px',
          height: 12,
          backgroundColor: '#aeaeae',
          position: 'absolute',
          top,
          left: `${82}%`,
        }}
      />
    </Fragment>
  );
});
Staples.displayName = 'Staples';

const expandButtonSx = {
  p: 0.25,
  position: 'absolute',
  right: 0,
  top: '50%',
  transform: 'translate(50%, -50%)',
  zIndex: 10,
  backgroundColor: 'background.paper',
  border: '1px solid #aeaeae',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': {
    backgroundColor: 'background.paper',
  },
};

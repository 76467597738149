import { EdgeToolbarElement } from './EdgeToolbar.types';
import { defaultColor, defaultLabelColor } from './Edge.constants';

export const EDGE_TOOLBAR_ITEMS: EdgeToolbarElement[] = [
  {
    type: 'icon-button',
    name: 'markerStart',
    icon: 'centered-line', // centered-line, arrow-left, or arrow-right depending on state
    tooltip: 'Line start',
    menu: {
      asRow: false,
      onSelect: {
        command: 'UpdateEdgeCommand',
        params: {
          key: 'update-marker-start',
          valueKey: 'markerStart',
        },
      },
      items: [
        {
          label: 'None',
          value: 'none',
          icon: 'centered-line',
          selected: false,
        },
        {
          label: 'Arrow',
          value: 'arrow',
          icon: 'arrow-left', // can never be arrow-right. This represents the source
          selected: true,
        },
      ],
    },
  },
  {
    type: 'icon-button',
    name: 'swapMarkers',
    icon: 'swap-horiz',
    tooltip: 'Swap',
    onClick: {
      command: 'UpdateEdgeCommand',
      params: {
        key: 'swap-markers',
      },
    },
  },
  {
    type: 'icon-button',
    name: 'markerEnd',
    icon: 'arrow-left', // centered-line, arrow-left, or arrow-right depending on state
    tooltip: 'Line end',
    menu: {
      asRow: false,
      onSelect: {
        command: 'UpdateEdgeCommand',
        params: {
          key: 'update-marker-end',
          valueKey: 'markerEnd',
        },
      },
      items: [
        {
          label: 'None',
          value: 'none',
          icon: 'centered-line',
          selected: false,
        },
        {
          label: 'Arrow',
          value: 'arrow',
          icon: 'arrow-right', // can never be arrow-right. This represents the source
          selected: true,
        },
      ],
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'icon-button',
    name: 'edgeColor',
    icon: 'format-color-fill',
    tooltip: 'Line color',
    menu: {
      asRow: true,
      onSelect: {
        command: 'UpdateEdgeCommand',
        params: {
          key: 'update-color',
          valueKey: 'color',
        },
      },
      items: [
        { color: defaultColor, selected: true },
        { color: '#E0E0E0', selected: false },
        { color: '#FFFFFF', selected: false },
        { color: '#673AB7', selected: false },
        { color: '#9E9E9E', selected: false },
        { color: '#212121', selected: false },
        { color: '#C5CAE9', selected: false },
        { color: '#FFB300', selected: false },
        { color: '#FF6F00', selected: false },
        { color: '#C51162', selected: false },
        { color: '#F06292', selected: false },
        { color: '#F48FB1', selected: false },
        { color: '#AA00FF', selected: false },
        { color: '#007DFF', selected: false },
        { color: '#E3F2FD', selected: false },
        { color: '#00BFA5', selected: false },
        { color: '#00796B', selected: false },
        { color: '#00B8D4', selected: false },
        { color: '#18FFFF', selected: false },
        { color: '#AEEA00', selected: false },
        { color: '#827717', selected: false },
        { color: '#DCE775', selected: false },
        { color: '#F1F8E9', selected: false },
        { color: '#D50000', selected: false },
        { color: '#FFF8E1', selected: false },
        { color: '#FFFF00', selected: false },
      ],
    },
  },
  {
    type: 'icon-button',
    icon: 'line-style',
    name: 'lineStyle',
    tooltip: 'Line style',
    menu: {
      asRow: false,
      onSelect: {
        command: 'UpdateEdgeCommand',
        params: {
          key: 'update-line-style',
          valueKey: 'lineStyle',
        },
      },
      items: [
        {
          label: 'Solid',
          value: 'solid',
          icon: 'centered-line',
          selected: true,
        },
        {
          label: 'Dashed',
          value: 'dashed',
          icon: 'dashed-line',
          selected: false,
        },
        {
          label: 'Dotted',
          value: 'dotted',
          icon: 'dotted-line',
          selected: false,
        },
      ],
    },
  },
  {
    type: 'icon-button',
    icon: 'line-weight',
    name: 'lineWeight',
    tooltip: 'Line weight',
    menu: {
      asRow: false,
      onSelect: {
        command: 'UpdateEdgeCommand',
        params: {
          key: 'update-line-weight',
          valueKey: 'lineWeight',
        },
      },
      items: [
        {
          label: '0.5 px',
          value: '0.5',
          icon: 'line',
          iconStrokeWidth: 0.5,
          selected: false,
        },
        {
          label: '1 px',
          value: '1',
          icon: 'line',
          iconStrokeWidth: 1,
          selected: false,
        },

        {
          label: '2 px',
          value: '2',
          icon: 'line',
          iconStrokeWidth: 2,
          selected: true,
        },
        {
          label: '3 px',
          value: '3',
          icon: 'line',
          iconStrokeWidth: 3,
          selected: false,
        },
        {
          label: '4 px',
          value: '4',
          icon: 'line',
          iconStrokeWidth: 4,
          selected: false,
        },
        {
          label: '5 px',
          value: '5',
          icon: 'line',
          iconStrokeWidth: 5,
          selected: false,
        },
        {
          label: '10 px',
          value: '10',
          icon: 'line',
          iconStrokeWidth: 10,
          selected: false,
        },
        {
          label: '15 px',
          value: '15',
          icon: 'line',
          iconStrokeWidth: 15,
          selected: false,
        },
      ],
    },
  },
  {
    type: 'icon-button',
    name: 'lineType',
    icon: 'curved-edge',
    tooltip: 'Line type',
    menu: {
      asRow: false,
      onSelect: {
        command: 'UpdateEdgeCommand',
        params: {
          key: 'update-line-type',
          valueKey: 'lineType',
        },
      },
      items: [
        {
          label: 'Curved',
          value: 'curved',
          icon: 'curved-edge',
          selected: false,
        },
        {
          label: 'Orthogonal',
          value: 'orthogonal',
          icon: 'orthogonal-edge',
          selected: false,
        },
        {
          label: 'Straight',
          value: 'straight',
          icon: 'straight-edge',
          selected: false,
        },
      ],
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'icon-button',
    icon: 'format-color-text',
    name: 'labelColor',
    tooltip: 'Label color',
    menu: {
      asRow: true,
      onSelect: {
        command: 'UpdateEdgeCommand',
        params: {
          key: 'update-label-color',
          valueKey: 'labelColor',
        },
      },
      items: [
        { color: defaultLabelColor, selected: true },
        { color: '#E0E0E0', selected: false },
        { color: '#FFFFFF', selected: false },
        { color: '#673AB7', selected: false },
        { color: '#9E9E9E', selected: false },
        { color: '#212121', selected: false },
        { color: '#C5CAE9', selected: false },
        { color: '#FFB300', selected: false },
        { color: '#FF6F00', selected: false },
        { color: '#C51162', selected: false },
        { color: '#F06292', selected: false },
        { color: '#F48FB1', selected: false },
        { color: '#AA00FF', selected: false },
        { color: '#007DFF', selected: false },
        { color: '#E3F2FD', selected: false },
        { color: '#00BFA5', selected: false },
        { color: '#00796B', selected: false },
        { color: '#00B8D4', selected: false },
        { color: '#18FFFF', selected: false },
        { color: '#AEEA00', selected: false },
        { color: '#827717', selected: false },
        { color: '#DCE775', selected: false },
        { color: '#F1F8E9', selected: false },
        { color: '#D50000', selected: false },
        { color: '#FFF8E1', selected: false },
        { color: '#FFFF00', selected: false },
      ],
    },
  },
  {
    type: 'stepper',
    value: 14,
    name: 'labelFontSize',
    tooltip: 'Label font size',
    menu: {
      onSelect: {
        command: 'UpdateEdgeCommand',
        params: {
          key: 'update-font-size',
          valueKey: 'labelFontSize',
        },
      },
      items: [
        {
          label: '10',
          value: 10,
          selected: false,
        },
        {
          label: '12',
          value: 12,
          selected: false,
        },
        {
          label: '14',
          value: 14,
          selected: true,
        },
        {
          label: '18',
          value: 18,
          selected: false,
        },
        {
          label: '24',
          value: 24,
          selected: false,
        },
        {
          label: '36',
          value: 36,
          selected: false,
        },
        {
          label: '48',
          value: 48,
          selected: false,
        },
        {
          label: '64',
          value: 64,
          selected: false,
        },
      ],
    },
    onIncrementClick: {
      command: 'UpdateEdgeCommand',
      params: {
        key: 'update-font-size',
      },
    },
    onDecrementClick: {
      command: 'UpdateEdgeCommand',
      params: {
        key: 'update-font-size',
      },
    },
  },
  {
    type: 'divider',
  },
  {
    type: 'icon-button',
    icon: 'more-horiz',
    name: 'more',
    menu: {
      asRow: false,
      onSelect: {
        command: 'UpdateEdgeCommand',
        params: {
          key: 'more-action',
          valueKey: 'moreActionValue',
        },
      },
      items: [
        // {
        //   label: 'Copy style',
        //   value: 'copyStyle',
        //   icon: 'copy',
        //   subtitle: `${metaKeys.modKey}+C`,
        //   disabled: false,
        // },
        // {
        //   label: 'Paste style',
        //   value: 'pasteStyle',
        //   icon: 'paste',
        //   subtitle: `${metaKeys.modKey}+V`,
        //   disabled: false,
        // },
        {
          label: 'Delete',
          value: 'deleteEdge',
          icon: 'delete',
          disabled: false,
        },
      ],
    },
  },
];

import { EntityRepository } from '../data/EntityRepository';
import { ScriptBase } from '../entities/scripts/ScriptBase';

export const getTargetDxDyBetweenEntities = (
  repository: EntityRepository,
  entityId1: string,
  entityId2: string,
  frameIndex?: number,
  laneIndex?: number,
) => {
  const entity1 = repository.get(entityId1)!;
  const entity2 = repository.get(entityId2)!;
  let targetPosition: { x: number; y: number };
  if (laneIndex !== undefined && frameIndex !== undefined && entity2 instanceof ScriptBase) {
    targetPosition = entity2.getIngestPosition(frameIndex, laneIndex);
    return {
      x: targetPosition.x - entity1.position.x,
      y: targetPosition.y - entity1.position.y,
    };
  } else {
    targetPosition = entity2.position;
  }
  return {
    x: entity1.position.x - targetPosition.x,
    y: entity1.position.y - targetPosition.y,
  };
};

export const getCursorPosition = (
  repository: EntityRepository,
  sourceEntity: string,
  targetEntity: string,
  frameIndex: number | undefined = undefined,
  laneIndex: number | undefined = undefined,
): { x: number; y: number } => {
  const targetDxDy = getTargetDxDyBetweenEntities(repository, sourceEntity, targetEntity, frameIndex, laneIndex);
  const entity2 = repository.get(targetEntity);
  if (!entity2) throw new Error(`Entity with id ${targetEntity} not found`);
  if (frameIndex !== undefined || laneIndex !== undefined) {
    const entity1 = repository.get(sourceEntity) as ScriptBase;
    return entity1.getIngestPosition(frameIndex!, laneIndex!);
  }
  return { x: entity2.position.x + targetDxDy.x, y: entity2.position.y + targetDxDy.y };
};

import { EntityType } from './EntityType';

export class EntityTypeRegistry {
  private static _dynamicEntityTypes: Set<string> = new Set(['DynamicFallbackAsset']);

  public static isRegisteredEntityType(type: string): boolean {
    return this.getAllEntityTypes().has(type);
  }

  public static register(type: string): void {
    if (this.isRegisteredEntityType(type)) {
      return;
    }
    this._dynamicEntityTypes.add(type);
  }

  public static deregister(type: string): void {
    if (!this.isRegisteredEntityType(type)) {
      return;
    }
    this._dynamicEntityTypes.delete(type);
  }

  static getAllEntityTypes(): Set<string> {
    const staticEntityTypes = Object.values(EntityType);
    return new Set([...staticEntityTypes, ...this._dynamicEntityTypes]);
  }
}

export const DottedLine = () => {
  return (
    <>
      <circle cx="6" cy="12" r="1" fill="black" fillOpacity="0.54" />
      <circle cx="9" cy="12" r="1" fill="black" fillOpacity="0.54" />
      <circle cx="12" cy="12" r="1" fill="black" fillOpacity="0.54" />
      <circle cx="15" cy="12" r="1" fill="black" fillOpacity="0.54" />
      <circle cx="18" cy="12" r="1" fill="black" fillOpacity="0.54" />
    </>
  );
};

import {
  Box,
  Button,
  Checkbox,
  createFilterOptions,
  List,
  ListItem,
  Stack,
  SxProps,
  TextField,
  Theme,
  Typography,
  useAutocomplete,
  UseAutocompleteProps,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { MouseEventHandler, useMemo } from 'react';
import { LabelColor } from '../label-color/label-color';
import { LabelsSelectorHeader } from './header/labels-selector-header';
import { Label, LabelTemp } from '@xspecs/single-source-model';
import { sid } from '@xspecs/short-id';
import { getRandomLabelColor } from '../labels-color-select/labels-color-select';
import { Icon } from '@xspecs/design-system';
import { FilterSelectorBackButton } from './header/filter-selector-back-button';
import { NoSearchResults } from '../../no-search-results/no-search-results';

export type LabelsSelectorProps = {
  onManageLabels?: MouseEventHandler<HTMLButtonElement>;
  labels: LabelTemp[];
  selectedLabels: LabelTemp[];
  onSelectedLabelsChange: (labels: Label[]) => void;
  createAndSelectLabel: (label: LabelTemp) => void;
  hideHeader?: boolean;
  hideManageLabels?: boolean;
  placeholder?: string;
  disableFreeSolo?: boolean;
  goBack?: () => void;
};

const filter = createFilterOptions<LabelTemp>();

export const LabelsSelector = (props: LabelsSelectorProps) => {
  const {
    onManageLabels,
    labels,
    onSelectedLabelsChange,
    createAndSelectLabel,
    selectedLabels = [],
    hideManageLabels,
    hideHeader,
    placeholder,
    disableFreeSolo = false,
    goBack,
  } = props;

  const { formatMessage: f } = useIntl();

  const autoCompleteProps = useMemo<UseAutocompleteProps<LabelTemp, true, true, boolean>>(() => {
    return {
      open: true,
      multiple: true,
      freeSolo: !disableFreeSolo,
      value: selectedLabels,
      onChange: (event, value, reason, details) => {
        const [name] = value.filter((label) => typeof label === 'string') as string[];
        const inputValue = Array.isArray(value) ? value.find((v) => v.hasOwnProperty('inputValue')) : value;

        if (inputValue) {
          createAndSelectLabel({
            id: sid(),
            // @ts-ignore
            name: inputValue.inputValue,
            color: getRandomLabelColor(),
          });
          return;
        }

        if (name) {
          createAndSelectLabel({ id: sid(), name, color: getRandomLabelColor() });
          return;
        }
        const newLabels = value as Label[];
        onSelectedLabelsChange(newLabels);
      },
      options: labels,
      getOptionLabel: (option) => (typeof option === 'string' ? option : option.name),
      filterOptions: (options, params) => {
        const filtered = filter(options, params);
        if (disableFreeSolo) return filtered;
        const { inputValue } = params;
        const isExisting = options.some((option) => inputValue === option.name);
        if (inputValue !== '' && inputValue.trim() != '' && !isExisting) {
          // @ts-ignore
          filtered.push({ inputValue, name: `Add "${inputValue}"` });
        }
        return filtered;
      },
    };
  }, [disableFreeSolo, selectedLabels, labels, onSelectedLabelsChange, createAndSelectLabel]);

  const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } =
    useAutocomplete(autoCompleteProps);

  const inputProps = getInputProps();

  return (
    <Stack gap={1} alignItems="flex-start" {...getRootProps()}>
      <Stack sx={inputWrapperSx} gap={1.5}>
        {!hideHeader ? <LabelsSelectorHeader /> : null}
        <TextField
          fullWidth
          placeholder={placeholder ?? f({ id: 'search-or-add' })}
          inputProps={inputProps}
          size="small"
          InputProps={{
            startAdornment: <Icon name="search" />,
          }}
        />
      </Stack>
      {goBack ? <FilterSelectorBackButton goBack={goBack} /> : null}
      <List {...getListboxProps()} sx={listSx}>
        <Box px={2}>
          {groupedOptions.length === 0 && !inputProps.value ? (
            <Typography variant="body2" color="text.primary">
              {f({ id: 'no-filters-created' }, { filter: 'Labels' })}
            </Typography>
          ) : null}
          {groupedOptions.length === 0 && inputProps.value ? <NoSearchResults /> : null}
          {groupedOptions.map((option, index) => {
            const isOption = Boolean(option.id);
            return (
              <ListItem
                {...getOptionProps({ option, index })}
                key={`LabelsSelector-option-${index}`}
                sx={{ p: 0, height: 38, px: isOption ? undefined : 2 }}
              >
                {isOption ? (
                  <Checkbox
                    size="small"
                    sx={{ px: 1 }}
                    checked={Boolean(selectedLabels.find((label) => label.id === option.id))}
                  />
                ) : null}
                <Typography variant="body2">{option.name}</Typography>
                {isOption ? <LabelColor color={option.color} rootSx={{ ml: 'auto', mr: 2 }} /> : null}
              </ListItem>
            );
          })}
        </Box>
      </List>

      {!hideManageLabels ? (
        <Box sx={{ px: 2, width: '100%' }}>
          <Button onClick={onManageLabels}>{f({ id: 'manage-labels' })}</Button>
        </Box>
      ) : null}
    </Stack>
  );
};

const inputWrapperSx: SxProps<Theme> = {
  px: 2,
  pb: 1,
  width: '100%',
  borderBottom: '1px solid',
  borderColor: 'divider',
};

const listSx: SxProps<Theme> = {
  width: '100%',
  maxHeight: 294,
  height: 294,
  overflowY: 'scroll',
  padding: 0,
  '& li.Mui-focused': {
    backgroundColor: 'action.hover',
    color: 'text.primary',
    cursor: 'pointer',
  },
  '& li:active': {
    backgroundColor: 'action.selected',
    color: 'text.primary',
  },
};

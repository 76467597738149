import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';

interface MouseMoveParams extends IParams {
  type: string;
  cursorPosition: { x: number; y: number };
}

export class MouseMoveEvent extends EventBase {
  static eventType = 'MouseMoveEvent';

  constructor(public readonly params: MouseMoveParams, public readonly source = MouseMoveCommand) {
    super();
  }
}

export class MouseMoveCommand extends CommandBase<MouseMoveParams> {
  shouldSave(): boolean {
    return false;
  }

  execute(params: MouseMoveParams): EventBase | undefined {
    return new MouseMoveEvent(params);
  }
}

import { MenuItem, Typography } from '@mui/material';
import { DragEventHandler, FC, useCallback, useRef } from 'react';
import { CategoryToolbarCompactItemElement, ToolbarElementProps } from '@xspecs/single-source-model';
import { Icon } from '@xspecs/design-system';
import { useCommandDispatch } from '../../../wrappers/application-context/application-context';
import { isUrl } from '../../../lib/utils';

export const CategoryCompact: FC<ToolbarElementProps<CategoryToolbarCompactItemElement>> = (props) => {
  const { element } = props;

  const dispatch = useCommandDispatch();
  const previewRef = useRef<SVGSVGElement>(null);

  const onClick = useCallback(() => {
    dispatch(element.onClick.command, element.onClick.params);
  }, [dispatch, element.onClick.command, element.onClick.params]);

  const onItemDragStart = useCallback<DragEventHandler>(
    (event) => {
      dispatch(element.onDragStart.command, element.onDragStart.params);
      if (previewRef.current) event.dataTransfer.setDragImage(previewRef.current, 0, 0);
    },
    [dispatch, element.onDragStart.command, element.onDragStart.params],
  );

  return (
    <MenuItem sx={rootSx} onClick={onClick} onDragStart={onItemDragStart} draggable>
      {'shape' in element ? (
        <Icon ref={previewRef} name={element.shape as string} color={element.iconColor} />
      ) : isUrl(element.icon) ? (
        <img alt={`${element.type}Icon`} style={imgStyle} src={element.icon} />
      ) : (
        <Icon ref={previewRef} name={element.icon} color={element.iconColor} />
      )}
      <Typography variant="body1">{element.label}</Typography>
      <Typography variant="body3" color="text.disabled" alignSelf="flex-start">
        {element.subtitle}
      </Typography>
    </MenuItem>
  );
};

const rootSx = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 1,
};

const imgStyle = {
  width: 24,
  height: 24,
  minWidth: 24,
  minHeight: 24,
};

import { Stack, SxProps, Theme } from '@mui/material';
import { renderEdgeToolbar } from './edge-toolbar-renderer';
import { EdgeToolbarElement } from '@xspecs/single-source-model';
import { EdgeProvider } from './edge-toolbar-context';

type EdgeToolbarProps = {
  edgeId: string;
  layout: EdgeToolbarElement[];
};

export const EdgeToolbar = (props: EdgeToolbarProps) => {
  const { edgeId, layout } = props;

  return (
    <EdgeProvider edgeId={edgeId}>
      <Stack sx={rootSx}>
        {layout.map((item, index) => renderEdgeToolbar({ key: `EdgeToolbarItem${index}`, item }))}
      </Stack>
    </EdgeProvider>
  );
};

const rootSx: SxProps<Theme> = {
  width: 'fit-content',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 1,
  borderRadius: '8px',
  boxShadow: (t) => t.shadows[1],
  backgroundColor: 'background.paper',
  px: 1.5,
  py: 1,
};

import { ConstructBase } from './ConstructBase';
import { EntityType } from '../../entities/EntityType';
import { z } from 'zod';

export class Event extends ConstructBase {
  color = '#FF9C48';

  static schema = ConstructBase.baseSchema.extend({
    width: z.number().default(80),
  });

  get type(): EntityType {
    return EntityType.Event;
  }

  static parse(data: unknown): Event {
    return ConstructBase.parseBase.call(Event, data, Event.schema);
  }
}

export const GripHorizontal = () => {
  return (
    <>
      <path
        d="M10.5 8.33341C10.9603 8.33341 11.3334 7.96032 11.3334 7.50008C11.3334 7.03984 10.9603 6.66675 10.5 6.66675C10.0398 6.66675 9.66671 7.03984 9.66671 7.50008C9.66671 7.96032 10.0398 8.33341 10.5 8.33341Z"
        stroke="#71717A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3334 8.33341C16.7936 8.33341 17.1667 7.96032 17.1667 7.50008C17.1667 7.03984 16.7936 6.66675 16.3334 6.66675C15.8731 6.66675 15.5 7.03984 15.5 7.50008C15.5 7.96032 15.8731 8.33341 16.3334 8.33341Z"
        stroke="#71717A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66671 8.33341C5.12694 8.33341 5.50004 7.96032 5.50004 7.50008C5.50004 7.03984 5.12694 6.66675 4.66671 6.66675C4.20647 6.66675 3.83337 7.03984 3.83337 7.50008C3.83337 7.96032 4.20647 8.33341 4.66671 8.33341Z"
        stroke="#71717A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 13.3334C10.9603 13.3334 11.3334 12.9603 11.3334 12.5001C11.3334 12.0398 10.9603 11.6667 10.5 11.6667C10.0398 11.6667 9.66671 12.0398 9.66671 12.5001C9.66671 12.9603 10.0398 13.3334 10.5 13.3334Z"
        stroke="#71717A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3334 13.3334C16.7936 13.3334 17.1667 12.9603 17.1667 12.5001C17.1667 12.0398 16.7936 11.6667 16.3334 11.6667C15.8731 11.6667 15.5 12.0398 15.5 12.5001C15.5 12.9603 15.8731 13.3334 16.3334 13.3334Z"
        stroke="#71717A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66671 13.3334C5.12694 13.3334 5.50004 12.9603 5.50004 12.5001C5.50004 12.0398 5.12694 11.6667 4.66671 11.6667C4.20647 11.6667 3.83337 12.0398 3.83337 12.5001C3.83337 12.9603 4.20647 13.3334 4.66671 13.3334Z"
        stroke="#71717A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
};

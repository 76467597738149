import { Edge, EdgeProps, getBezierPath, getSmoothStepPath, getStraightPath } from '@xyflow/react';
import { EdgeLabel } from './label/edge-label';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useUpdateEntity } from '../hooks/use-update-entity';
import { EdgeData } from '@xspecs/single-source-model';
import { arrow, autoUpdate, flip, FloatingArrow, FloatingPortal, offset, useFloating } from '@floating-ui/react';
import { Box } from '@mui/material';
import { EdgeToolbar } from './toolbar/edge-toolbar';
import { logger } from '@xspecs/logger';
import { createPlateEditor, getEditorString } from '@udecode/plate-common';

export const DefaultEdge = (props: EdgeProps<Edge<EdgeData>>) => {
  const {
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    data,
    selected,
    markerStart,
    markerEnd,
    style,
    sourceHandleId,
    targetHandleId,
  } = props;

  const isEmptyLabel = useMemo(() => {
    const editor = createPlateEditor();
    editor.children = JSON.parse(data?.name ?? JSON.stringify([]));
    const editorText = getEditorString(editor, []);
    return Boolean(editorText);
  }, [data?.name]);

  const [showLabel, setShowLabel] = useState(Boolean(selected || isEmptyLabel));
  const [focus, setFocus] = useState(false);
  const { onRename } = useUpdateEntity(id);

  const targetOffset = useMemo(() => {
    const OFFSET = parseInt(data?.lineWeight || '4');

    if (targetHandleId === 'left') {
      return {
        xOffset: OFFSET,
        yOffset: 0,
      };
    }

    if (targetHandleId === 'bottom') {
      return {
        xOffset: 0,
        yOffset: -OFFSET,
      };
    }

    if (targetHandleId === 'right') {
      return {
        xOffset: -OFFSET,
        yOffset: 0,
      };
    }

    if (targetHandleId === 'top') {
      return {
        xOffset: 0,
        yOffset: OFFSET,
      };
    }
    return {
      xOffset: 0,
      yOffset: 0,
    };
  }, [data?.lineWeight, targetHandleId]);

  const sourceOffset = useMemo(() => {
    const divider = !markerStart || markerStart.includes('none') ? 2 : 1;

    const OFFSET = parseInt(data?.lineWeight || '4') / divider;

    if (sourceHandleId === 'left') {
      return {
        xOffset: OFFSET,
        yOffset: 0,
      };
    }

    if (sourceHandleId === 'bottom') {
      return {
        xOffset: 0,
        yOffset: -OFFSET,
      };
    }

    if (sourceHandleId === 'right') {
      return {
        xOffset: -OFFSET,
        yOffset: 0,
      };
    }

    if (sourceHandleId === 'top') {
      return {
        xOffset: 0,
        yOffset: OFFSET,
      };
    }
    return {
      xOffset: 0,
      yOffset: 0,
    };
  }, [data?.lineWeight, markerStart, sourceHandleId]);

  const [edgePath, labelX, labelY] = useMemo(() => {
    const lineType = data?.lineType ?? 'curved';
    const params = {
      sourceX: sourceX - sourceOffset.xOffset,
      sourceY: sourceY - sourceOffset.yOffset,
      sourcePosition,
      targetX: targetX - targetOffset.xOffset,
      targetY: targetY - targetOffset.yOffset,
      targetPosition,
    };
    switch (lineType) {
      case 'straight':
        return getStraightPath(params);
      case 'orthogonal':
        return getSmoothStepPath(params);
      case 'curved':
      default:
        return getBezierPath(params);
    }
  }, [
    data?.lineType,
    sourceOffset.xOffset,
    sourceOffset.yOffset,
    sourcePosition,
    sourceX,
    sourceY,
    targetOffset.xOffset,
    targetOffset.yOffset,
    targetPosition,
    targetX,
    targetY,
  ]);

  const arrowRef = useRef(null);
  const { refs, floatingStyles, context } = useFloating({
    placement: 'top',
    whileElementsMounted: autoUpdate,
    middleware: [offset(20), flip(), arrow({ element: arrowRef.current })],
  });

  const strokeDasharray = useMemo(() => {
    if (!data?.lineStyle || data?.lineStyle === 'solid') {
      return undefined;
    }

    if (data.lineStyle === 'dotted') {
      return '2 5';
    }
    if (data.lineStyle === 'dashed') {
      return '10 5';
    }

    logger.error('Received a line style for which stroke dash array is not registered');
  }, [data?.lineStyle]);

  const baseEdgeStyle = useMemo(
    () => ({
      ...style,
      strokeWidth: data?.lineWeight ?? '5px',
      cursor: data?.isReadOnly ? 'default' : 'pointer',
    }),
    [data?.isReadOnly, data?.lineWeight, style],
  );
  const stroke = parseInt(baseEdgeStyle.strokeWidth.replace('px', ''));
  const interactionWidth = stroke * 2 + 42;

  const edgeLabelStyle = useMemo(
    () => ({
      color: data?.labelColor,
      fontSize: data?.labelFontSize,
    }),
    [data?.labelColor, data?.labelFontSize],
  );

  const onDoubleClick = () => {
    setShowLabel(true);
    setFocus(true);
  };

  const onBlur = () => {
    setFocus(false);
    setShowLabel(isEmptyLabel);
  };

  useEffect(() => {
    setShowLabel(isEmptyLabel);
  }, [isEmptyLabel]);

  return (
    <>
      {showLabel ? (
        <EdgeLabel
          id={`${id}label`}
          key={id + JSON.stringify(data?.name)}
          value={data?.name ?? '[]'}
          onChange={onRename}
          labelX={labelX}
          labelY={labelY}
          focus={focus}
          onBlur={onBlur}
          selected={Boolean(selected)}
          style={edgeLabelStyle}
        />
      ) : null}
      <g ref={refs.setReference} onDoubleClick={onDoubleClick}>
        <path
          id={id}
          className="react-flow__edge-path"
          d={edgePath}
          stroke={baseEdgeStyle.strokeWidth}
          markerEnd={markerEnd}
          markerStart={markerStart}
          style={baseEdgeStyle}
          strokeDasharray={strokeDasharray}
        />
        {interactionWidth && (
          <path
            d={edgePath}
            fill="none"
            strokeOpacity={0}
            strokeWidth={interactionWidth}
            className="react-flow__edge-interaction"
          />
        )}
      </g>
      {selected && data?.toolbar ? (
        <FloatingPortal>
          <Box ref={refs.setFloating} style={floatingStyles}>
            <FloatingArrow ref={arrowRef} context={context} fill="white" stroke="rgba(0, 0, 0, 0.20)" strokeWidth={1} />
            <EdgeToolbar edgeId={id} layout={data.toolbar} />
          </Box>
        </FloatingPortal>
      ) : null}
    </>
  );
};

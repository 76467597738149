import { SpacesSideBarLink, SpacesSideBarLinkProps } from './item/spaces-sidebar-link';
import { Stack } from '@mui/material';

export interface SpacesSidebarList {
  links: SpacesSideBarLinkProps[];
  collapsed: boolean;
}

export enum SpacesSidebarLinkValues {
  Recents = 'recents',
  All = 'All Spaces',
}

export const SpacesSidebarList = (props: SpacesSidebarList) => {
  const { links, collapsed } = props;

  return (
    <Stack gap={0.5}>
      {links.map((link) => (
        <SpacesSideBarLink {...link} key={link.label} collapsed={collapsed} />
      ))}
    </Stack>
  );
};

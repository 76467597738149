import { IClipboard } from './IClipboard';

const KEY = 'clipboard';

export class NavigatorClipboard implements IClipboard {
  public async copy(text: string): Promise<void> {
    localStorage.setItem(KEY, text);
  }

  public async read(): Promise<string> {
    return localStorage.getItem(KEY) ?? '';
  }

  public dispose(): void {
    localStorage.removeItem(KEY);
  }
}

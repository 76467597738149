import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  SxProps,
  Tooltip,
  TooltipProps,
  Typography,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { Theme } from '@mui/system';
import { AssetUsageItem } from './asset-usage-item';
import { FloatingMenu, useFloatingMenu } from '../../single-source-model/canvas/floating-menu/floating-menu';
import { useIntl } from 'react-intl';
import { FloatingPortal } from '@floating-ui/react';
import { memo, useCallback, useState } from 'react';
import { AssetExplorerItem } from '@xspecs/single-source-model';
import { useSingleSourceStore } from '../../../store/single-source-store/single-source-store';

type AssetAccordionProps = AssetExplorerItem;

const _AssetAccordion = (props: AssetAccordionProps) => {
  const { id, name, type, url, usages, icon } = props;
  const [constructToPanTo, setConstructToPanTo] = useState(id);
  const attachmentsByAssetId = useSingleSourceStore.use.attachmentsByAssetId();

  const { formatMessage: f } = useIntl();

  const {
    context,
    floatingProps,
    floatingRef,
    floatingStyles,
    referenceProps,
    referenceRef,
    showFloating,
    arrowRef,
    onManageLabels,
    onShowFloating,
  } = useFloatingMenu();

  const hasUsages = usages?.length > 0;

  const onClick = useCallback(() => {
    onShowFloating();
    const attachments = attachmentsByAssetId[id];
    const panToId = attachments?.length && usages.length === 0 ? attachments[0]?.id : id;
    setConstructToPanTo(panToId);
  }, [attachmentsByAssetId, id, onShowFloating, usages.length]);

  return (
    <Accordion slotProps={{ heading: { component: 'h4' } }} disableGutters={true} sx={accordionSx}>
      <AccordionSummary
        expandIcon={
          hasUsages ? (
            <Tooltip title={f({ id: 'show-usage' })} placement="top" slotProps={tooltipSlotProps}>
              <ExpandMore fontSize="small" color="action" />
            </Tooltip>
          ) : null
        }
        aria-controls={`asset-${name}`}
        id={`asset-${name}`}
        {...referenceProps}
        ref={referenceRef}
        sx={accordionSummarySx}
        onClick={onClick}
      >
        <Stack gap={1} direction="row" alignItems="center" sx={accordionContentSx}>
          <Tooltip title={name}>
            <Box>
              <img src={icon ?? url} alt={name} style={imageStyle} />
            </Box>
          </Tooltip>
          <Typography variant="body2" color="textPrimary" sx={assetNameSx}>
            {name}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={accordionDetailsSx} hidden={!hasUsages}>
        <Stack>
          {usages.map((usage) => (
            <AssetUsageItem key={usage.id} {...usage} />
          ))}
        </Stack>
      </AccordionDetails>
      {showFloating ? (
        <FloatingPortal>
          <FloatingMenu
            ref={floatingRef}
            id={id}
            constructToPanTo={constructToPanTo}
            styles={floatingStyles}
            arrowRef={arrowRef}
            context={context}
            type={type}
            disableLabels
            name={name}
            onManageLabels={onManageLabels}
            labels={[]}
            withDeleteAsset
            {...floatingProps}
          />
        </FloatingPortal>
      ) : null}
    </Accordion>
  );
};

_AssetAccordion.displayName = 'AssetAccordion';
export const AssetAccordion = memo(_AssetAccordion);

const imageStyle = {
  minWidth: '24px',
  minHeight: '24px',
  maxWidth: '24px',
  maxHeight: '24px',
};

const accordionSx: SxProps<Theme> = {
  boxShadow: 'none',
  background: (theme) => theme.palette.grey[50],
  position: 'relative',
  overflow: 'visible',
  '&:before': { display: 'none' },
};

const accordionDetailsSx: SxProps<Theme> = { pt: 0, pr: 0.5, pb: 1, pl: 3.5 };

const tooltipSlotProps: TooltipProps['slotProps'] = {
  popper: {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, -14],
        },
      },
    ],
  },
};

const accordionSummarySx: SxProps<Theme> = {
  '& .MuiAccordionSummary-root': {
    justifyContent: 'space-between',
  },
  '& .MuiAccordionSummary-content': {
    width: '90%',
  },
};

const assetNameSx: SxProps<Theme> = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '80%',
};

const accordionContentSx: SxProps<Theme> = { width: '100%' };

import { EntityBase } from '../EntityBase';
import { z } from 'zod';

export abstract class AssetBase extends EntityBase {
  static baseSchema = EntityBase.abstractBaseSchema
    .extend({
      type: z.string().describe(`The type of the construct`),
      position: z
        .object({
          x: z.number(),
          y: z.number(),
        })
        .optional(),
    })
    .omit({ parent: true });
  static references: string[] = [];
  //static version = '1.0.1'; // Rename Tags to Labels;
  static version = '1.0.2'; // Remove parent from assets

  protected static parseBase<T extends EntityBase>(
    this: new (...args: any[]) => T,
    data: object,
    schema: z.ZodSchema<any> = AssetBase.baseSchema,
  ): T {
    return super.parseBase(data, schema) as T;
  }

  isValid(): boolean {
    return AssetBase.baseSchema.safeParse(this).success;
  }
}

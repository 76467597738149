import { Divider } from '@mui/material';
import { IconButton } from './components/icon-button';
import { EdgeToolbarElement } from '@xspecs/single-source-model';
import { Stepper } from './components/stepper';

export const renderEdgeToolbar = ({ key, item }: { key: string; item: EdgeToolbarElement }) => {
  switch (item.type) {
    case 'icon-button':
      return <IconButton key={key} {...item} />;
    case 'divider':
      return <Divider key={key} {...item} flexItem orientation="vertical" />;
    case 'stepper':
      return <Stepper key={key} {...item} />;
  }
};

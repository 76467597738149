import { Box, IconButton, Stack, SxProps, Theme } from '@mui/material';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ProfileDetails } from '../../sidebar/profile-details/profile-details';
import { Icon, IconName } from '@xspecs/design-system';
import { SpacesSidebarLinkValues, SpacesSidebarList } from './list/links-list';
import { SpacesSideBarLinkProps } from './list/item/spaces-sidebar-link';
import { UpgradePlan, UpgradePlanProps } from './upgrade-plan/upgrade-plan';
import { useActiveOrganization } from '../../../hooks/use-active-organization';
import { useSingleSourceStore } from '../../../store/single-source-store/single-source-store';

export type SidebarProps = {
  profile: {
    name: string;
    email: string;
    picture: string;
  };
  collapsed: boolean;
  onCollapse: () => void;
  selectedView: SpacesSidebarLinkValues;
  setSelectedView: (value: SpacesSidebarLinkValues) => void;
};

export const Sidebar = (props: SidebarProps) => {
  const { profile, collapsed, onCollapse, selectedView, setSelectedView } = props;
  const { organization } = useActiveOrganization();
  const recentSpacesByOrg = useSingleSourceStore.use.recentSpacesByOrg();

  const { formatMessage: f } = useIntl();

  const explorerWrapperSx: SxProps = useMemo(
    () => ({ px: collapsed ? 1.5 : 1, flex: 1, pb: 1, overflowY: 'auto', overflowX: 'visible' }),
    [collapsed],
  );

  const links: SpacesSideBarLinkProps[] = useMemo(
    () => [
      ...(organization && recentSpacesByOrg[organization?.id]
        ? [
            {
              label: 'Recents',
              icon: 'recent-folder' as IconName,
              onChange: () => setSelectedView(SpacesSidebarLinkValues.Recents),
              selected: selectedView === SpacesSidebarLinkValues.Recents,
              collapsed: collapsed,
            },
          ]
        : []),
      {
        label: 'All Spaces',
        icon: 'boxes',
        onChange: () => setSelectedView(SpacesSidebarLinkValues.All),
        selected: selectedView === SpacesSidebarLinkValues.All,
        collapsed: collapsed,
      },
    ],
    [collapsed, organization, recentSpacesByOrg, selectedView, setSelectedView],
  );

  const upgradePlan: UpgradePlanProps = useMemo(
    () => ({
      title: f({ id: 'upgrade-your-plan' }),
      description: f({ id: 'premium-features-with-one-click' }),
      buttonText: f({ id: 'upgrade-now' }),
      link: `/${organization?.name}/settings#plan`,
      collapsed: collapsed,
    }),
    [collapsed, f, organization?.name],
  );

  return (
    <Stack sx={sidebarSx} width={collapsed ? '64px' : '100%'}>
      <Box sx={logoSx}>{/*<Logo />*/}</Box>
      <Box sx={controlsWrapperSx}>
        <IconButton sx={backButtonSx} onClick={onCollapse} data-testid="collapse-button">
          <Icon name={collapsed ? 'show-sidebar' : 'hide-sidebar'} width={24} height={24} />
        </IconButton>
      </Box>

      <Box sx={explorerWrapperSx}>
        <SpacesSidebarList links={links} collapsed={collapsed} />
      </Box>
      <Stack gap={1.5}>
        <Box px={1} display="flex" justifyContent="center">
          <UpgradePlan {...upgradePlan} />
        </Box>
        <ProfileDetails {...profile} collapsed={collapsed} />
      </Stack>
    </Stack>
  );
};

const sidebarSx: SxProps = {
  height: '100%',
  position: 'relative',
};

const logoSx: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const backButtonSx: SxProps<Theme> = {
  borderRadius: 2,
  width: 28,
  height: 28,
  color: 'text.primary',
  alignSelf: 'center',
  padding: 0,
};

const controlsWrapperSx: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'flex-end',
  px: 2,
  mb: 1.5,
  mt: 2,
  gap: 1,
};

import { EntityBase } from '../entities/EntityBase';
import { ConstructBase, ConstructShape } from '../entities/constructs/ConstructBase';
import { AssetBase } from '../entities/assets/AssetBase';
import { EntityType } from '../entities/EntityType';
import { Attachment } from '../entities/assets/Attachment';
import { DetailsElement } from './DetailsElement';
import { ScriptBase } from '../entities/scripts/ScriptBase';
import { ScriptConfig } from '../entities/scripts/ScriptConfig';
import { z } from 'zod';
import { EntityClassRegistry } from '../entities/EntityClassRegistry';
import { EntityTypeRegistry } from '../entities/EntityTypeRegistry';
import { FrameGroup, LaneGroup } from 'narrative-studio-sdk';

export interface DynamicConstruct {
  type: EntityType | string;
  label: string;
  description?: string;
  backgroundColor: string;
  textColor: string;
  shape: ConstructShape;
  detailsPane?: DetailsElement[];
}

export interface DynamicAsset {
  type: EntityType | string;
  label: string;
  description?: string;
  icon: string;
  dataSource: string;
  detailsPane?: DetailsElement[];
}

export interface DynamicScript {
  type: EntityType | string;
  label: string;
  description?: string;
  icon: string;
  frameGroups?: FrameGroup[];
  laneGroups?: LaneGroup[];
  detailsPane?: DetailsElement[];
}

type UserDefinedEntity = DynamicConstruct | DynamicAsset | DynamicScript;

type EntityBaseConstructor = new (
  id: string,
  name: string,
  parent: EntityBase | undefined,
  position: { x: number; y: number },
  scopes: string[],
  attributes: any,
  width: number,
  height: number,
  isVisible: boolean,
  zIndex: number,
) => ConstructBase | AssetBase | ScriptBase;

export class DynamicEntityRegistry {
  constructor() {}

  public static registerEntities(entities: {
    constructs: DynamicConstruct[];
    assets: DynamicAsset[];
    scripts: DynamicScript[];
  }): void {
    if (entities.constructs) {
      entities.constructs.forEach((constructDef) => {
        const dynamicEntityClass = this.createEntityClass(constructDef, ConstructBase);
        EntityClassRegistry.register(constructDef.type, dynamicEntityClass);
        EntityTypeRegistry.register(constructDef.type);
        this.registerDynamicEntity(constructDef.type, dynamicEntityClass);
      });
    }
    if (entities.assets) {
      entities.assets.forEach((attachmentDef) => {
        const dynamicEntityClass = this.createEntityClass(attachmentDef, AssetBase);
        this.registerDynamicEntity(attachmentDef.type, dynamicEntityClass);
      });
    }
    if (entities.scripts) {
      entities.scripts.forEach((scriptDef) => {
        const dynamicEntityClass = this.createEntityClass(scriptDef, ScriptBase);
        this.registerDynamicEntity(scriptDef.type, dynamicEntityClass);
      });
    }
  }

  private static registerDynamicEntity(entityType: string, dynamicEntityClass: EntityBaseConstructor) {
    EntityClassRegistry.register(entityType, dynamicEntityClass);
    EntityTypeRegistry.register(entityType);
  }

  public static deRegisterEntities(entityTypes: string[]) {
    entityTypes.forEach((entityType) => {
      EntityClassRegistry.deregister(entityType);
      EntityTypeRegistry.deregister(entityType);
    });
  }

  /**
   * This is needed to display added entities after an app is removed
   */
  public static createDynamicFallbackEntity(data: any): EntityBaseConstructor {
    if (!data.__dynamic) {
      throw new Error('Unable to create dynamic entity as entity is not marked as dynamic', data);
    }
    if (data.__baseType === 'Construct') {
      return this.CreateConstructClass(data);
    } else if (data.__baseType === 'Asset') {
      return this.CreateAssetClass(data);
    } else if (data.__baseType === 'Script') {
      return this.createScriptClass(data);
    }
    throw new Error('Unsupported entity for dynamic fallback');
  }

  private static createEntityClass(
    entity: UserDefinedEntity,
    baseClass: typeof ConstructBase | typeof AssetBase | typeof ScriptBase,
  ): EntityBaseConstructor {
    const entityType = entity.type;
    if (baseClass === ConstructBase) {
      return this.CreateConstructClass(entity as DynamicConstruct, entity.detailsPane);
    } else if (baseClass === AssetBase) {
      Attachment.registerAssetIcon({
        assetType: entityType,
        url: (entity as DynamicAsset).icon,
      });
      Attachment.registerDataSource({
        assetType: entityType,
        dataSource: (entity as DynamicAsset).dataSource,
      });
      return this.CreateAssetClass(entity as DynamicAsset, entity.detailsPane);
    } else if (baseClass === ScriptBase) {
      return this.createScriptClass(entity as DynamicScript, entity.detailsPane);
    }
    throw new Error('Unsupported entity base class');
  }

  private static CreateAssetClass(entity: DynamicAsset, detailsPane?: DetailsElement[]): EntityBaseConstructor {
    const schema = AssetBase.baseSchema.extend({
      type: z.string().optional().default(entity.type),
      detailsPane: z
        .array(
          z.object({
            label: z.string(),
            type: z.string().optional(),
            properties: z.record(z.any()).optional(),
          }),
        )
        .optional(),
    });
    return class extends AssetBase {
      public type = entity.type;
      public __dynamic = true;
      public __baseType = 'Asset';
      private _detailsPane = detailsPane;

      static parse(data: unknown): AssetBase {
        return AssetBase.parseBase.call(this, data, schema);
      }

      public isValid(): boolean {
        return schema.safeParse(this).success;
      }
    };
  }

  private static CreateConstructClass(entity: DynamicConstruct, detailsPane?: DetailsElement[]): EntityBaseConstructor {
    const schema = ConstructBase.baseSchema.extend({
      type: z.string().optional().default(entity.type),
      detailsPane: z
        .array(
          z.object({
            label: z.string(),
            type: z.string().optional(),
            properties: z.record(z.any()).optional(),
          }),
        )
        .optional(),
    });
    return class extends ConstructBase {
      color = entity.backgroundColor;
      public type = entity.type;
      public __dynamic = true;
      public __baseType = 'Construct';
      private _detailsPane = detailsPane;

      static parse(data: unknown): ConstructBase {
        return ConstructBase.parseBase.call(this, data, schema);
      }
    };
  }

  private static createScriptClass(entity: DynamicScript, detailsPane?: DetailsElement[]): EntityBaseConstructor {
    return class extends ScriptBase {
      public type = entity.type;
      public __dynamic = true;
      public __baseType = 'Script';
      private _detailsPane = detailsPane;

      get config(): ScriptConfig {
        return {
          frameGroups: entity.frameGroups ?? [],
          laneGroups: entity.laneGroups ?? [],
          get defaultFrameWidth(): number {
            return 200;
          },
          get defaultLaneHeight(): number {
            return 200;
          },
          get defaultLaneColor(): string {
            return '#F8F8F8';
          },
        };
      }

      get icon(): string {
        return entity.icon;
      }
    };
  }
}

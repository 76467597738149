import { memo } from 'react';
import { wrapNodesWithHoc } from '../utils';
import { NodeTypes } from '@xyflow/react';
import { EntityType } from '@xspecs/single-source-model';
import { ThreadNode } from './nodes/thread-node/thread-node';
import { DebugNode } from './debug-node/debug-node';
import { PreviewNode } from './links/preview-node';
import { DefaultEdge } from './edges/default-edge';
import { DropTargetNode } from './drop-target-node/drop-target-node';
import { FloatingEdge } from './edges/floating-edge';
import { AttachmentNode } from './attachments/attachable-base';
import { ConstructNode } from './constructs/construct-node/construct-node';
import { ScriptNode } from './nodes/script-node/script-node';

const withMemo = (nodes: NodeTypes) => wrapNodesWithHoc(nodes, memo);

const nodes = {
  Construct: ConstructNode,
  Script: ScriptNode,
  [EntityType.Attachment]: AttachmentNode,
  [EntityType.Thread]: ThreadNode,
  DebugNode: DebugNode,
  DropTargetNode: DropTargetNode,
  [EntityType.Preview]: PreviewNode,
};

export const nodeTypes = withMemo(nodes);

export const edgeTypes = {
  floating: FloatingEdge,
  default: DefaultEdge,
};

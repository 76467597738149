import { useIntl } from 'react-intl';
import { EdgeLabelRenderer } from '@xyflow/react';
import { CSSProperties, KeyboardEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { Z_INDEXES } from '@xspecs/single-source-model';
import {
  blurEditor,
  createPlateEditor,
  focusEditor,
  getEditorString,
  getEndPoint,
  Plate,
  PlateContent,
  PlateEditor,
  select,
} from '@udecode/plate-common';

type EdgeLabelProps = {
  id: string;
  value: string;
  onChange: (value: string) => void;
  labelX: number;
  labelY: number;
  focus: boolean;
  onBlur: () => void;
  selected: boolean;
  style: CSSProperties;
};

const hasText = (editor: PlateEditor) => {
  if (editor.children.length === 0) return false;
  return Boolean(getEditorString(editor, []));
};

export const EdgeLabel = (props: EdgeLabelProps) => {
  const { id, value, onChange: onChangeProp, labelX, labelY, focus, onBlur: onBlurProp, selected, style } = props;

  const { formatMessage: f } = useIntl();

  const editor = useMemo(
    () =>
      createPlateEditor({
        id,
      }),
    [id],
  );
  const initialValue = useMemo(() => {
    const provided = JSON.parse(value);
    if (Array.isArray(provided) && provided.length > 0) return provided;
    return [
      {
        type: 'p',
        children: [{ text: '' }],
      },
    ];
  }, [value]);
  const [empty, setEmpty] = useState(() => {
    const editor = createPlateEditor();
    editor.children = initialValue;
    return !hasText(editor);
  });

  const containerStyle = {
    position: 'absolute',
    top: labelY,
    left: labelX,
    transform: 'translate(-50%, -50%)',
    borderRadius: 4,
    padding: '4px 8px',
    fontSize: 12,
    pointerEvents: 'all',
    zIndex: Z_INDEXES.Edge + 3000,
    width: 'fit-content',
    textAlign: 'center',
    backgroundColor: 'white',
    minWidth: empty ? 130 : 'fit-content',
    ...style,
  } satisfies CSSProperties;

  const onBlur = useCallback(() => {
    if (!editor) return;
    onChangeProp(JSON.stringify(editor.children));
    onBlurProp();
  }, [editor, onBlurProp, onChangeProp]);

  const onKeyDown = useCallback<KeyboardEventHandler<HTMLDivElement>>(
    (e) => {
      if (!editor) return;
      setEmpty(!hasText(editor));
      if (e.key === 'Escape') {
        e.stopPropagation();
        blurEditor(editor);
      }
    },
    [editor],
  );

  useEffect(() => {
    if (editor && editor.children.length > 0 && (focus || selected)) {
      const end = getEndPoint(editor, []);
      select(editor, end);
      focusEditor(editor, end);
    }
  }, [editor, editor.children.length, focus, selected]);

  useEffect(() => {
    if (editor && !focus) {
      blurEditor(editor);
      onBlur();
    }
  }, [editor, focus, onBlur]);

  return (
    <EdgeLabelRenderer>
      <div style={containerStyle} className="nodrag nopan">
        <Plate
          id={id}
          editor={editor}
          initialValue={initialValue}
          onChange={() => {
            setEmpty(!hasText(editor));
          }}
        >
          <PlateContent
            id={id}
            style={editorStyle}
            placeholder={f({ id: 'type-something' })}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
          />
        </Plate>
      </div>
    </EdgeLabelRenderer>
  );
};

const editorStyle = {
  outline: 'none',
};

import { SingleSourceModelCanvas } from './canvas/single-source-model-canvas';
import { Stack } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';

export const SingleSourceModel = () => {
  return (
    <Delayed>
      <Stack width="100%" height="100%">
        <SingleSourceModelCanvas />
      </Stack>
    </Delayed>
  );
};

type DelayedProps = {
  children: ReactNode;
  waitBeforeShow?: number;
};

const Delayed = ({ children, waitBeforeShow = 10 }: DelayedProps) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true);
    }, waitBeforeShow);
    return () => clearTimeout(timer);
  }, [waitBeforeShow]);

  return isShown ? children : null;
};

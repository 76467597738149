import { Box, Button, Divider, Icon, MenuItem, Typography, useTheme } from '@mui/material';
import { NavBar } from '../../components/nav-bar/nav-bar';
import { Icon as IconPrimitive } from '@xspecs/design-system';
import { ChevronRight } from '@mui/icons-material';
import { useIntl } from 'react-intl';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { getRoutePathFromParams, RoutePaths } from '../../config/route-paths/route-paths';
import { Organization } from '../../state/types';
import { Loading } from '../../components/loading/loading';
import { AppTypeEvent, useTrackEvents } from '../../hooks/use-track-events';

export type ChooseOrganizationProps = {
  items: Organization[];
  loading: boolean;
};

export const ChooseOrganization = (props: ChooseOrganizationProps) => {
  const { items, loading } = props;

  const theme = useTheme();

  const { trackEvent } = useTrackEvents();

  const { formatMessage: f } = useIntl();
  const navigate = useNavigate();

  const onCreateNewOrganization = useCallback(() => {
    navigate(RoutePaths.CreateOrganization);
  }, [navigate]);

  const getOrganization = useCallback((id: string) => items.find((item) => item.id === id), [items]);

  const navigateToOrganization = useCallback((organization: Organization) => {
    window.location.href = getRoutePathFromParams(RoutePaths.Organization, {
      organizationName: organization.name,
    });
  }, []);

  const navigateToSpaces = useCallback(
    (organization: Organization) => {
      navigate(getRoutePathFromParams(RoutePaths.Spaces, { organizationName: organization.name }), {
        state: {
          preventNavigation: true,
        },
      });
    },
    [navigate],
  );

  const selectOrganization = useCallback(
    (id: string) => () => {
      const organization = getOrganization(id);
      if (!organization) return;
      if (organization.workspaces.length > 0) {
        navigateToOrganization(organization);
        trackEvent(AppTypeEvent.OrganizationSelected, { organizationId: organization.id });
      } else {
        navigateToSpaces(organization);
      }
    },
    [getOrganization, navigateToOrganization, trackEvent, navigateToSpaces],
  );

  const renderContent = useCallback(() => {
    return (
      <>
        <Box
          data-testid="organizations-list"
          sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 1.5, mb: 3.5 }}
        >
          {items.map((organization) => (
            <OrganizationItem
              key={organization.id}
              {...organization}
              onSelectOrganization={selectOrganization(organization.id)}
            />
          ))}
        </Box>
        <Box sx={{ width: '100%', mb: 3.5, position: 'relative' }}>
          <Typography
            sx={{
              position: 'absolute',
              left: '50%',
              transform: 'translate(-50%, -47%)',
              px: 0.75,
              backgroundColor: 'white',
            }}
          >
            {f({ id: 'OR' })}
          </Typography>
          <Divider sx={{ width: '100%' }} />
        </Box>
        <Button
          data-testid="create-new-organization-button"
          fullWidth
          variant="contained"
          sx={{ height: '42px' }}
          onClick={onCreateNewOrganization}
        >
          {f({ id: 'create-new-organization' })}
        </Button>
      </>
    );
  }, [f, items, onCreateNewOrganization, selectOrganization]);

  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', backgroundColor: 'background.paper' }}>
      <NavBar isLoggedIn isPrimary hideLogo hideOrganizations />
      <Box
        sx={{
          mt: { xs: 8, md: 22.25 },
          mb: { xs: 8, md: 30.375 },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          px: { xs: 2, md: 4.5 },
          py: { md: 4.5 },
          width: { md: '415px' },
          mx: { md: 'auto' },
          boxShadow: {
            md: '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
          },
        }}
      >
        <IconPrimitive
          data-testid="NarrativeLogo"
          name="narrative-filled"
          height={48}
          width={46}
          color={theme.palette.primary.main}
        />
        <Typography variant="h5" sx={{ mt: 4.5, mb: 2 }}>
          {f({ id: 'choose-organization' })}
        </Typography>
        {loading ? <Loading /> : renderContent()}
      </Box>
    </Box>
  );
};

type OrganizationItemProps = {
  name: string;
  onSelectOrganization: () => void;
};

const organizationItemRootSx = {
  display: 'flex',
  alignItems: 'center',
  pl: 2,
  pr: 3,
  height: '60px',
  borderRadius: '4px',
  boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
};
const OrganizationItem = (props: OrganizationItemProps) => {
  const { name, onSelectOrganization } = props;

  return (
    <MenuItem data-testid="organization-item" sx={organizationItemRootSx} onClick={onSelectOrganization}>
      <Box sx={{ flexGrow: 1, overflowX: 'hidden' }}>
        <Typography sx={{ overflowX: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{name}</Typography>
      </Box>
      <Icon sx={{ ml: 'auto' }}>
        <ChevronRight />
      </Icon>
    </MenuItem>
  );
};

import { EntityType } from '../../entities/EntityType';
import { ConstructBase } from './ConstructBase';

export class ReadModel extends ConstructBase {
  color = '#67C6C0';

  get type(): EntityType {
    return EntityType.ReadModel;
  }

  static parse(data: unknown): ReadModel {
    return ConstructBase.parseBase.call(ReadModel, data);
  }
}

import * as Y from 'yjs';
import { IEntityUpcaster, PropertyTransformFunction } from './IEntityUpcaster';
import { sid } from '@xspecs/short-id';
import { logger } from '@xspecs/logger';
import { AttachmentType, EntityType } from '../entities/EntityType';
import { NarrativeScriptLaneNames } from '../entities/scripts/NarrativeScriptLaneNames';
import { NarrativeScript } from '../entities/scripts/NarrativeScript';
import { Z_INDEXES } from '../ZIndexes';
import { ActionScript } from '../entities/scripts/ActionScript';
import { EntityChanges, SerializedEntity } from '../types';
import { SingleSourceModel } from '../SingleSourceModel';
import { RecalculateDimensionsCommand } from '../commands/dimensions/RecalculateDimensionsCommand';
import { ToggleScriptVisibilityCommand } from '../commands/scripts/ToggleScriptVisibilityCommand';
import { ChangesSavedEvent } from '../commands/changes/BroadcastSavedChangesCommand';
import { HandleLocation } from '../entities/transitions/Edge';

let postProcessTimeout: NodeJS.Timeout | null = null;

export class EntityVersionUpcastRegistry {
  constructor(
    public readonly entityUpcaster: IEntityUpcaster,
    public readonly storageMap: Y.Map<any>,
    public readonly model: SingleSourceModel,
  ) {}

  register() {
    this.entityUpcaster
      //.registerDeprecatedEntity('Frame', '1.0.0')
      //.withCustomTransformUpcast(this.transformOldFrameToNewFrame)
      //.withPostProcessingUpcast(this.updateChildCellParentRefs)
      // .registerDeprecatedEntity('Narrative', '1.0.0', EntityType.NarrativeScript)
      // .registerDeprecatedEntity('Lane', '1.0.1', 'Cell')
      // .registerDeprecatedEntity('Tag', '1.0.0')
      // .withCustomTransformUpcast(this.transformTagToLabelWithStarScope)
      // .registerDeprecatedEntity('Frame', '2.0.0')
      // .withCustomTransformUpcast(this.transformFrameLanesToCells)
      // .registerDeprecatedEntity(EntityType.Comment, '1.0.0')
      // .withCustomTransformUpcast(this.transformCommentToAddCreatedAndUpdatedAt)
      .registerRetiredEntity('Frame')
      .registerRetiredEntity('Cell')
      .registerRetiredEntity('Action', '2.0.2')
      .registerDeprecatedEntity(EntityType.Label, '1.0.2')
      .withCustomTransformUpcast(this.removeNotFoundLabelReferences)
      .registerDeprecatedEntities([EntityType.Moment], '2.0.2', this.removeNotFoundAttachments)
      .registerDeprecatedEntity(EntityType.Edge, '1.0.1')
      .withCustomTransformUpcast(this.removeActionEdges)
      .registerDeprecatedEntity(EntityType.Edge, '1.0.2')
      .withCustomTransformUpcast(this.updateEdgesZIndex)
      .registerDeprecatedEntity(EntityType.Edge, '1.0.3')
      .withCustomTransformUpcast(this.updateEdgeLabel)
      .registerDeprecatedEntity(EntityType.Attachment, '1.0.2')
      .withCustomTransformUpcast(this.updateAttachmentDimensions)
      .registerDeprecatedEntity(EntityType.Attachment, '1.0.3')
      .withCustomTransformUpcast(this.updateAttachmentDimensionsWithParents)
      // .registerDeprecatedEntity(EntityType.Attachment, '1.0.1')
      // .withCustomTransformUpcast(this.addPreviewToUpload)
      .registerDeprecatedEntities(['Narrative', 'Action'], '2.0.3', this.addEdgesToScriptTransform)
      .registerDeprecatedEntities(['Attachment'], '1.0.4', this.addEdgesToScriptTransform)
      .registerDeprecatedEntities(['Script', 'SubScript'], '1.0.2', this.transformToScriptsWthParent)
      .registerDeprecatedEntities(
        ['Actor', 'Doc', 'Query', 'Schema', 'Spec', 'Upload'],
        '1.0.1',
        this.transformToAssetWithoutParent,
      )
      .registerDeprecatedEntity(EntityType.ActionScript, '1.0.2')
      .withCustomTransformUpcast(this.addFrameGroupsAndLaneGroupsTransform)
      .registerDeprecatedEntity(EntityType.NarrativeScript, '1.0.3')
      .withCustomTransformUpcast(this.addFrameGroupsAndLaneGroupsTransform)
      .withAfterAllUpcasts(this.finalizeSeptemberRefactorAfterAllUpcasts.bind(this))
      .withAfterModelLoaded(this.finalizeSeptemberRefactorAfterModelLoaded.bind(this));
  }

  private addEdgesToScriptTransform: PropertyTransformFunction = (entityData: any) => {
    let source: object;
    let target: object;
    if (typeof entityData !== 'object' || entityData === null) return [];
    if (entityData.type === 'Narrative' || entityData.type === 'Action') {
      source = { $ref: entityData.id };
      target = entityData.script;
    } else if (entityData.type === 'Attachment' && entityData.preview) {
      source = { $ref: entityData.id };
      target = entityData.preview;
    } else {
      return [entityData];
    }
    const newEdge = {
      type: 'Edge',
      color: '#D9D9D9',
      height: 80,
      id: sid(),
      isVisible: true,
      labelColor: '#212121',
      labelFontSize: 14,
      labels: [],
      lineStyle: 'solid',
      lineType: 'curved',
      lineWeight: '5px',
      markerEnd: { type: 'arrow' },
      markerStart: { type: 'none' },
      name: '[{"type":"p","children":[{"text":""}]}]',
      parent: undefined,
      position: { x: 0, y: 0 },
      sourceHandleLocation: 'Right',
      targetHandleLocation: 'Left',
      width: 140,
      zIndex: 3000,
      source: source,
      target: target,
      status: 'New',
      scopes: entityData.scopes,
    };
    return [entityData, newEdge];
  };

  private transformToAssetWithoutParent: PropertyTransformFunction = (entityData: any) => {
    if (typeof entityData !== 'object' || entityData === null) return [];
    const updatedEntity = {
      ...entityData,
      parent: undefined,
    };
    return [updatedEntity];
  };

  private transformToScriptsWthParent: PropertyTransformFunction = (entityData: any) => {
    if (typeof entityData !== 'object' || entityData === null) return [];
    const script = this.transformScript(entityData);
    const action = entityData.type === 'SubScript' ? this.getFromStorageMap(entityData.parent.$ref) : null;
    if (action) {
      script.position = {
        x: action.position.x + 200,
        y: action.position.y + 300,
      };
    }
    const narrativeOrActionEntity = {
      // use id of parent Action for SubScripts
      id: entityData.type === 'SubScript' ? entityData.parent.$ref : `${entityData.id}`,
      type:
        entityData.type === 'Script'
          ? EntityType.Narrative
          : entityData.type === 'SubScript'
          ? EntityType.Action
          : undefined,
      name: action?.name ?? entityData.name,
      position: action?.position ?? entityData.position,
      attachments: action?.attachments ?? [],
      script: { $ref: script.id },
      attributes: { fontSize: -1 },
      height: 80,
      width: 140,
      isVisible: true,
      version: '2.0.3',
      labels: entityData.labels ?? [],
      scopes: entityData.scopes,
      zIndex: Z_INDEXES.Construct,
      parent: entityData.type == 'SubScript' ? this.storageMap.get(entityData.parent.$ref)?.get('parent') : undefined,
    };
    if (entityData.type === 'SubScript') {
      this.storageMap.doc!.transact(() => {
        this.storageMap.delete(entityData.parent.$ref);
      });
    }
    return [script, narrativeOrActionEntity];
  };

  private transformScript(entityData: SerializedEntity) {
    const frames = entityData.frames || [];
    const frameIds: string[] = frames.map((frame: any) => frame.$ref);
    const scriptEntities: { id: string; laneIndex: number; frameIndex: number }[] = [];
    let numberOfCells = 0;
    frameIds?.forEach((frameId: string, index) => {
      const frame = this.getFromStorageMap(frameId);
      if (frame?.cells) {
        numberOfCells = Math.max(numberOfCells, frame.cells.length);
      }
      frame?.cells?.forEach((cellRef: any, cellIndex: number) => {
        const cell = this.getFromStorageMap(cellRef.$ref);
        cell.entities.forEach((entityRef: any) => {
          scriptEntities.push({
            id: entityRef.$ref,
            laneIndex: cellIndex,
            frameIndex: index,
          });
        });
      });
    });
    const config =
      entityData.type === 'Script'
        ? new NarrativeScript('', '').config
        : entityData.type === 'SubScript'
        ? new ActionScript('', '').config
        : null!;

    function createLanesForActionScript(numberOfLanes: number) {
      const lanes: { displayName: string; height: number; color: string; id: string }[] = [];
      for (let i = 0; i < numberOfLanes; i++) {
        lanes.push({
          id: sid(),
          displayName: '',
          height: config!.defaultLaneHeight,
          color: '#F8F8F8',
        });
      }
      return lanes;
    }

    if (numberOfCells === 0 && entityData.type === 'SubScript') {
      numberOfCells = 1;
    }
    const updatedScript = {
      ...entityData,
      id: entityData.type === 'SubScript' ? `${entityData.parent.$ref}_script` : `${entityData.id}_script`,
      type:
        entityData.type === 'Script'
          ? EntityType.NarrativeScript
          : entityData.type === 'SubScript'
          ? EntityType.ActionScript
          : undefined,
      parent: entityData.type == 'Script' ? { $ref: entityData.id } : entityData.parent,
      isVisible: true,
      isOpen: entityData.isExpanded ?? false,
      version: '1.0.3',
      width: 800,
      height: entityData.type === 'SubScript' ? 200 : 600,
      zIndex: Z_INDEXES.Script,
      frames: frames.map(() => ({
        id: sid(),
        width: config.defaultFrameWidth,
        entities: Array.from({ length: numberOfCells }, () => []),
      })),
      lanes:
        entityData.type === 'SubScript'
          ? createLanesForActionScript(numberOfCells)
          : config.laneGroups
              ?.flatMap((l) => l?.lanes)
              .map((laneConfig) => ({
                id: sid(),
                displayName: laneConfig?.label?.text,
                height: config?.defaultLaneHeight,
                color: laneConfig?.style?.backgroundColor,
              })),
    };
    scriptEntities.forEach((scriptEntity) => {
      const mapEntity = this.getFromStorageMap(scriptEntity.id);
      if (mapEntity) {
        mapEntity.parent = { $ref: updatedScript.id }; // this is happening for Actions, but somewhere its' being wiped
        this.storageMap.set(scriptEntity.id, this.toYMap(mapEntity));
        updatedScript.frames[scriptEntity.frameIndex].entities[scriptEntity.laneIndex] = [{ $ref: scriptEntity.id }];
      }
    });
    return updatedScript;
  }

  private removeParentFromAssets(storageMap: Y.Map<any>): void {}

  private removeNotFoundLabelReferences: PropertyTransformFunction = (entityData: any) => {
    if (typeof entityData !== 'object' || entityData === null) return [];
    const updatedEntity = { ...entityData };
    if (entityData.entities) {
      updatedEntity.entities = entityData.entities.filter((labelRef: any) => this.getFromStorageMap(labelRef.$ref));
    }
    return [updatedEntity];
  };

  private removeNotFoundAttachments: PropertyTransformFunction = (entityData: any) => {
    if (typeof entityData !== 'object' || entityData === null) return [];
    const updatedEntity = { ...entityData };
    if (entityData.attachments && Array.isArray(entityData.attachments) && entityData.attachments.length > 0) {
      updatedEntity.attachments = entityData.attachments.filter((attachmentRef: any) =>
        this.getFromStorageMap(attachmentRef.$ref),
      );
    }
    return [updatedEntity];
  };

  private removeActionEdges: PropertyTransformFunction = (entityData: any) => {
    if (typeof entityData !== 'object' || entityData === null) return [];
    const source = this.getFromStorageMap(entityData.source.$ref);
    const target = this.getFromStorageMap(entityData.target.$ref);
    const updatedEntity = { ...entityData };
    updatedEntity.version = '1.0.2';
    if (
      !target ||
      target?.type === 'SubScript' ||
      !source ||
      source?.type === 'SubScript' ||
      source?.type === EntityType.Action
    ) {
      this.storageMap.doc!.transact(() => {
        this.storageMap.delete(entityData.id);
      });
      return [];
    }
    return [updatedEntity];
  };

  private updateEdgesZIndex: PropertyTransformFunction = (entityData: any) => {
    if (typeof entityData !== 'object' || entityData === null) return [];
    const updatedEntity = { ...entityData };
    updatedEntity.zIndex = Z_INDEXES.Edge;
    return [updatedEntity];
  };

  private updateEdgeLabel: PropertyTransformFunction = (entityData: any) => {
    if (typeof entityData !== 'object' || entityData === null) return [];
    const updatedEntity = { ...entityData };
    let name = entityData.name;
    try {
      JSON.parse(entityData.name);
    } catch {
      name = JSON.stringify([{ type: 'p', children: [{ text: entityData.name }] }]);
    }
    updatedEntity.name = name;
    updatedEntity.sourceHandleLocation = HandleLocation.Right;
    updatedEntity.targetHandleLocation = HandleLocation.Left;
    return [updatedEntity];
  };

  private transformFrameLanesToCells: PropertyTransformFunction = (oldFrameData: any) => {
    if (oldFrameData.lanes) {
      const cells = oldFrameData.lanes.map((lane) => ({
        ...lane,
        type: 'Cell',
      }));
      const newFrame = {
        ...oldFrameData,
        lanes: undefined,
        cells,
      };
      return [newFrame];
    }
    return [oldFrameData];
  };

  private transformOldFrameToNewFrame: PropertyTransformFunction = (oldFrameData: any) => {
    const { frontend, moment, backend, id, scopes } = oldFrameData;
    const cells = [
      {
        id: sid(),
        type: 'Cell',
        name: NarrativeScriptLaneNames.Interaction,
        laneKey: NarrativeScriptLaneNames.Interaction,
        parent: { $ref: id },
        position: { x: 0, y: 0 },
        scopes: scopes || [],
        entities: frontend || [],
      },
      {
        id: sid(),
        type: 'Cell',
        name: NarrativeScriptLaneNames.Context,
        laneKey: NarrativeScriptLaneNames.Context,
        parent: { $ref: id },
        position: { x: 0, y: 0 },
        scopes: scopes || [],
        entities: moment ? [moment] : [],
      },
      {
        id: sid(),
        type: 'Cell',
        name: NarrativeScriptLaneNames.System,
        laneKey: NarrativeScriptLaneNames.System,
        parent: { $ref: id },
        position: { x: 0, y: 0 },
        scopes: scopes || [],
        entities: backend || [],
      },
    ];
    const cellRefs = cells.map((cell) => ({
      $ref: cell.id,
    }));
    const updatedFrameData = {
      ...oldFrameData,
      frontend: undefined,
      moment: undefined,
      backend: undefined,
      cells: cellRefs,
    };
    return [updatedFrameData, ...cells];
  };

  private updateChildCellParentRefs(storageMap: Y.Map<any>): void {
    storageMap.doc!.transact(() => {
      storageMap.forEach((cellValue, cellKey) => {
        if (cellValue instanceof Y.Map && cellValue.get('type') === 'Cell') {
          const entities = cellValue.get('entities');
          if (entities) {
            const updatedEntities: string[] = [];
            entities.forEach((childRef) => {
              if (childRef && childRef.$ref) {
                const childEntity = storageMap.get(childRef.$ref);
                if (childEntity && childEntity instanceof Y.Map) {
                  const updatedEntity = new Y.Map();
                  childEntity.forEach((value, key) => {
                    updatedEntity.set(key, value);
                  });
                  updatedEntity.set('parent', { $ref: cellKey }); // set new parent
                  storageMap.set(childRef.$ref, updatedEntity);
                  updatedEntities.push(childRef); // keep this ref in the updated list
                } else {
                  logger.error(
                    `EntityVersionUpcastRegistry:updateChildCellParentRefs: No child entity found for ref: ${childRef.$ref}`,
                  );
                }
              }
            });

            if (updatedEntities.length === 0) {
              // rebuild the entire Cell
              const newCell = new Y.Map();
              cellValue.forEach((value, key) => {
                newCell.set(key, value); // Copy all properties from the old cell to the new cell
              });
              newCell.set('entities', []);
              // Replace the old cell entity with the new one in the storage map
              storageMap.set(cellKey, newCell);
            }
          } else {
            logger.error(
              `EntityVersionUpcastRegistry:updateChildCellParentRefs: No entities array found for cell ${cellKey}`,
            );
            //cellValue.set('entities', []);
          }
        }
      });
    });
  }

  private addFrameGroupsAndLaneGroupsTransform: PropertyTransformFunction = (entityData: any) => {
    if (typeof entityData !== 'object' || entityData === null) return [];
    const updatedEntity = { ...entityData };
    if (entityData.type === 'ActionScript' || entityData.type === 'NarrativeScript') {
      updatedEntity.frames = entityData.frames.map((frame: any) => ({
        ...frame,
        id: sid(),
        label: '',
      }));
      updatedEntity.lanes = entityData.lanes.map((lane: any) => ({
        ...lane,
        id: sid(),
        label: lane.displayName,
      }));

      updatedEntity.lanes.forEach((lane: any) => {
        delete lane.displayName;
      });

      updatedEntity.frameGroups = [
        {
          configIndex: 0,
          frameIds: updatedEntity.frames.map((frame: any) => frame.id),
          label: '',
        },
      ];
      updatedEntity.laneGroups = [
        {
          configIndex: 0,
          laneIds: updatedEntity.lanes.map((lane: any) => lane.id),
          label: '',
        },
      ];
    }
    return [updatedEntity];
  };

  private finalizeSeptemberRefactorAfterAllUpcasts(storageMap: Y.Map<any>): void {
    const entitiesToDelete: string[] = [];
    storageMap.forEach((entityValue, entityKey) => {
      // remove old Actions
      if (entityValue.get('type') === 'Action' && entityValue.get('version') === '2.0.2') {
        entitiesToDelete.push(entityKey);
      }
      // remove Cells, Frames, and Subscripts
      if (
        entityValue.get('type') === 'Cell' ||
        entityValue.get('type') === 'Frame' ||
        entityValue.get('type') === 'SubScript'
      ) {
        //storageMap.delete(entityKey);
        entitiesToDelete.push(entityKey);
      }

      //repoint source or target edges pointing Narratives instead point to the Script of the Narrative
      // if (entityValue.get('type') === 'Edge') {
      //   const sourceRef = entityValue.get('source').$ref;
      //   const targetRef = entityValue.get('target').$ref;
      //   const sourceEntity = storageMap.get(sourceRef);
      //   const targetEntity = storageMap.get(targetRef);
      //   let hasUpdated = false;
      //   if (sourceEntity && sourceEntity.get('type') === EntityType.Narrative && sourceEntity.get('version') === '2.0.3') {
      //     const scriptRef = sourceEntity.get('script').$ref;
      //     entityValue.set('source', { $ref: scriptRef });
      //     hasUpdated = true;
      //   }
      //   if (targetEntity && targetEntity.get('type') === EntityType.Narrative && sourceEntity.get('version') === '2.0.3') {
      //     const scriptRef = targetEntity.get('script').$ref;
      //     entityValue.set('target', { $ref: scriptRef });
      //     hasUpdated = true;
      //   }
      //   if (hasUpdated) {
      //     const updatedEdge = entityValue.toJSON?.();
      //     storageMap.set(entityKey, this.toYMap(updatedEdge));
      //   }
      // }
      // remove edges with missing source or target
      // if (entityValue.get('type') === 'Edge') {
      //   const sourceRef = entityValue.get('source').$ref;
      //   const targetRef = entityValue.get('target').$ref;
      //   if (!storageMap.get(sourceRef.$ref) || !storageMap.get(targetRef.$ref)) {
      //     //storageMap.delete(entityKey);
      //     entitiesToDelete.push(entityKey);
      //   }
      // }
    });

    storageMap.doc!.transact(() => {
      entitiesToDelete.forEach((entityKey) => {
        storageMap.delete(entityKey);
      });
      storageMap.forEach((entityValue, entityKey) => {
        if (entityValue.get('parent') && !storageMap.get(entityValue.get('parent').$ref)) {
          entityValue.set('parent', undefined);
        }

        if (
          entityValue.get('type') === 'Attachment' &&
          entityValue.get('asset') &&
          !storageMap.get(entityValue.get('asset').$ref)
        ) {
          entityValue.set('asset', undefined);
        }
        const entities = entityValue.get('entities');
        if (entities && entities.length > 0) {
          const notFoundEntities = entities.filter((entityRef) => !storageMap.get(entityRef.$ref));
          if (notFoundEntities.length > 0) {
            logger.warn(`Entity ${entityKey} has missing entities:`, notFoundEntities);
            const updatedEntity = entities.filter((ref) => !notFoundEntities.includes(ref));
            logger.log(`Entity ${entityKey} updated entities will be set to:`, updatedEntity);
            entityValue.set('entities', updatedEntity);
          }
        }
      });
    });
  }

  private finalizeSeptemberRefactorAfterModelLoaded() {
    //this.waitForGracefulPeriodOfNoSavingThenPostProcess();
  }

  private waitForGracefulPeriodOfNoSavingThenPostProcess() {
    this.model.messageBus.subscribe([ChangesSavedEvent], (changes) => {
      if (postProcessTimeout) {
        clearTimeout(postProcessTimeout);
      }
      postProcessTimeout = setTimeout(() => {
        this.postProcess(changes);
        if (postProcessTimeout) clearTimeout(postProcessTimeout);
        postProcessTimeout = null;
        setTimeout(() => {
          if (window) window.location.reload();
        }, 5000);
      }, 1000); // this isn't waiting 1 second, it's waiting for 1 second of no OnAfterSave activity
    });
  }

  private postProcess(changes) {
    const entityRepository = this.model.entityRepository;
    const entityIds = entityRepository.list().map((entity) => entity.id);
    this.model.boundariesIndex.updateIndex(changes as unknown as EntityChanges);
    this.model.messageBus.sendInternal(RecalculateDimensionsCommand, { entityIds });
    entityRepository
      .list()
      .filter((e) => e.type === EntityType.Action)
      .map((e) => e.id)
      .forEach((entityId) => {
        this.model.messageBus.sendInternal(ToggleScriptVisibilityCommand, { entityId });
        this.model.messageBus.sendInternal(ToggleScriptVisibilityCommand, { entityId });
      });
    entityRepository
      .list()
      .filter((e) => e.type === EntityType.Narrative)
      .forEach((e) => {
        e.position = { x: e.position.x - 200, y: e.position.y - 100 };
        entityRepository.update(e);
      });
    entityRepository.save();
    this.model.boundariesIndex.updateIndex(changes as unknown as EntityChanges);
  }

  // private fixPositions() {
  //   this.messageBus.send(RecalculateDimensionsCommand, {
  //     entityIds: this.entityRepository.list().map((entity) => entity.id),
  //   });
  // }

  private constructTransitionsToEdgeTransform: PropertyTransformFunction = (entityData: any) => {
    if (typeof entityData !== 'object' || entityData === null) return [];
    const { id, scopes, transitionsTo = [] } = entityData as { id: string; scopes?: string[]; transitionsTo?: any[] };
    const updatedEntity = { ...entityData, transitionsTo: undefined, transitionsFrom: undefined };
    const edges: any[] = [];
    if (Array.isArray(transitionsTo)) {
      transitionsTo.forEach((targetRef) => {
        if (typeof targetRef === 'object' && targetRef && '$ref' in targetRef) {
          const newEdge = {
            type: 'Edge',
            id: sid(),
            name: '',
            source: { $ref: id },
            target: targetRef,
            position: { x: 0, y: 0 },
            status: 'New',
            scopes: scopes || [],
          };
          edges.push(newEdge);
        }
      });
    }
    return [updatedEntity, ...edges];
  };

  private renameTagsFieldToLabelsTransform: PropertyTransformFunction = (entityData: any) => {
    if (typeof entityData !== 'object' || entityData === null) return [];
    const { tags = [] } = entityData as { tags?: any[] };
    const newLabels = tags?.map((tag) => ({ $ref: tag.$ref })) ?? [];
    const updatedEntity = {
      ...entityData,
      tags: undefined,
      labels: newLabels,
    };
    return [updatedEntity];
  };

  private transformTagToLabelWithStarScope: PropertyTransformFunction = (entityData: any) => {
    if (typeof entityData !== 'object' || entityData === null) return [];
    const updatedEntity = {
      ...entityData,
      scopes: ['*'],
    };
    updatedEntity.type = EntityType.Label;
    return [updatedEntity];
  };

  private transformCommentToAddCreatedAndUpdatedAt: PropertyTransformFunction = (entityData: any) => {
    if (typeof entityData !== 'object' || entityData === null) return [];
    const now = new Date().toISOString();
    const updatedEntity = {
      ...entityData,
      createdAt: now,
      updatedAt: now,
    };
    return [updatedEntity];
  };

  private addPreviewToUpload: PropertyTransformFunction = (entityData: any) => {
    if (typeof entityData !== 'object' || entityData === null) return [];
    if (entityData.subType !== AttachmentType.Upload) return [entityData];
    const previewId = sid();
    const updatedEntity = {
      ...entityData,
      preview: {
        $ref: previewId,
      },
    };

    const parentConstruct = entityData.parent?.$ref ? this.getFromStorageMap(entityData.parent.$ref) : null;
    if (parentConstruct) {
      parentConstruct.isVisible = false;
      this.storageMap.set(entityData.parent.$ref, this.toYMap(parentConstruct));
    }

    const preview = {
      type: EntityType.Preview,
      parent: {
        $ref: entityData.id,
      },
      labels: [],
      id: previewId,
      name: '',
      position: {
        x: entityData.position.x + 200,
        y: entityData.position.y,
      },
      attributes: {
        fontSize: -1,
      },
      scopes: entityData.scopes,
      width: 200,
      height: 163,
      isVisible: true,
      zIndex: 6000,
    };
    return [updatedEntity, preview];
  };

  private updateAttachmentDimensions: PropertyTransformFunction = (entityData: any) => {
    if (typeof entityData !== 'object' || entityData === null) return [];
    const updatedEntity = {
      ...entityData,
      width: 128,
      height: 40,
    };
    return [updatedEntity];
  };

  private updateAttachmentDimensionsWithParents: PropertyTransformFunction = (entityData: any) => {
    if (typeof entityData !== 'object' || entityData === null) return [];
    const updatedEntity = {
      ...entityData,
      width: 128,
      height: 40,
    };
    if (entityData.parent) {
      const parent = this.getFromStorageMap(entityData.parent?.$ref);
      if (parent) {
        updatedEntity.width = parent.width || 128;
      }
    }

    return [updatedEntity];
  };

  private getFromStorageMap(key: string): any {
    return this.storageMap.get(key)?.toJSON?.() ?? this.storageMap.get(key);
  }

  private toYMap(obj: any): Y.Map<any> {
    const fields = JSON.parse(JSON.stringify(Object.entries(obj)));
    return new Y.Map(fields);
  }
}

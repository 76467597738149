import { ComponentType, Fragment, memo, ReactNode, useCallback, useEffect, useId, useRef, useState } from 'react';
import { Box, InputBase, Stack, Typography } from '@mui/material';
import { FrameGroup, LaneGroup } from '@xspecs/single-source-model';
import { useWindowEvent } from '@mantine/hooks';
import { useCommandDispatch } from '../../../../wrappers/application-context/application-context';
import { Icon } from '@xspecs/design-system';
import { Header } from './header';

type ScriptProps = {
  laneGroups: LaneGroup[];
  frameGroups: FrameGroup[];
  width: number;
  height: number;
  mouseToXY: (event: { clientX: number; clientY: number }) => { x: number; y: number };
  position?: { x: number; y: number };
  HeaderContainer?: ComponentType<{ children: ReactNode }>;
};

const frameGroupOffset = 80;
const frameOffset = 35;

const laneGroupOffset = 100;
const laneOffset = 35;

const _Script = (props: ScriptProps) => {
  const {
    laneGroups,
    frameGroups,
    width,
    height,
    position = {
      x: 0,
      y: 0,
    },
    HeaderContainer = Fragment,
  } = props;

  const id = useId();

  const dispatchCommand = useCommandDispatch();

  const [hovered, setHovered] = useState({ frameGroupIndex: -1, laneGroupIndex: -1, frameIndex: -1, laneIndex: -1 });

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuOpenFor, setMenuOpenFor] = useState<{
    frameGroupIndex?: number;
    laneGroupIndex?: number;
    frameIndex?: number;
    laneIndex?: number;
  }>({});
  const rootRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;
    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;
    ctx.strokeStyle = '#000000';
    ctx.lineWidth = 2;
    ctx.strokeRect(0, 0, canvas.width, canvas.height);

    function drawLaneGroups(laneGroups: LaneGroup[]) {
      if (!ctx) return;
      for (const laneGroup of laneGroups) {
        ctx.fillStyle = laneGroup.style.backgroundColor ?? 'transparent';
        ctx.fillRect(laneGroup.x, laneGroup.y, laneGroup.width, laneGroup.height);

        if (laneGroup.style.borderColor && laneGroup.style.borderWidth) {
          ctx.lineWidth = laneGroup.style.borderWidth;
          ctx.strokeStyle = laneGroup.style.borderColor;
          ctx.strokeRect(laneGroup.x, laneGroup.y, laneGroup.width, laneGroup.height);
        }

        for (const lane of laneGroup.lanes) {
          ctx.fillStyle = lane.style.backgroundColor ?? 'transparent';
          ctx.fillRect(lane.x, lane.y, lane.width, lane.height);

          if (lane.style.borderColor && lane.style.borderWidth) {
            ctx.lineWidth = lane.style.borderWidth;
            ctx.strokeStyle = lane.style.borderColor;
            ctx.strokeRect(lane.x, lane.y, lane.width, lane.height);
          }
        }
      }
    }

    function drawFrameGroups(frameGroups: FrameGroup[]) {
      if (!ctx) return;
      for (const fg of frameGroups) {
        ctx.fillStyle = fg.style.backgroundColor ?? 'transparent';
        ctx.fillRect(fg.x, fg.y, fg.width, fg.height);

        if (fg.style.borderColor && fg.style.borderWidth) {
          ctx.lineWidth = fg.style.borderWidth;
          ctx.strokeStyle = fg.style.borderColor;
          ctx.strokeRect(fg.x, fg.y, fg.width, fg.height);
        }

        for (const frame of fg.frames) {
          ctx.fillStyle = frame.style.backgroundColor ?? 'transparent';
          ctx.fillRect(frame.x, frame.y, frame.width, frame.height);

          if (frame.style.borderColor && frame.style.borderWidth) {
            ctx.lineWidth = frame.style.borderWidth;
            ctx.strokeStyle = frame.style.borderColor;
            ctx.strokeRect(frame.x, frame.y, frame.width, frame.height);
          }
        }
      }
    }

    drawLaneGroups(laneGroups);
    drawFrameGroups(frameGroups);

    return () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    };
  }, [frameGroups, height, laneGroups, width]);

  const getOnMenuChange = useCallback(
    (key: string, value: number) => (isOpen: boolean) => {
      setIsMenuOpen(isOpen);
      if (isOpen) setMenuOpenFor({ [key]: value });
      else setMenuOpenFor({});
    },
    [],
  );

  const labels = [
    laneGroups.flatMap((lg) => {
      const result = lg.labelProps ? [lg.labelProps] : [];
      lg.lanes.forEach((l) => {
        if (l.labelProps) result.push(l.labelProps);
      });
      return result;
    }),
    frameGroups.flatMap((fg, i) => {
      const result = fg.labelProps ? [fg.labelProps] : [];
      fg.frames.forEach((f) => {
        if (f.labelProps) result.push(f.labelProps);
      });
      return result;
    }),
  ].flatMap((x) => x);

  useWindowEvent('mousemove', (e) => {
    if (!rootRef.current || isMenuOpen) return;
    const { x, y } = props.mouseToXY(e);

    const frameGroupIndex = frameGroups.findIndex((fg) => {
      const offset = fg.headerProps.show ? frameGroupOffset : 0;
      const fgX = fg.x;
      const fgY = fg.y - offset;
      const fgWidth = fg.width;
      const fgHeight = fg.height + offset;
      return x >= fgX && x <= fgX + fgWidth && y >= fgY && y <= fgY + fgHeight;
    });

    const frameIndex =
      frameGroups[frameGroupIndex]?.frames.findIndex((f) => {
        const offset = f.headerProps.show ? frameOffset : 0;
        const fX = f.x;
        const fY = f.y - offset;
        const fWidth = f.width;
        const fHeight = f.height + offset;

        return x >= fX && x <= fX + fWidth && y >= fY && y <= fY + fHeight;
      }) ?? -1;

    const laneGroupIndex = laneGroups.findIndex((lg) => {
      const offset = lg.headerProps.show ? laneGroupOffset : 0;
      const lgX = lg.x - offset;
      const lgY = lg.y;
      const lgWidth = lg.width + offset;
      const lgHeight = lg.height;

      return x >= lgX && x <= lgX + lgWidth && y >= lgY && y <= lgY + lgHeight;
    });
    const laneIndex =
      laneGroups[laneGroupIndex]?.lanes.findIndex((l) => {
        const offset = l.headerProps.show ? laneOffset : 0;
        const lX = l.x - offset;
        const lY = l.y;
        const lWidth = l.width + offset;
        const lHeight = l.height;

        return x >= lX && x <= lX + lWidth && y >= lY && y <= lY + lHeight;
      }) ?? -1;

    setHovered({
      frameGroupIndex,
      laneGroupIndex,
      frameIndex,
      laneIndex,
    });
  });

  return (
    <Box ref={rootRef} width={width} height={height} position="relative">
      <canvas ref={canvasRef} />
      {labels.map((label, index) => (
        <Stack
          key={`ScriptLabel${id}${index}`}
          direction="row"
          gap={0.5}
          sx={{
            position: 'absolute',
            left: label.x,
            top: label.y,
          }}
        >
          {label.iconProps ? <Icon name={label.iconProps.source} /> : null}
          {label.text.map((text, i) =>
            text.onRename ? (
              <InputBase
                key={`ScriptLabel${id}LabelText${i}`}
                placeholder="Untitled"
                sx={text.style}
                value={text.value}
                onChange={(e) => {
                  if (text.onRename)
                    dispatchCommand(text.onRename.command, {
                      ...text.onRename.params,
                      value: e.target.value,
                    });
                }}
              />
            ) : (
              <Typography key={`ScriptLabel${id}LabelText${i}`} sx={text.style}>
                {text.value}
              </Typography>
            ),
          )}
        </Stack>
      ))}
      <HeaderContainer>
        {frameGroups.map((fg, i) =>
          fg.headerProps.show ? (
            <Box
              key={`FrameGroupHeader-${i}`}
              className="nodrag"
              sx={{
                zIndex: 1000000,
                pointerEvents: 'all',
                position: 'absolute',
                width: fg.width,
                left: fg.x + position.x,
                top: -35 + position.y,
                transform: 'translate(0, -100%)',
                opacity: menuOpenFor.frameGroupIndex === i ? 1 : hovered.frameGroupIndex === i ? 0.4 : 0,
                transition: 'opacity 0.1s ease-in',
                '&:hover': {
                  opacity: 1,
                },
              }}
            >
              <Header
                onMenuOpenChange={getOnMenuChange('frameGroupIndex', i)}
                isGroupHeader
                bgColor="rgba(212, 212, 216, 1)"
                {...fg}
              />
            </Box>
          ) : null,
        )}
        {frameGroups.map((fg, i) =>
          fg.frames.map((f, j) =>
            f.headerProps.show ? (
              <Box
                key={`FrameHeader-${i}-${j}`}
                className="nodrag"
                sx={{
                  zIndex: 1000000,
                  pointerEvents: 'all',
                  position: 'absolute',
                  width: f.width,
                  left: f.x + position.x,
                  top: -4 + position.y,
                  transform: 'translate(0, -100%)',
                  opacity:
                    menuOpenFor.frameIndex === j
                      ? 1
                      : hovered.frameGroupIndex === i && hovered.frameIndex === j
                      ? 0.4
                      : 0,
                  transition: 'opacity 0.1s ease-in',
                  '&:hover': {
                    opacity: 1,
                  },
                }}
              >
                <Header onMenuOpenChange={getOnMenuChange('frameIndex', j)} {...f} />
              </Box>
            ) : null,
          ),
        )}
        {laneGroups.map((lg, i) =>
          lg.headerProps.show ? (
            <Box
              key={`LaneGroupHeader-${i}`}
              className="nodrag"
              sx={{
                zIndex: 1000000,
                pointerEvents: 'all',
                position: 'absolute',
                height: lg.height,
                left: -35 + position.x,
                top: lg.y + position.y,
                transform: 'translate(-100%, 0)',
                opacity: menuOpenFor.laneGroupIndex === i ? 1 : hovered.laneGroupIndex === i ? 0.4 : 0,
                transition: 'opacity 0.1s ease-in',
                '&:hover': {
                  opacity: 1,
                },
              }}
            >
              <Header
                onMenuOpenChange={getOnMenuChange('laneGroupIndex', i)}
                isGroupHeader
                orientation="vertical"
                bgColor="rgba(212, 212, 216, 1)"
                {...lg}
              />
            </Box>
          ) : null,
        )}
        {laneGroups.map((lg, i) =>
          lg.lanes.map((l, j) =>
            l.headerProps.show ? (
              <Box
                key={`LaneHeader-${i}-${j}`}
                className="nodrag"
                sx={{
                  zIndex: 1000000,
                  pointerEvents: 'all',
                  position: 'absolute',
                  height: l.height,
                  left: l.x - 4 + position.x,
                  top: l.y + position.y,
                  transform: 'translate(-100%, 0)',
                  opacity:
                    menuOpenFor.laneIndex === j ? 1 : hovered.laneGroupIndex === i && hovered.laneIndex === j ? 0.4 : 0,
                  transition: 'opacity 0.1s ease-in',
                  '&:hover': {
                    opacity: 1,
                  },
                }}
              >
                <Header onMenuOpenChange={getOnMenuChange('laneIndex', j)} orientation="vertical" {...l} />
              </Box>
            ) : null,
          ),
        )}
      </HeaderContainer>
    </Box>
  );
};
export const Script = memo(_Script);

import { Handle, Node, NodeProps, Position } from '@xyflow/react';
import { Name } from '../name/name';
import { useUpdateEntity } from '../../hooks/use-update-entity';
import { Box } from '@mui/material';
import { Overlay } from '../overlay/overlay';
import { ConstructNodeData, EntityType, Label } from '@xspecs/single-source-model';
import { ScriptToggleButton } from './script-toggle-button';
import { Fragment, memo, useMemo, useRef, useState } from 'react';
import {
  arrow,
  autoPlacement,
  autoUpdate,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import { LabelsStack } from '../../../../labels/labels-stack/labels-stack';
import { useLabels } from '../../floating/menu/labels-details-button/use-labels';
import {
  FloatingLabelsSelector,
  FloatingLabelsSelectorProps,
} from '../../../../labels/floating-labels-selector/floating-labels-selector';
import { createPortal } from 'react-dom';
import { FloatingMenu, useFloatingMenu } from '../../floating-menu/floating-menu';

export const ConstructNode = (props: NodeProps<Node<ConstructNodeData>>) => {
  const { id, selected, data, dragging } = props;
  const { onRename, onFontSizeChange } = useUpdateEntity(id);

  const {
    showFloating,
    floatingStyles,
    referenceRef,
    floatingRef,
    context,
    arrowRef,
    referenceProps,
    floatingProps,
    onManageLabels,
  } = useFloatingMenu();

  const offsetFromAttachments = useMemo(
    () =>
      data.attachments
        ?.filter((attachment) => attachment.subType === 'Actor')
        .reduce((offset, attachment) => offset + attachment.height + attachment.attachmentsPadding, 0) ?? 0,
    [data.attachments],
  );

  const backgroundContainerSx = useMemo(
    () => ({
      width: '100%',
      height: '100%',
      backgroundColor: data.backgroundColor,
    }),
    [data.backgroundColor],
  );

  return (
    <Fragment>
      <Box sx={backgroundContainerSx} />
      <Handles color={data.color} />
      {data.hasOverlay ? <Overlay /> : null}
      <Name
        {...referenceProps}
        ref={referenceRef}
        id={id}
        value={data.name}
        onRename={onRename}
        onFontSizeChange={onFontSizeChange}
        isSelected={Boolean(selected)}
        isNew={data.isNew}
        isDragging={dragging}
        className="node"
        fontSize={data.attributes.fontSize}
      />
      {data.type === EntityType.Narrative || data.type === EntityType.Action ? (
        <ScriptToggleButton
          id={id}
          hasOverlay={data.hasOverlay}
          isExpanded={Boolean(data.isExpanded)}
          type={data.type as EntityType}
        />
      ) : null}
      {showFloating ? (
        <FloatingMenu
          ref={floatingRef}
          id={id}
          styles={floatingStyles}
          arrowRef={arrowRef}
          context={context}
          type={data.type}
          labels={data.labels}
          onManageLabels={onManageLabels}
          name={data.name}
          withCopy
          {...floatingProps}
        />
      ) : null}
      {data.labels.length > 0 ? (
        <CanvasLabelsStack
          id={id}
          labels={data.labels}
          hasOverlay={data.hasOverlay}
          topOffset={offsetFromAttachments}
        />
      ) : null}
    </Fragment>
  );
};

// Canvas Labels Stack
type CanvasLabelsStackProps = {
  id: string;
  hasOverlay: boolean;
  labels: Label[];
  topOffset: number;
};

const CanvasLabelsStack = memo((props: CanvasLabelsStackProps) => {
  const { hasOverlay, id, topOffset, labels: selectedLabels } = props;
  const [isOpen, setIsOpen] = useState(false);
  const arrowRef = useRef(null);
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'right',
    middleware: [
      offset(15),
      autoPlacement({
        allowedPlacements: ['right', 'right-start', 'right-end'],
      }),
      arrow({ element: arrowRef.current }),
    ],
    whileElementsMounted: autoUpdate,
  });
  const click = useClick(context);
  const dismiss = useDismiss(context, {
    referencePress: false,
  });
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss, click]);
  const { labels, createAndSelectLabel, onSelectedLabelsChange, onManageLabels } = useLabels(id);

  const sx = useMemo(
    () => ({
      position: 'absolute',
      top: 0,
      width: 'calc(100% - 8px)',
      transform: `translate(0, calc(-100% - 4px - ${topOffset}px))`,
    }),
    [topOffset],
  );

  const floatingLabelsSelectorProps = useMemo<FloatingLabelsSelectorProps>(
    () => ({
      RootProps: { className: 'nowheel', ref: refs.setFloating, sx: floatingStyles, ...getFloatingProps() },
      context,
      arrowRef,
      selectorProps: { labels, selectedLabels, onSelectedLabelsChange, createAndSelectLabel, onManageLabels },
    }),
    [
      context,
      createAndSelectLabel,
      floatingStyles,
      getFloatingProps,
      labels,
      onManageLabels,
      onSelectedLabelsChange,
      refs.setFloating,
      selectedLabels,
    ],
  );

  return (
    <Fragment>
      <Box ref={refs.setReference} sx={sx} {...getReferenceProps()}>
        <LabelsStack hasOverlay={hasOverlay} labels={selectedLabels} />
      </Box>
      {isOpen ? createPortal(<FloatingLabelsSelector {...floatingLabelsSelectorProps} />, document.body) : null}
    </Fragment>
  );
});
CanvasLabelsStack.displayName = 'CanvasLabelsStack';

// Handles
const Handles = ({ color }: { color: string }) => {
  const styles = useMemo(
    () => ({
      backgroundColor: color,
    }),
    [color],
  );

  return (
    <Fragment>
      <Handle id={Position.Left} type="source" position={Position.Left} style={styles} />
      <Handle id={Position.Right} type="source" position={Position.Right} style={styles} />
      <Handle id={Position.Top} type="source" position={Position.Top} style={styles} />
      <Handle id={Position.Bottom} type="source" position={Position.Bottom} style={styles} />
    </Fragment>
  );
};

import { ScriptBase } from '../../entities/scripts/ScriptBase';
import {
  CommonNodeData,
  FrameGroup as FrameGroupLayout,
  Frame as FrameLayout,
  LaneGroup as LaneGroupLayout,
  Lane as LaneLayout,
  ScriptNodeData,
  HeaderProps,
} from './Graph.types';
import { LaneGroup as LaneGroupConfig, FrameGroup as FrameGroupConfig, PermissionAction } from 'narrative-studio-sdk';
import { ScriptConfig } from '../../entities/scripts/ScriptConfig';

export class ScriptToScriptNodeTranslator {
  public static translate(script: ScriptBase, commonNodeData: CommonNodeData): ScriptNodeData {
    return {
      ...commonNodeData,
      width: script.width,
      height: script.height,
      labels: script.labels,
      attributes: { fontSize: script.attributes.fontSize },
      frameGroups: this.getFrameGroups(script),
      laneGroups: this.getLaneGroups(script),
    };
  }

  private static getLaneGroups(script: ScriptBase): LaneGroupLayout[] {
    let cumulativeLaneGroupY = 0;
    return script.laneGroups.map((laneGroup) => {
      const laneGroupConfig = script.config.laneGroups[laneGroup.configIndex];
      let cumulativeLaneY = 0;
      const lanes = laneGroup.laneIds.map((laneId, laneIndex) => {
        const lane = script.lanes.find((l) => l.id === laneId);
        const laneHeight = lane?.height ?? laneGroupConfig.laneHeight ?? script.config.defaultLaneHeight;
        const laneStyle = laneGroupConfig?.lanes?.[laneIndex]?.style ?? laneGroupConfig?.lanes?.[0]?.style;
        const laneY = cumulativeLaneY;
        cumulativeLaneY += laneHeight;
        return {
          x: 0,
          y: laneY,
          width: script.width,
          height: laneHeight,
          style: {
            backgroundColor: laneStyle?.backgroundColor ?? '#F8F8F8',
            borderWidth: laneStyle?.borderWidth ?? 1,
            borderColor: laneStyle?.borderColor ?? 'rgb(158, 158, 158)',
          },
          headerProps: this.getLaneHeaderProps(script, laneGroupConfig, laneIndex),
          labelProps: {
            text: [
              {
                value: lane?.label ?? '',
                style: {
                  fontSize: 10,
                  fontWeight: 500,
                },
                onRename: {
                  command: 'RenameScriptLaneCommand',
                  params: { scriptId: script.id, laneIndex: laneIndex, value: '' },
                },
              },
            ],
            x: 10,
            y: laneY + 10,
          },
        } satisfies LaneLayout;
      });
      const laneGroupHeight = lanes.reduce((sum, lane) => sum + lane.height, 0);
      const laneGroupY = cumulativeLaneGroupY;
      cumulativeLaneGroupY += laneGroupHeight;
      return {
        x: 0,
        y: laneGroupY,
        width: script.width,
        height: laneGroupHeight,
        style: {
          backgroundColor: laneGroupConfig?.style?.backgroundColor ?? 'transparent',
          borderWidth: laneGroupConfig?.style?.borderWidth ?? 1,
          borderColor: laneGroupConfig?.style?.borderColor ?? 'rgb(158, 158, 158)',
        },
        headerProps: {
          show: false,
        },
        lanes: lanes,
      } satisfies LaneGroupLayout;
    });
  }

  private static getFrameGroups(script: ScriptBase): FrameGroupLayout[] {
    let cumulativeFrameGroupX = 0;
    return script.frameGroups.map((frameGroup) => {
      const frameGroupConfig = script.config.frameGroups[frameGroup.configIndex];
      let cumulativeFrameX = 0;
      const frames = frameGroup.frameIds.map((frameId, frameIndex) => {
        const frame = script.frames.find((f) => f.id === frameId);
        if (!frame) {
          throw new Error(`Frame with id ${frameId} not found`);
        }
        const frameStyle = frameGroupConfig?.frames?.[frameIndex]?.style ?? frameGroupConfig?.frames?.[0]?.style;
        const frameX = cumulativeFrameX;
        cumulativeFrameX += frame.width;
        return {
          x: frameX,
          y: 0,
          width: frame.width,
          height: script.calculatedHeight(),
          style: {
            backgroundColor: frameStyle?.backgroundColor ?? 'transparent',
            borderWidth: frameStyle?.borderWidth ?? 1,
            borderColor: frameStyle?.borderColor ?? 'rgb(158, 158, 158)',
          },
          headerProps: this.getFrameHeaderProps(script, frameGroupConfig, frameIndex),
        } satisfies FrameLayout;
      });
      const frameGroupX = cumulativeFrameGroupX;
      cumulativeFrameGroupX += frames.reduce((sum, frame) => sum + frame.width, 0);
      return {
        x: frameGroupX,
        y: 0,
        width: frames.reduce((sum, frame) => sum + frame.width, 0),
        height: script.calculatedHeight(),
        style: {
          backgroundColor: frameGroupConfig?.style?.backgroundColor ?? 'transparent',
          borderWidth: frameGroupConfig?.style?.borderWidth ?? 1,
          borderColor: frameGroupConfig?.style?.borderColor ?? 'rgb(158, 158, 158)',
        },
        headerProps: {
          show: false,
        },
        frames: frames,
      } satisfies FrameGroupLayout;
    });
  }

  private static getFrameHeaderProps(
    script: ScriptBase,
    frameGroupConfig: FrameGroupConfig,
    frameIndex: number,
  ): HeaderProps {
    const canAddFrame =
      ScriptConfig.hasFrameGroupPermission(frameGroupConfig, PermissionAction.ADD) &&
      (frameGroupConfig.frameLimits.max === Infinity || script.frames.length < frameGroupConfig.frameLimits.max);
    const canDeleteFrame =
      ScriptConfig.hasFrameGroupPermission(frameGroupConfig, PermissionAction.REMOVE) &&
      script.frames.length > (frameGroupConfig.frameLimits.min ?? 1);
    const canMoveFrame = ScriptConfig.hasFrameGroupPermission(frameGroupConfig, PermissionAction.REORDER);
    if (!canAddFrame && !canDeleteFrame) {
      return {
        show: false,
      };
    }
    const onInsertBefore = canAddFrame
      ? {
          command: 'AddScriptFrameCommand',
          params: { scriptId: script.id, frameIndex },
        }
      : undefined;
    const onInsertAfter = canAddFrame
      ? {
          command: 'AddScriptFrameCommand',
          params: { scriptId: script.id, frameIndex: frameIndex + 1 },
        }
      : undefined;
    return {
      show: true,
      disableDrag: !canMoveFrame,
      onInsertBefore,
      onInsertAfter,
      menu: {
        items: [
          ...(canAddFrame
            ? [
                {
                  id: 'insert-frame-before',
                  label: 'Insert Before',
                  icon: 'insert-before',
                  command: onInsertBefore!.command,
                  params: onInsertBefore!.params,
                },
                {
                  id: 'insert-frame-after',
                  label: 'Insert After',
                  icon: 'insert-after',
                  ...onInsertAfter,
                  command: onInsertAfter!.command,
                  params: onInsertAfter!.params,
                },
              ]
            : []),
          ...(canDeleteFrame
            ? [
                {
                  id: 'delete-frame',
                  label: 'Delete',
                  icon: 'delete',
                  command: 'DeleteScriptFrameCommand',
                  params: { scriptId: script.id, frameIndex },
                },
              ]
            : []),
        ],
      },
    } satisfies HeaderProps;
  }

  private static getLaneHeaderProps(
    script: ScriptBase,
    laneGroupConfig: LaneGroupConfig,
    laneIndex: number,
  ): HeaderProps {
    const canAddLane =
      ScriptConfig.hasLaneGroupPermission(laneGroupConfig, PermissionAction.ADD) &&
      (laneGroupConfig.laneLimits.max === Infinity || script.lanes.length < laneGroupConfig.laneLimits.max);
    const canDeleteLane =
      ScriptConfig.hasLaneGroupPermission(laneGroupConfig, PermissionAction.REMOVE) &&
      script.lanes.length > (laneGroupConfig.laneLimits.min ?? 1);
    const canMoveLane = ScriptConfig.hasLaneGroupPermission(laneGroupConfig, PermissionAction.REORDER);
    const onInsertBefore = canAddLane
      ? {
          command: 'AddScriptLaneCommand',
          params: { scriptId: script.id, laneIndex },
        }
      : undefined;
    const onInsertAfter = canAddLane
      ? {
          command: 'AddScriptLaneCommand',
          params: { scriptId: script.id, laneIndex: laneIndex + 1 },
        }
      : undefined;
    if (!canAddLane && !canDeleteLane) {
      return {
        show: false,
        disableDrag: !canMoveLane,
      };
    }
    return {
      show: true,
      onInsertBefore,
      onInsertAfter,
      disableDrag: !canMoveLane,
      menu: {
        items: [
          ...(canAddLane
            ? [
                {
                  id: 'insert-lane-before',
                  label: 'Insert Before',
                  icon: 'insert-before',
                  command: onInsertBefore!.command,
                  params: onInsertBefore!.params,
                },
                {
                  id: 'insert-lane-after',
                  label: 'Insert After',
                  icon: 'insert-after',
                  command: onInsertAfter!.command,
                  params: onInsertAfter!.params,
                },
              ]
            : []),
          ...(canDeleteLane
            ? [
                {
                  id: 'delete-lane',
                  label: 'Delete',
                  icon: 'delete',
                  command: 'DeleteScriptLaneCommand',
                  params: { scriptId: script.id, laneIndex },
                },
              ]
            : []),
        ],
      },
    } satisfies HeaderProps;
  }
}

import { EntityType } from '../EntityType';
import { ScriptBase } from './ScriptBase';
import { ScriptConfig } from './ScriptConfig';
import { PermissionAction } from 'narrative-studio-sdk';

export class ActionScript extends ScriptBase {
  //static version = '1.0.2'; // Renames tags to labels

  get config(): ScriptConfig {
    return new ScriptConfig(
      [
        {
          permissions: { actions: [PermissionAction.ADD, PermissionAction.UPDATE, PermissionAction.REMOVE] },
          frameGroupLimits: { min: 1, max: 1 },
          frameLimits: { min: 1, max: Infinity },
          frameWidth: 200,
          frames: [{}, {}, {}, {}],
        },
      ],
      [
        {
          permissions: { actions: [PermissionAction.ADD, PermissionAction.UPDATE, PermissionAction.REMOVE] },
          laneGroupLimits: { min: 1, max: 1 },
          laneLimits: { min: 1, max: Infinity },
          allowedEntities: {
            type: 'SPECIFIC',
            entities: [
              EntityType.Command,
              EntityType.Data,
              EntityType.Event,
              EntityType.Process,
              EntityType.Constraints,
              EntityType.ExternalSystem,
              EntityType.Resolver,
              EntityType.ReadModel,
              EntityType.Projection,
              EntityType.Gateway,
            ],
          },
          autoIngestInCorrectLane: false,
          entityLimits: { min: 1, max: Infinity },
          laneHeight: 200,
          style: { backgroundColor: '#F8F8F8' },
          lanes: [{ style: { backgroundColor: '#F8F8F8' } }],
        },
      ],
    );
  }

  get icon(): string {
    return '/action.svg';
  }

  get type(): EntityType {
    return EntityType.ActionScript;
  }
}

import { LogoutOutlined, PersonAddAltRounded } from '@mui/icons-material';
import { Box, Button, IconButton } from '@mui/material';
import { NavBarProps } from './nav-bar';
import { SettingsMenu } from './settings-menu/settings-menu';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { showInviteUsersVar } from '../../state/state';
import { UpgradeButton } from './upgrade-button/upgrade-button';
import { HelpMenu } from './help-menu/help-menu';
import { StatusIndicator } from './status-indicator/status-indicator';
import { useAuth } from '../../auth';
import { AppTypeEvent, useTrackEvents } from '../../hooks/use-track-events';
import { RoutePaths } from '../../config/route-paths/route-paths';
import { useMatch } from 'react-router-dom';

type NavRightItemsProps = { isPrimary?: boolean } & Pick<NavBarProps, 'hideOrganizations' | 'isLoggedIn'>;

export const NavRightItems = (props: NavRightItemsProps) => {
  const { isLoggedIn, hideOrganizations, isPrimary } = props;

  const { logout } = useAuth();

  const { trackEvent } = useTrackEvents();

  const { formatMessage: f } = useIntl();

  const onLogout = useCallback(() => {
    trackEvent(AppTypeEvent.LoggedOut, null);
    logout({ logoutParams: { returnTo: window.location.origin + RoutePaths.Login } });
  }, [logout, trackEvent]);

  const onInvite = useCallback(() => showInviteUsersVar(true), []);

  const isInSpacesRoute = useMatch(`/:organizationName/spaces`) !== null;

  return (
    <Box
      sx={{
        color: { xs: 'primary.contrastText', md: isPrimary ? 'primary.contrastText' : 'action.active' },
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        alignSelf: 'flex-end',
      }}
    >
      {isLoggedIn && hideOrganizations && !isInSpacesRoute ? (
        <IconButton data-testid="logout-button" sx={{ color: 'inherit', ml: 'auto', mr: 1 }} onClick={onLogout}>
          <LogoutOutlined color="inherit" />
        </IconButton>
      ) : null}
      {(isLoggedIn && !hideOrganizations) || isInSpacesRoute ? (
        <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', gap: 1, alignSelf: 'flex-end' }}>
          {!isInSpacesRoute ? (
            <Button
              data-testid="nav-right-items-invite-button"
              sx={{ mr: 1, display: { xs: 'none', md: 'flex' } }}
              startIcon={<PersonAddAltRounded />}
              onClick={onInvite}
              size="small"
              variant="outlined"
            >
              {f({ id: 'invite-users' })}
            </Button>
          ) : null}
          {!isInSpacesRoute ? <UpgradeButton /> : null}
          <HelpMenu />
          {!isInSpacesRoute ? <StatusIndicator /> : null}
          <SettingsMenu />
        </Box>
      ) : null}
    </Box>
  );
};

import { Button, SxProps, Theme, Typography } from '@mui/material';
import { Icon, IconName } from '@xspecs/design-system';
import { useMemo } from 'react';

export interface SpacesSideBarLinkProps {
  label: string;
  icon: IconName;
  onChange: () => void;
  selected: boolean;
  collapsed: boolean;
}

export const SpacesSideBarLink = (props: SpacesSideBarLinkProps) => {
  const { label, icon, onChange, selected, collapsed } = props;

  const linkSx: SxProps<Theme> = useMemo(
    () => ({
      display: 'flex',
      justifyContent: 'flex-start',
      gap: 1,
      p: 1,
      backgroundColor: selected ? 'base.accent' : 'white',
      color: selected ? 'text.primary' : 'text.secondary',
      minWidth: 'auto',
    }),
    [selected],
  );

  return (
    <Button disableRipple sx={linkSx} onClick={onChange}>
      {icon ? <Icon name={icon} /> : null}
      {label && !collapsed ? (
        <Typography variant="buttonMedium" textTransform="none" width="auto">
          {label}
        </Typography>
      ) : null}
    </Button>
  );
};

import { FC } from 'react';
import { IconComponentProps } from '../icon';
import { px } from '../../../utils';

export const Line: FC<IconComponentProps> = ({ strokeWidth = '0.5' }) => {
  return (
    <>
      <path d="M0 8H16" stroke="black" strokeOpacity="0.54" strokeWidth={px(strokeWidth)} />
    </>
  );
};

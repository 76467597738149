import { Box, Button, Stack, SxProps, Theme, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export interface UpgradePlanProps {
  title: string;
  description: string;
  buttonText: string;
  link: string;
  collapsed: boolean;
}

export const UpgradePlan = (props: UpgradePlanProps) => {
  const { title, description, buttonText, link, collapsed } = props;
  const navigate = useNavigate();

  const navigateToPlans = useCallback(() => {
    if (!link) return;
    navigate(link, {
      state: {
        preventNavigation: true,
      },
    });
  }, [navigate, link]);

  if (collapsed) {
    return (
      <Button variant="text" sx={imgWrapperSx} onClick={navigateToPlans}>
        <img alt={title} src="/upgrade-plan.svg" loading="lazy" width="40px" style={{ margin: '0 auto' }} />
      </Button>
    );
  }

  return (
    <Box border="1px solid" borderColor="base.border" borderRadius={3} p={2} gap={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Stack gap={1} width="75%">
          <Typography variant="subtitle2">{title}</Typography>
          <Typography variant="body2">{description}</Typography>
        </Stack>
        <img alt={title} src="/upgrade-plan.svg" loading="lazy" width="20%" style={{ maxWidth: '100px' }} />
      </Box>
      <Button variant="text" fullWidth sx={buttonSx} onClick={navigateToPlans}>
        {buttonText}
      </Button>
    </Box>
  );
};

const imgWrapperSx: SxProps<Theme> = { p: 0, m: 0, minWidth: 'auto' };

const buttonSx: SxProps<Theme> = {
  backgroundColor: 'base.accent',
  color: 'text.primary',
  textTransform: 'none',
  mt: 2,
};

import { CommandBase, IParams } from '../framework/CommandBase';
import { Position } from '../../types';
import { EventBase } from '../framework/EventBase';
import { AttachmentType, EntityType } from '../../entities/EntityType';
import { Narrative } from '../../entities/constructs/Narrative';
import { Action } from '../../entities/constructs/Action';
import { NarrativeScript } from '../../entities/scripts/NarrativeScript';
import { EntityBase } from '../../entities/EntityBase';
import { ActionScript } from '../../entities/scripts/ActionScript';
import { EntityParserFactory } from '../../entities/EntityParserFactory';
import { ScriptBase } from '../../entities/scripts/ScriptBase';
import { CommandError } from '../../ErrorStore';
import { Attachment } from '../../entities/assets/Attachment';
import { AssetBase } from '../../entities/assets/AssetBase';
import { Query } from '../../entities/assets/Query';
import { GqlEntityBase } from '../../entities/gql-entities/GqlEntityBase';
import { GqlOperation } from '../../entities/gql-entities/GqlOperation';
import { sid } from '@xspecs/short-id';
import { Edge, HandleLocation } from '../../entities/transitions/Edge';

export interface BaseParams extends IParams {
  id: string;
  name: string;
  position: Position;
}

type AddGqlEntityParams = BaseParams & {
  type: EntityType.GqlField | EntityType.GqlOperation;
  queryId: string;
};
export type AddEntityParams =
  | (BaseParams & {
      type: EntityType.Attachment;
      subType: AttachmentType;
      assetId?: string;
      autoCreate?: boolean;
    })
  | (BaseParams & {
      type: EntityType.Thread;
      createdBy: string;
    })
  | (BaseParams & {
      type: Exclude<EntityType, EntityType.Attachment | EntityType.Thread>;
      subType?: never;
      createdBy?: never;
      assetId?: never;
    })
  | AddGqlEntityParams;

interface EntitiesAddedParams extends IParams {
  entityIds: string[];
  cursorPosition: Position;
}

export class EntitiesAddedEvent extends EventBase {
  static eventType = 'EntityAddedEvent';

  constructor(public readonly params: EntitiesAddedParams, public readonly source = AddEntityCommand) {
    super();
  }
}

export class AddEntityCommand extends CommandBase<AddEntityParams> {
  execute(params: AddEntityParams): EntitiesAddedEvent | CommandError {
    const entities = this.createEntities(params);
    if (entities instanceof CommandError) return entities;
    entities.forEach((entity) => {
      this.model.entityRepository.add(entity);
    });
    return new EntitiesAddedEvent({
      entityIds: entities.map((entity) => entity.id),
      cursorPosition: params.position,
    });
  }

  private createEntities(params: AddEntityParams): EntityBase[] | CommandError {
    const data = { ...params, scopes: this.model.entityRepository.getScopes() };
    const entity = EntityParserFactory.parse(data, params.type, undefined, true);
    // entity.position = { x: entity.position.x - entity.width / 2, y: entity.position.y - entity.height / 2 };
    switch (params.type) {
      case EntityType.Action: {
        const action = entity as Action;
        action.script = new ActionScript(`${action.id}_script`, 'script', action, params.position);
        action.script.position = {
          x: params.position.x + action.width * 1.5,
          y: params.position.y + action.height * 2,
        };
        ScriptBase.initialize(action.script);
        const edge = this.createEdgeBetweenConstructAndScript(action);
        return [action, action.script, edge];
      }
      case EntityType.Narrative: {
        const narrative = entity as Narrative;
        narrative.script = new NarrativeScript(`${narrative.id}_script`, 'script', narrative, params.position);
        narrative.script.position = {
          x: params.position.x + narrative.width * 2,
          y: params.position.y + narrative.height,
        };
        ScriptBase.initialize(narrative.script);
        const edge = this.createEdgeBetweenConstructAndScript(narrative);
        return [narrative, narrative.script, edge];
      }
      case EntityType.Attachment:
        if (params.assetId) {
          const asset = this.model.entityRepository.get<AssetBase>(params.assetId);
          if (!asset) {
            return CommandError.of(new Error(`Asset with id ${params.assetId} not found`), 'error');
          }
          const attachment = entity as Attachment;
          attachment.linkAsset(asset);
          this.model.entityRepository.update(asset);
          return [attachment];
        }
        if (params.autoCreate) {
          const attachment = EntityParserFactory.parse(data) as Attachment;
          const asset = EntityParserFactory.parse(
            {
              ...data,
              id: `${data.id}_${params.subType}`,
            },
            EntityType[params.subType],
          );
          this.model.entityRepository.add(asset);
          attachment.linkAsset(asset);
          this.model.entityRepository.update(asset);
          return [attachment, asset];
        }
        return [entity];
      case EntityType.GqlField: {
        const returnEntities: EntityBase[] = [entity];
        const query = this.model.entityRepository.get<Query>((params as AddGqlEntityParams).queryId);
        if (!(query instanceof Query))
          return CommandError.of(
            new Error(`Query with id ${(params as AddGqlEntityParams).queryId} not found`),
            'error',
          );
        if (params.type === EntityType.GqlField && !query.gqlEntities.find((p) => p.type === EntityType.GqlOperation)) {
          const gqlOperation = this.createGqlOperation(query, params);
          query.addGqlEntity(gqlOperation);
          this.model.entityRepository.add(gqlOperation);
          returnEntities.push(gqlOperation);
        }
        query.addGqlEntity(entity as GqlEntityBase);
        this.model.entityRepository.add(entity);
        query.syncGqlEntitiesToQuery();
        this.model.entityRepository.update(query);
        return returnEntities;
      }
      case EntityType.Query: {
        const query = entity as Query;
        const gqlOperation = this.createGqlOperation(query, params);
        query.addGqlEntity(gqlOperation);
        query.syncGqlEntitiesToQuery();
        this.model.entityRepository.add(gqlOperation);
        return [query, gqlOperation];
      }
      default:
        return [entity];
    }
  }

  private createGqlOperation(
    query: Query,
    params: BaseParams & {
      type: Exclude<EntityType, EntityType.Attachment | EntityType.Thread>;
      subType?: never;
      createdBy?: never;
      assetId?: never;
    },
  ) {
    return EntityParserFactory.parse<GqlOperation>({
      id: sid(),
      name: query.operationName,
      type: EntityType.GqlOperation,
      position: params.position,
      scopes: this.model.entityRepository.getScopes(),
      parentId: query.id,
    });
  }

  private createEdgeBetweenConstructAndScript(construct: Narrative | Action) {
    return new Edge({
      id: sid(),
      source: construct,
      target: construct.script,
      sourceHandleLocation: HandleLocation.Right,
      targetHandleLocation: HandleLocation.Left,
    });
  }
}

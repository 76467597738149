import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { FC, useCallback, useState, MouseEvent } from 'react';
import { EdgeToolbarElementStepper } from '@xspecs/single-source-model';
import { Icon } from '@xspecs/design-system';
import { useCommandDispatch } from '../../../../../../wrappers/application-context/application-context';
import { Menu, MenuItem } from '@mui/material';
import { useEdgeContext } from '../edge-toolbar-context';
import { useIntl } from 'react-intl';

export const Stepper: FC<EdgeToolbarElementStepper> = (props) => {
  const { value, menu, tooltip, onIncrementClick, onDecrementClick } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const dispatch = useCommandDispatch();
  const { edgeId } = useEdgeContext();
  const { formatMessage: f } = useIntl();

  const onIncrement = useCallback(() => {
    dispatch(onIncrementClick.command, {
      ...onIncrementClick.params,
      id: edgeId,
    });
  }, [dispatch, edgeId, onIncrementClick.command, onIncrementClick.params]);

  const onDecrement = useCallback(() => {
    dispatch(onDecrementClick.command, {
      ...onDecrementClick.params,
      id: edgeId,
    });
  }, [dispatch, edgeId, onDecrementClick.command, onDecrementClick.params]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onOptionClick = useCallback(
    (option: EdgeToolbarElementStepper['menu']['items'][number]) => () => {
      dispatch(menu.onSelect.command, {
        ...menu.onSelect.params,
        [menu.onSelect.params.valueKey]: option.value,
        id: edgeId,
      });
      handleClose();
    },
    [dispatch, edgeId, handleClose, menu.onSelect.command, menu.onSelect.params],
  );

  const onClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  }, []);

  return (
    <Stack direction="row" alignItems="center">
      <Tooltip title={tooltip} placement="top" disableInteractive>
        <IconButton sx={buttonSx} onClick={onClick}>
          <Typography>{value}</Typography>
        </IconButton>
      </Tooltip>
      <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
        {menu.items.map((item, index) => (
          <MenuItem
            key={`StepperMenuItem${index}`}
            style={{
              width: 60,
              padding: '4px 8px',
            }}
            onClick={onOptionClick(item)}
          >
            <Typography>{item.label}</Typography>
            {item.selected ? (
              <Box ml="auto">
                <Icon name="done" width={12} height={12} />
              </Box>
            ) : null}
          </MenuItem>
        ))}
      </Menu>

      <Stack>
        <Tooltip title={f({ id: 'increase' })} placement="top" disableInteractive>
          <IconButton sx={arrowButtonSx} onClick={onIncrement}>
            <Icon name="keyboard-up" width={14} height={14} />
          </IconButton>
        </Tooltip>
        <Tooltip title={f({ id: 'decrease' })} placement="top" disableInteractive>
          <IconButton sx={arrowButtonSx} onClick={onDecrement}>
            <Icon name="keyboard-down" width={14} height={14} />
          </IconButton>
        </Tooltip>
      </Stack>
    </Stack>
  );
};

const menuStyle = {
  width: 60,
  minWidth: 60,
  maxWidth: 60,
  padding: 0,
  margin: 0,
};

const buttonSx = {
  borderRadius: '4px',
  p: 0.25,
};

const arrowButtonSx = {
  p: 0,
  borderRadius: '4px',
};

import { Button, Stack, Typography } from '@mui/material';
import { ArrowBackOutlined } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useMemo } from 'react';
import { getRoutePathFromParams, RoutePaths } from '../../../config/route-paths/route-paths';
import { useIntl } from 'react-intl';
import { useActiveWorkspace } from '../../../hooks/use-active-workspace';
import { useActiveOrganization } from '../../../hooks/use-active-organization';

export const SettingsNav = () => {
  const { formatMessage: f } = useIntl();

  const { organization } = useActiveOrganization();
  const { workspace } = useActiveWorkspace();

  const navigate = useNavigate();
  const location = useLocation();

  const sections = useMemo(
    () => [
      {
        label: f({ id: 'plans' }),
        id: '#plans',
        active: location.hash === '#plans',
      },
      {
        label: f({ id: 'billing' }),
        id: '#billing',
        active: location.hash === '#billing',
      },
      {
        label: f({ id: 'apps' }),
        id: `#apps`,
        active: location.hash === '#apps',
      },
    ],
    [f, location.hash],
  );

  const goBack = useCallback(() => {
    if (organization && workspace) {
      navigate(
        getRoutePathFromParams(RoutePaths.Workspace, {
          organizationName: organization?.name,
          workspaceName: workspace?.name,
        }),
      );
    }
  }, [navigate, organization, workspace]);

  const activeSession = useMemo(() => sections.find(({ active }) => active), [sections]);

  const onItemClick = useCallback(
    (id: string) => () => {
      if (!organization) return;
      navigate(
        getRoutePathFromParams(RoutePaths.Settings, {
          organizationName: organization.name,
        }) + id,
        {
          state: {
            preventNavigation: true,
          },
        },
      );
      setTimeout(() => {
        const element = document.getElementById(id.replace('#', ''));
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
          });
        }
      }, 100);
    },
    [navigate, organization],
  );

  useEffect(() => {
    if (!organization) return;
    if (activeSession) {
      setTimeout(() => {
        const id = location.hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
          });
        }
      }, 1000);
    } else {
      navigate(
        getRoutePathFromParams(RoutePaths.Settings, {
          organizationName: organization?.name,
        }) + '#plans',
        {
          replace: true,
          state: {
            preventNavigation: true,
          },
        },
      );
    }
  }, [activeSession, location.hash, navigate, organization, organization?.name]);

  return (
    <Stack width={300} alignItems="flex-start" gap={2}>
      <Button startIcon={<ArrowBackOutlined />} onClick={goBack} fullWidth sx={buttonSx}>
        {f({ id: 'back-to-space' })}
      </Button>
      <Stack width="100%" gap={1.5} px={4}>
        {sections.map(({ label, id, active }) => (
          <Typography
            key={'settings-nav-' + id}
            color={active ? 'primary.main' : undefined}
            onClick={onItemClick(id)}
            sx={{
              cursor: 'pointer',
              '&:hover': {
                color: 'primary.main',
              },
            }}
          >
            {label}
          </Typography>
        ))}
      </Stack>
    </Stack>
  );
};

const buttonSx = {
  justifyItems: 'flex-start',
};

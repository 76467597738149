import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { EventHandlerBase } from '../framework/EventHandlerBase';
import { ScriptBase } from '../../entities/scripts/ScriptBase';
import { ConstructBase } from '../../entities/constructs/ConstructBase';
import { BoundingBox } from '../../read-models/boundary/BoundariesIndex';
import { CommandError } from '../../ErrorStore';
import { EntitiesDraggedEvent } from './DragEntitiesCommand';
import { Position } from '../../types';
import { determinePrecedentTarget } from './IngestEntitiesCommand';
import { EntityParserFactory } from '../../entities/EntityParserFactory';
import { sid } from '@xspecs/short-id';
import { MouseMoveEvent } from './MouseMoveCommand';
import { EntityType } from '../../entities/EntityType';

export type DropTarget = {
  entityId: string;
  wantedBoundedBox: BoundingBox;
  wantedCentroid: Position;
  actualBoundedBox?: BoundingBox;
  actualCentroid?: Position;
};

interface DropTargetsHighlightedEventParams extends IParams {
  accepted: DropTarget[];
  rejected: DropTarget[];
  error?: Error;
}

export class DropTargetsHighlightedEvent extends EventBase {
  static eventType = 'DropTargetsHighlightedEvent';

  constructor(
    public readonly params: DropTargetsHighlightedEventParams,
    public readonly source = HighlightDropTargetsCommand,
  ) {
    super();
  }
}

interface HighLightDropTargetsWithEntitiesCommandParams extends IParams {
  entityIds: string[];
  dxDy: Position;
  cursorPosition: Position;
}

interface HighLightDropTargetsWithCursorCommandParams extends IParams {
  type: string;
  cursorPosition: Position;
}

export type HighlightDropTargetsCommandParams =
  | HighLightDropTargetsWithEntitiesCommandParams
  | HighLightDropTargetsWithCursorCommandParams;

export class HighlightDropTargetsCommand extends CommandBase<HighlightDropTargetsCommandParams> {
  shouldSave(): boolean {
    return false;
  }

  execute(params: HighlightDropTargetsCommandParams): DropTargetsHighlightedEvent | CommandError | undefined {
    const { cursorPosition } = params;
    const isHighlightWithEntities = 'entityIds' in params;

    const useCursorPosition = isHighlightWithEntities ? params.entityIds.length <= 1 : true;

    const accepted: DropTarget[] = [];
    const rejected: DropTarget[] = [];
    let error: Error | undefined;

    if (isHighlightWithEntities)
      params.entityIds.forEach((entityId) => {
        const entity = this.model.entityRepository.get(entityId)!;
        if (entity instanceof ScriptBase) return;

        const entityBoundingBox = {
          minX: entity.position.x + params.dxDy.x,
          minY: entity.position.y + params.dxDy.y,
          maxX: entity.position.x + entity.width + params.dxDy.x,
          maxY: entity.position.y + entity.height + params.dxDy.y,
        };

        const _intersectingEntities = useCursorPosition
          ? this.model.boundariesIndex.getIntersectingEntitiesData(cursorPosition)
          : this.model.boundariesIndex.getIntersectingEntitiesData(entityBoundingBox);
        const intersectingEntities = _intersectingEntities.filter((e) => e.entityId !== entityId);
        const precedentTarget = determinePrecedentTarget(this.model, entity, intersectingEntities);

        // if construct onto script
        if (
          params.entityIds.length <= 1 &&
          precedentTarget &&
          precedentTarget.entity instanceof ScriptBase &&
          entity instanceof ConstructBase
        ) {
          const script = precedentTarget.entity;
          const response = script.ingestEntity(
            entity,
            precedentTarget.entry.frameId!,
            precedentTarget.entry.laneId!,
            true,
          );

          const OFFSET_X = entity.width === entity.height ? 60 : 30;
          const OFFSET_Y = 60;
          const wantedBoundedBoxCacheEntry = this.model.boundariesIndex.getEntry(precedentTarget.entry.entityId);
          const wantedBoundedBox = {
            minX: wantedBoundedBoxCacheEntry.minX + OFFSET_X,
            minY: wantedBoundedBoxCacheEntry.minY + OFFSET_Y,
            maxX: wantedBoundedBoxCacheEntry.maxX - OFFSET_X,
            maxY: wantedBoundedBoxCacheEntry.maxY - OFFSET_Y,
          };

          if (response) {
            const validationResult = script.popValidationResult();

            const ID_LENGTH = 10;
            const id = `${precedentTarget.entry.entityId.substring(0, ID_LENGTH)}_script_${
              validationResult.targetFrameIndex
            }_${validationResult.targetLaneIndex}`;
            const actualBoundedBoxCacheEntry = this.model.boundariesIndex.getEntry(id);
            const actualBoundedBox = {
              minX: actualBoundedBoxCacheEntry.minX + OFFSET_X,
              minY: actualBoundedBoxCacheEntry.minY + OFFSET_Y,
              maxX: actualBoundedBoxCacheEntry.maxX - OFFSET_X,
              maxY: actualBoundedBoxCacheEntry.maxY - OFFSET_Y,
            };

            const wantedIngestPosition = script.getIngestPosition(
              precedentTarget.entry.frameId!,
              precedentTarget.entry.laneId!,
            );
            const actualIngestPosition = script.getIngestPosition(
              validationResult.targetFrameIndex!,
              validationResult.targetLaneIndex!,
            );

            accepted.push({
              entityId,
              wantedBoundedBox,
              wantedCentroid: {
                x: wantedIngestPosition.x + script.frames[precedentTarget.entry.frameId!].width / 2,
                y: wantedIngestPosition.y + script.lanes[precedentTarget.entry.laneId!].height / 2,
              },
              actualBoundedBox,
              actualCentroid: {
                x: actualIngestPosition.x + script.frames[validationResult.targetFrameIndex!].width / 2,
                y: actualIngestPosition.y + script.lanes[validationResult.targetLaneIndex!].height / 2,
              },
            });
          } else {
            const wantedIngestPosition = script.getIngestPosition(
              precedentTarget.entry.frameId!,
              precedentTarget.entry.laneId!,
            );
            rejected.push({
              entityId,
              wantedBoundedBox,
              wantedCentroid: {
                x: wantedIngestPosition.x + script.frames[precedentTarget.entry.frameId!].width / 2,
                y: wantedIngestPosition.y + script.lanes[precedentTarget.entry.laneId!].height / 2,
              },
            });
            error = script.getError();
          }
        }

        if (precedentTarget) this.model.graph.highlightDropTarget(precedentTarget.entity.id);
        else this.model.graph.clearDropTargetHighlights();
      });
    else {
      if (params.type) {
        const dummyEntity = EntityParserFactory.parse({
          id: sid(),
          type: params.type,
          position: cursorPosition,
          scopes: this.model.entityRepository.getScopes(),
          name: '',
          createdBy: 'machine',
          script: EntityParserFactory.parse({
            id: sid(),
            type: EntityType.ActionScript,
            position: cursorPosition,
            scopes: this.model.entityRepository.getScopes(),
            name: '',
            createdBy: 'machine',
          }),
        });

        const intersectingEntities = this.model.boundariesIndex.getIntersectingEntitiesData(params.cursorPosition);
        const precedentTarget = determinePrecedentTarget(this.model, dummyEntity, intersectingEntities);
        if (precedentTarget) this.model.graph.highlightDropTarget(precedentTarget.entity.id);
        else this.model.graph.clearDropTargetHighlights();
      }

      // TODO
      // if construct onto script
      // if attachment onto construct
      // if comment onto construct
      // if comment onto script
    }
    return new DropTargetsHighlightedEvent({ accepted, rejected, error });
  }
}

export class HighlightDropTargetsPolicy extends EventHandlerBase {
  handles() {
    return [EntitiesDraggedEvent, MouseMoveEvent];
  }

  execute(event: EventBase) {
    switch (event.type) {
      case EntitiesDraggedEvent.type:
        const entitiesDraggedEvent = event as EntitiesDraggedEvent;
        return this.model.messageBus.sendInternal(HighlightDropTargetsCommand, {
          entityIds: entitiesDraggedEvent.params.entityIds,
          dxDy: entitiesDraggedEvent.params.dxDy,
          cursorPosition: entitiesDraggedEvent.params.cursorPosition,
          // isModPressed: entitiesDraggedEvent.params.isModPressed,
        });

      case MouseMoveEvent.type:
        const mouseMoveEvent = event as MouseMoveEvent;
        return this.model.messageBus.sendInternal(HighlightDropTargetsCommand, {
          type: mouseMoveEvent.params.type,
          cursorPosition: mouseMoveEvent.params.cursorPosition,
        });
    }
  }
}

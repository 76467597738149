import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { EventHandlerBase } from '../framework/EventHandlerBase';
import { EntitiesIngestedEvent } from '../entities/IngestEntitiesCommand';
import { EntitiesAddedEvent } from '../entities/AddEntityCommand';
import { ScriptLaneAddedEvent } from '../scripts/AddScriptLaneCommand';
import { ScriptVisibilityToggledEvent } from '../scripts/ToggleScriptVisibilityCommand';
import { ScriptLaneDeletedEvent } from '../scripts/DeleteScriptLaneCommand';
import { ScriptFrameAddedEvent } from '../scripts/AddScriptFrameCommand';
import { ScriptFrameDeletedEvent } from '../scripts/DeleteScriptFrameCommand';
import { EntityBase } from '../../entities/EntityBase';
import { EntitiesDeletedEvent } from '../entities/DeleteEntitiesCommand';
import { CommandError } from '../../ErrorStore';
import { EntityType } from '../../entities/EntityType';

interface RecalculateDimensionsParams extends IParams {
  entityIds: string[];
}

export class DimensionsRecalculatedEvent extends EventBase {
  static eventType = 'DimensionsRecalculatedEvent';

  constructor(
    public readonly params: RecalculateDimensionsParams,
    public readonly source = RecalculateDimensionsCommand,
  ) {
    super();
  }
}

export class RecalculateDimensionsCommand extends CommandBase<RecalculateDimensionsParams> {
  execute(params: RecalculateDimensionsParams): DimensionsRecalculatedEvent | CommandError {
    params.entityIds.forEach((entityId) => {
      const entity = this.model.entityRepository.get(entityId);
      if (!entity) return;
      const initialPosition = { x: entity.position.x, y: entity.position.y };
      const parent = this.recalculateDimensionsToParent(entity);
      if (parent) this.recalculateDimensionsToChildren(parent);
      const dxDy = { dx: entity.position.x - initialPosition.x, dy: entity.position.y - initialPosition.y };
      entity.children.forEach((child) => {
        if (child.type === EntityType.Thread) {
          child.position.x += dxDy.dx;
          child.position.y += dxDy.dy;
          this.model.entityRepository.update(child);
        }
      });
    });
    return new DimensionsRecalculatedEvent(params);
  }

  private recalculateDimensionsToParent(entity: EntityBase): EntityBase | undefined {
    let currentEntity: EntityBase | undefined = entity;
    while (currentEntity) {
      currentEntity.recalculateDimensions();
      this.model.entityRepository.update(currentEntity);
      if (!currentEntity.parent) return currentEntity; // root reached
      currentEntity = currentEntity.parent;
    }
  }

  private recalculateDimensionsToChildren(entity: EntityBase) {
    entity.recalculateDimensions();
    this.model.entityRepository.update(entity);
    entity.children.forEach((child) => {
      this.recalculateDimensionsToChildren(child);
    });
  }
}

export class RecalculateDimensionsPolicy extends EventHandlerBase {
  handles() {
    return [
      EntitiesAddedEvent,
      EntitiesDeletedEvent,
      EntitiesIngestedEvent,
      ScriptVisibilityToggledEvent,
      ScriptLaneAddedEvent,
      ScriptLaneDeletedEvent,
      ScriptFrameAddedEvent,
      ScriptFrameDeletedEvent,
    ];
  }

  execute(event: EventBase) {
    switch (event.type) {
      case EntitiesIngestedEvent.type:
        const entitiesIngestedEvent = event as EntitiesIngestedEvent;
        return this.model.messageBus.sendInternal(RecalculateDimensionsCommand, {
          entityIds: Array.from(
            new Set([
              ...entitiesIngestedEvent.params.ingested.map((ingest) => ingest.entityId),
              ...entitiesIngestedEvent.params.ingested.map((ingest) => ingest.targetId),
              ...entitiesIngestedEvent.params.ejected.map((eject) => eject.entityId),
              ...entitiesIngestedEvent.params.affected.map((affected) => affected.entityId),
            ]),
          ),
        });
      case EntitiesDeletedEvent.type:
        const entitiesDeletedEvent = event as EntitiesDeletedEvent;
        return this.model.messageBus.sendInternal(RecalculateDimensionsCommand, {
          entityIds: Array.from(
            new Set([...entitiesDeletedEvent.params.affected!.map((affected) => affected.entityId)]),
          ),
        });
      case ScriptFrameAddedEvent.type:
      case ScriptFrameDeletedEvent.type:
      case ScriptLaneDeletedEvent.type:
      case ScriptLaneAddedEvent.type:
        const laneOrFrameEvent = event as
          | ScriptFrameAddedEvent
          | ScriptFrameDeletedEvent
          | ScriptLaneAddedEvent
          | ScriptLaneDeletedEvent;
        return this.model.messageBus.sendInternal(RecalculateDimensionsCommand, {
          entityIds: [laneOrFrameEvent.params.scriptId],
        });
    }
  }
}

import { Box, Button, Link, Stack, styled } from '@mui/material';
import { OpenInNew, VisibilityOutlined } from '@mui/icons-material';
import { Icon } from '@xspecs/design-system';
import { useSingleSourceStore } from '../../../../store/single-source-store/single-source-store';
import { useCallback } from 'react';

export type UploadLinkOverlayProps = {
  url: string;
  title?: string;
  favicon?: string;
  expand?: boolean;
  openInNewTab?: boolean;
  view?: boolean;
  setShowOverlay?: (value: boolean) => void;
};

export const UploadLinkOverlay = (props: UploadLinkOverlayProps) => {
  const { url, title, favicon, expand, openInNewTab, view, setShowOverlay } = props;
  const setUrlToPreview = useSingleSourceStore.use.setUrlToPreview();

  const handleExpand = useCallback(() => {
    setUrlToPreview({ url, title, favicon });
  }, [favicon, setUrlToPreview, title, url]);

  const handleView = useCallback(() => {
    setShowOverlay?.(false);
  }, [setShowOverlay]);

  return (
    <Overlay>
      <Stack direction="row" gap={2}>
        {openInNewTab ? (
          <Link sx={overlayElements} href={url} target="_blank">
            <OpenInNew fontSize="large" sx={{ color: 'white' }} />
          </Link>
        ) : null}
        {expand ? (
          <Button sx={overlayElements} onClick={handleExpand}>
            <Icon name="expand" width={35} height={36} />
          </Button>
        ) : null}
        {view ? (
          <Button sx={overlayElements} onClick={handleView}>
            <VisibilityOutlined fontSize="large" sx={{ color: 'white' }} />
          </Button>
        ) : null}
      </Stack>
    </Overlay>
  );
};

const Overlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.other.backgroundOverlay,
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2.5),
  opacity: 0,
  transition: 'opacity 0.3s ease',
  borderRadius: 4,
  '&:hover': {
    opacity: 1,
  },
}));

const overlayElements = {
  padding: '12px',
  borderRadius: '50%',
  width: 60,
  height: 60,
  minWidth: 60,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
  },
};

import { Errors } from '@xspecs/errors';
import { EntityRepository } from '../data/EntityRepository';
import { SingleSourceModel } from '../SingleSourceModel';
import { NotificationTypes, SingleSourceObserver } from '../observable/SingleSourceObserver';
import { Cache } from '../Cache';
import { EntityBase } from './EntityBase';
import { ScriptBase } from './scripts/ScriptBase';
import { Thread } from './threads/Thread';
import { Comment } from './threads/Comment';
import { AssetTypes } from './assets/AssetTypes';
import { NarrativeScript } from './scripts/NarrativeScript';
import { AttachmentType, EntityType } from './EntityType';
import { AssetBase } from './assets/AssetBase';
import { ActiveUser } from '../data/File.types';

export class EntityManager {
  constructor(
    public readonly model: SingleSourceModel,
    private readonly entityRepository: EntityRepository,
    private readonly observer: SingleSourceObserver,
    private readonly cache: Cache,
    public readonly activeUser: ActiveUser,
  ) {}

  public delete(id: string, save = true): void | Error {
    const entity = this.entityRepository.get(id);
    if (!entity) return new Error(Errors.EntityNotFound);
    this.entityRepository.delete(id);
    //this.model.viewModelResolver.deleteViewModel(id, entity.type);
    if (save) this.entityRepository.save();
  }

  public clearNewState(entityId: string) {
    const entity = this.entityRepository.get(entityId);
    if (!entity) return new Error(Errors.EntityNotFound);
    entity.isNew = false;
    this.observer.notify(NotificationTypes.OnTransientChange, {
      added: [],
      updated: [{ entity, modifiedProperties: ['isNew'] }],
      deleted: [],
    });
  }

  public add(entity: EntityBase, isSave = false): void | Error {
    if (this.entityRepository.get(entity.id)) return new Error(Errors.DuplicateEntityId);
    if (this.hasDuplicateAssetName(entity)) return new Error(Errors.DuplicateAssetName);
    this.entityRepository.add(entity);
    // FIXME remove this when we have a better model
    if (entity instanceof ScriptBase && !entity.getFrame(0)) {
      entity.hide();
      for (let i = 0; i < 4; i++) {
        const narrative = this.entityRepository.get<NarrativeScript>(entity.id)!;
        narrative.addFrame();
        this.entityRepository.update(narrative);
      }
    }
    if (isSave) {
      this.entityRepository.save();
    }
  }

  public addCommentToThread(threadId: string, comment: Comment) {
    this.clearNewState(threadId);
    const thread = this.entityRepository.get(threadId) as Thread;
    thread.unresolve();
    thread.addComment(comment);
    this.entityRepository.add(comment);
    this.entityRepository.update(thread);
    this.entityRepository.save();
  }

  public resolveThread(threadId: string) {
    const thread = this.entityRepository.get(threadId) as Thread;
    if (!thread) return new Error(Errors.EntityNotFound);
    thread.resolve();
    this.entityRepository.update(thread);
    this.entityRepository.save();
  }

  public updateComment(commentId: string, value: string): void | Error {
    const comment = this.entityRepository.get(commentId) as Comment;
    if (!comment) return new Error(Errors.EntityNotFound);
    comment.update(value);
    this.entityRepository.update(comment);
    this.entityRepository.save();
  }

  private hasDuplicateAssetName(entity: EntityBase, newName?: string): boolean {
    if (!AssetTypes.isAsset(entity.type)) return false;
    const nameToCheck = newName?.toLowerCase() || entity.name?.toLowerCase();
    return this.entityRepository.list().some((asset) => {
      return AssetTypes.isAsset(asset.type) && asset.id !== entity.id && asset.name?.toLowerCase() === nameToCheck;
    });
  }

  public setAttribute(id: string, attributeName: string, attributeValue: any) {
    const e = this.entityRepository.get(id);
    if (!e) return; // FIXME this is here from the refactor upcasting issues. It might be safe to remove
    e.attributes[attributeName] = attributeValue;
    this.entityRepository.update(e);
    this.entityRepository.save();
  }

  public listAssetsByType(type: AttachmentType): AssetBase[] {
    const assets = this.entityRepository
      .list()
      .filter((entity: EntityBase) => entity.type === (type as unknown as EntityType)) as AssetBase[];
    return assets.sort((a, b) => (a.name > b.name ? 1 : -1));
  }

  public applyRemoteChanges(serializedChanges: { added: any[]; updated: any[]; deleted: any[] }): boolean {
    if (!this.entityRepository.validateChanges(serializedChanges)) return false;
    this.entityRepository.applyRemoteChanges(serializedChanges);
    this.entityRepository.broadCastChanges();
    return true;
  }

  public dispose() {
    this.entityRepository.dispose();
    this.observer.dispose();
  }
}

import { ConstructBase } from './ConstructBase';
import { EntityType } from '../EntityType';

export class Projection extends ConstructBase {
  color = '#FA8A7B';

  get type(): EntityType {
    return EntityType.Projection;
  }

  static parse(data: unknown): Projection {
    return ConstructBase.parseBase.call(Projection, data);
  }
}

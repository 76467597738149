import { CommandBase, IParams } from '../framework/CommandBase';
import { EntitiesSelectionsUpdatedEvent } from './UpdateEntitiesSelectionsCommand';
import { CommandError } from '../../ErrorStore';

interface DeselectAllEntitiesCommandParams extends IParams {}

export class DeselectAllEntitiesCommand extends CommandBase<DeselectAllEntitiesCommandParams> {
  shouldSave(): boolean {
    return false;
  }

  execute(_params: DeselectAllEntitiesCommandParams): EntitiesSelectionsUpdatedEvent | CommandError {
    const selectedEntities = this.model.entityRepository.list().filter((entity) => entity.isSelected);
    Object.keys(this.model.entitySelectionTracker.localSelections).map((entityId) => {
      const entity = this.model.entityRepository.get(entityId);
      if (entity) entity.deselect();
    });
    return new EntitiesSelectionsUpdatedEvent({
      entitySelections: selectedEntities.map((entity) => ({ id: entity.id, selected: false })),
    });
  }
}

import { SingleSourceModel } from '../SingleSourceModel';
import { Filter } from '../entities/Filter';
import { Label } from '../entities/assets/Label';
import { ActiveUser, SerializedFile } from '../data/File.types';
import { ExplorerState } from '../read-models/explorer/ExplorerItem';
import { EntityDetailsState } from '../read-models/entity-details/EntityDetails';
import { CommandError } from '../ErrorStore';
import { AnnotatorState } from '../read-models/annotator/Annotator';
import { Viewport } from '@xyflow/react';
import { AssetsState } from '../read-models/assets/Assets';
import { PaletteLayout } from '../palette/Palette';
import { AssetExplorerState } from '../read-models/asset-explorer/AssetExplorerItem';
import { AttachmentsByAssetIdState } from '../read-models/attachments/Attachments.types';
import { FiltersMenuState } from '../read-models/filters-menu/FiltersMenu';
import { ToolbarState } from '../read-models/toolbar/ToolbarState';

export enum Mode {
  SELECTION = 'SELECTION',
  INSERT_CONSTRUCT = 'INSERT_CONSTRUCT',
  INSERT_SCRIPT = 'INSERT_SCRIPT',
  INSERT_MOMENT = 'INSERT_MOMENT',
  INSERT_ACTION = 'INSERT_ACTION',
  INSERT_INTERFACE = 'INSERT_INTERFACE',
  INSERT_ACTOR = 'INSERT_ACTOR',
  INSERT_SPEC = 'INSERT_SPEC',
  INSERT_THREAD = 'INSERT_THREAD',
  INSERT_CAPABILITY = 'INSERT_CAPABILITY',
}

type UrlToPreview = { url: string; title?: string; favicon?: string };

export type SingleSourceStoreState = {
  activeUsersByFile: Record<string, ActiveUser[]>;
  appliedSavedFilter: Filter | undefined;
  constructToInsert: string | null;
  constructToInsertUsingDrag: string | null;
  constructToPanTo: string | undefined;
  entityDetails: EntityDetailsState | null;
  filesById: Record<string, SerializedFile>;
  graph: { nodes: any[]; edges: any[] };
  isLoaded: boolean;
  labels: Label[];
  mode: Mode;
  savedFilters: Filter[];
  searchQuery: string;
  annotator: AnnotatorState | null;
  showManageLabelsModal: boolean;
  showResolvedThreads: boolean;
  showRestorePreviousVersionModal: boolean;
  showUploadFileModal?: string;
  singleSourceModel: SingleSourceModel | null;
  states: Record<string, unknown>;
  unsavedFilter: Filter | undefined;
  errors: CommandError[];
  showUploadLinkModal: boolean;
  urlToPreview: UrlToPreview | null;
  viewports: Record<string, Viewport> | undefined;
  showProposeSchemaChangesModal: boolean;
  instantMockUrl: string;
  selectedGraphs: Record<string, string>;
  variantProposal: string;
  creatingProposal: boolean;
  graphDetails: GqlGraphDetails | null;
  loadingVariantsProposal: boolean;
  assets: AssetsState;
  assetExplorer: AssetExplorerState;
  schemaOptions: string[];
  showKeyboardShortcutsModal: boolean;
  markers: Record<string, boolean>;
  runningQuery: boolean;
  seedData: object;
  showWorkspaceResetModal: boolean;
  workspace: any;
  organization: any;
  showUploadAppModal: boolean;
  palette: PaletteLayout | undefined;
  showDeleteAssetModal: boolean;
  assetToDelete: string | undefined;
  collapseSidebar: boolean;
  explorer: ExplorerState;
  attachmentsByAssetId: AttachmentsByAssetIdState;
  filtersMenu: FiltersMenuState;
  toolbar: ToolbarState;
  showCreateSpaceModal: boolean;
  spaceToRename: string | undefined;
  spaceToDelete: string | undefined;
  recentSpacesByOrg: Record<string, { id: string; name: string }[]>;
};

export type SingleSourceStoreActions = {
  setActiveUsersByFile: (fileId: string, activeUsers: ActiveUser[]) => void;
  setAppliedSavedFilter: (appliedSavedFilters: Filter) => void;
  setConstructToInsert: (construct: string | null) => void;
  setConstructToInsertUsingDrag: (constructToInsertUsingDrag: string | null) => void;
  setConstructToPanTo: (id?: string) => void;
  setEntityDetails: (entity: EntityDetailsState) => void;
  setGraph: (graph: { nodes: any[]; edges: any[] }) => void;
  setInsertActionMode: () => void;
  setInsertActorMode: () => void;
  setInsertCapabilityMode: () => void;
  setInsertConstructMode: () => void;
  setInsertInterfaceMode: () => void;
  setInsertMomentMode: () => void;
  setInsertNarrativeMode: () => void;
  setInsertSpecMode: () => void;
  setInsertThreadMode: () => void;
  setIsLoaded: (isLoaded: boolean) => void;
  setLabels: (labels: Label[]) => void;
  setAnnotator: (annotator: AnnotatorState | null) => void;
  setSavedFilters: (filter: Filter[]) => void;
  setSearchQuery: (searchQuery: string) => void;
  setSelectionMode: () => void;
  setShowManageLabelsModal: (showManageLabelsModal: boolean) => void;
  setShowResolvedThreads: (showResolvedThreads: boolean) => void;
  setShowRestorePreviousVersionModal: (showRestorePreviousVersionModal: boolean) => void;
  setShowUploadFileModal: (showUploadFileModal?: string) => void;
  setSingleSourceModel: (model: SingleSourceModel) => void;
  setStateField: (key: string, value: unknown) => void;
  setUnsavedFilter: (filter: Filter) => void;
  setErrors: (errors: CommandError[]) => void;
  setShowUploadLinkModal: (showUploadLinkModal: boolean) => void;
  setUrlToPreview: (urlToPreview: UrlToPreview | null) => void;
  setViewports: (key: string, viewport: Viewport) => void;
  setShowProposeSchemaChangesModal: (showProposeSchemaChangesModal: boolean) => void;
  setInstantMockUrl: (instantMockUrl: string) => void;
  setSelectedGraphs: (params: { selectedGraph: string; queryId: string }) => void;
  setVariantProposal: (variantProposal: string | undefined) => void;
  setGraphDetails: (graphDetails: GqlGraphDetails | null) => void;
  setLoadingVariantsProposal: (loadingVariantsProposal: boolean) => void;
  setAssets: (assets: AssetsState) => void;
  setAssetExplorer: (assetsExplorer: AssetExplorerState) => void;
  setSchemaOptions: (schemaOptions: string[]) => void;
  setCreatingProposal: (creatingProposal: boolean) => void;
  setShowKeyboardShortcutModal: (showKeyboardShortcutsModal: boolean) => void;
  setMarkers: (params: { id: string; marker: boolean }) => void;
  setRunningQuery: (runningQuery: boolean) => void;
  setSeedData: (seedData: object) => void;
  setShowWorkspaceResetModal: (showWorkspaceResetModal: boolean) => void;
  setWorkspace: (workspace: any) => void;
  setOrganization: (organization: any) => void;
  setShowUploadAppModal: (showUploadAppModal: boolean) => void;
  setAppState: (appName: string, state: object) => void;
  setPalette: (palette: PaletteLayout) => void;
  setShowDeleteAssetModal: (showDeleteAssetModal: boolean) => void;
  setAssetToDelete: (id?: string) => void;
  setCollapseSidebar: (collapseSidebar: boolean) => void;
  setExplorer: (explorer: ExplorerState) => void;
  setAttachmentsByAssetId: (attachments: AttachmentsByAssetIdState) => void;
  setFiltersMenu: (filtersMenu: FiltersMenuState) => void;
  setToolbar: (toolbar: ToolbarState) => void;
  setShowCreateSpaceModal: (showCreateSpaceModal: boolean) => void;
  setSpaceToRename: (spaceToRename: string | undefined) => void;
  setSpaceToDelete: (spaceToRename: string | undefined) => void;
  setRecentSpacesByOrg: (orgId: string, space: { id: string; name: string }) => void;
};

export type SingleSourceStore = SingleSourceStoreState & SingleSourceStoreActions;

export type InteractorResponse = { action: string; params: any } | undefined;

type Schema = {
  document: string;
  __typename: string;
};

type SchemaPublication = {
  publishedAt: string;
  schema: Schema;
  __typename: 'SchemaPublication';
};

type GraphVariant = {
  key: string;
  displayName: string;
  latestPublication?: SchemaPublication;
  __typename: 'GraphVariant';
};

type User = {
  name: string;
  __typename: 'User';
};

type Proposal = {
  key: string;
  displayName: string;
  status: string;
  id: string;
  createdAt: string;
  createdBy: User;
  latestPublication?: SchemaPublication;
  __typename: 'Proposal';
};

export type GqlGraphDetails = {
  name: string;
  variants: GraphVariant[];
  proposals: Proposal[];
  __typename: 'Graph';
};

export type GqlGraph = {
  id: string;
  name: string;
  proposals?: {
    totalCount: number;
    __typename: string;
  };
  variants?: {
    __typename: string;
  }[];
};

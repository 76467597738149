import { EntityBase } from '../EntityBase';
import { z } from 'zod';
import { EntityType } from '../EntityType';
import { ConstructBase } from '../constructs/ConstructBase';
import { Z_INDEXES } from '../../ZIndexes';
import { EDGE_TOOLBAR_ITEMS } from './EdgeToolbarItems';
import { EdgeToolbarElement, EdgeToolbarElementIconButton, EdgeToolbarElementStepper } from './EdgeToolbar.types';
import {
  defaultColor,
  defaultLabelColor,
  defaultLabelFontSize,
  defaultLineStyle,
  defaultLineType,
  defaultLineWeight,
  defaultName,
  markerEndDefault,
  markerStartDefault,
} from './Edge.constants';

export enum HandleLocation {
  Top = 'Top',
  Right = 'Right',
  Bottom = 'Bottom',
  Left = 'Left',
  Float = 'Float',
  Relative = 'Relative',
}
export const strToHandleLocation = (enumValue: string): HandleLocation | Error => {
  const value = enumValue.charAt(0).toUpperCase() + enumValue.slice(1);
  const result = HandleLocation[value as keyof typeof HandleLocation];
  if (!result) return new Error(`Invalid HandleLocation: ${enumValue}`);
  return result;
};

type EdgeData = z.infer<typeof Edge.schema>;

const markerSchema = z.object({
  type: z.enum(['none', 'arrow', 'arrowclosed']).default('arrow'),
});
type Marker = z.infer<typeof markerSchema>;

export class Edge extends EntityBase {
  constructor(data: EdgeData, omitReferencesParsing: boolean = false) {
    let parsedData: EdgeData;
    if (omitReferencesParsing) {
      parsedData = Edge.schema.omit({ source: true, target: true }).parse(data);
    } else {
      parsedData = Edge.schema.parse(data);
    }
    super(
      parsedData.id,
      parsedData.name,
      parsedData.parent,
      parsedData.position,
      parsedData.scopes,
      parsedData.attributes,
      parsedData.width,
      parsedData.height,
      parsedData.isVisible,
      parsedData.zIndex,
    );
    this.source = data.source;
    this.target = data.target;
    this.sourceHandleLocation = parsedData.sourceHandleLocation ?? HandleLocation.Right;
    this.targetHandleLocation = parsedData.targetHandleLocation ?? HandleLocation.Left;
    this.markerStart = parsedData.markerStart;
    this.markerEnd = parsedData.markerEnd;
    this.color = parsedData.color;
    this.lineStyle = parsedData.lineStyle;
    this.lineWeight = parsedData.lineWeight;
    this.labelColor = parsedData.labelColor;
    this.labelFontSize = parsedData.labelFontSize;
    this.lineType = parsedData.lineType;
  }

  //static version = '1.0.0';
  //static version = '1.0.1'; // Renames Tags to Labels;
  // static version = '1.0.2'; // Removes Edges with an Action Source;
  // static version = '1.0.3'; // Updates z index of edges
  static version = '1.0.4'; // Updates edge label

  static schema = EntityBase.abstractBaseSchema.extend({
    type: z.nativeEnum(EntityType).readonly().default(EntityType.Edge),
    sourceHandleLocation: z.nativeEnum(HandleLocation).optional().default(HandleLocation.Left),
    targetHandleLocation: z.nativeEnum(HandleLocation).optional().default(HandleLocation.Right),
    position: z
      .object({
        x: z.number(),
        y: z.number(),
      })
      .optional(),
    name: z.string().optional().default(JSON.stringify(defaultName)),
    source: z.lazy(() => ConstructBase.baseSchema),
    target: z.lazy(() => ConstructBase.baseSchema),
    scopes: z.array(z.string()).optional().default([]),
    zIndex: z.number().default(Z_INDEXES.Edge),
    markerStart: markerSchema.optional().default(markerStartDefault),
    markerEnd: markerSchema.optional().default(markerEndDefault),
    color: z.string().optional().default(defaultColor),
    lineStyle: z.enum(['solid', 'dashed', 'dotted']).optional().default(defaultLineStyle),
    lineWeight: z.string().optional().default(defaultLineWeight),
    labelColor: z.string().optional().default(defaultLabelColor),
    labelFontSize: z.number().optional().default(defaultLabelFontSize),
    lineType: z.enum(['straight', 'curved', 'orthogonal']).optional().default(defaultLineType),
  });

  source: EntityBase;
  sourceHandleLocation: HandleLocation = HandleLocation.Left;
  target: EntityBase;
  targetHandleLocation: HandleLocation = HandleLocation.Right;
  markerStart: Marker = markerStartDefault;
  markerEnd: Marker = markerEndDefault;
  color: string = defaultColor;
  lineStyle: string = defaultLineStyle;
  lineWeight: string = defaultLineWeight;
  labelColor: string = defaultLabelColor;
  labelFontSize: number = defaultLabelFontSize;
  lineType: string = defaultLineType;

  static references = ['source', 'target'];

  get type(): EntityType {
    return EntityType.Edge;
  }

  get toolbarLayout(): EdgeToolbarElement[] {
    const defaultLayout = structuredClone(EDGE_TOOLBAR_ITEMS);

    const markerStart = defaultLayout.find(
      (item) => item.type === 'icon-button' && item.name === 'markerStart',
    ) as EdgeToolbarElementIconButton;
    if (markerStart) {
      markerStart.icon = this.markerStart.type.startsWith('arrow') ? 'arrow-left' : 'centered-line';
      markerStart.menu?.items.forEach((item) => {
        if (!('color' in item)) {
          item.selected = item.value === this.markerStart.type;
        }
      });
    }

    const markerEnd = defaultLayout.find(
      (item) => item.type === 'icon-button' && item.name === 'markerEnd',
    ) as EdgeToolbarElementIconButton;
    if (markerEnd) {
      markerEnd.icon = this.markerEnd.type.startsWith('arrow') ? 'arrow-right' : 'centered-line';
      markerEnd.menu?.items.forEach((item) => {
        if (!('color' in item)) {
          item.selected = item.value === this.markerEnd.type;
        }
      });
    }

    const edgeColor = defaultLayout.find(
      (item) => item.type === 'icon-button' && item.name === 'edgeColor',
    ) as EdgeToolbarElementIconButton;
    if (edgeColor) {
      edgeColor.menu?.items.forEach((item) => {
        if ('color' in item) {
          item.selected = item.color === this.color;
          if (item.selected) {
            edgeColor.iconColor = item.color;
          }
        }
      });
    }

    const textColor = defaultLayout.find(
      (item) => item.type === 'icon-button' && item.name === 'labelColor',
    ) as EdgeToolbarElementIconButton;
    if (textColor) {
      textColor.menu?.items.forEach((item) => {
        if ('color' in item) {
          item.selected = item.color === this.labelColor;
          if (item.selected) {
            textColor.iconColor = item.color;
          }
        }
      });
    }

    const lineStyle = defaultLayout.find(
      (item) => item.type === 'icon-button' && item.name === 'lineStyle',
    ) as EdgeToolbarElementIconButton;
    if (lineStyle) {
      lineStyle.menu?.items.forEach((item) => {
        if (!('color' in item)) item.selected = item.value === this.lineStyle;
      });
    }

    const lineWeight = defaultLayout.find(
      (item) => item.type === 'icon-button' && item.name === 'lineWeight',
    ) as EdgeToolbarElementIconButton;
    if (lineWeight) {
      lineWeight.menu?.items.forEach((item) => {
        if (!('color' in item)) item.selected = item.value === this.lineWeight;
      });
    }

    const labelFontSize = defaultLayout.find(
      (item) => item.type === 'stepper' && item.name === 'labelFontSize',
    ) as EdgeToolbarElementStepper;
    if (labelFontSize) {
      labelFontSize.value = this.labelFontSize;
      labelFontSize.menu?.items.forEach((item) => {
        if (!('color' in item)) {
          const value = item.value;
          item.selected = value === this.labelFontSize;
        }
      });
      labelFontSize.onDecrementClick.params.labelFontSize = Math.max(10, this.labelFontSize - 2);
      labelFontSize.onIncrementClick.params.labelFontSize = Math.min(64, this.labelFontSize + 2);
    }

    const lineType = defaultLayout.find(
      (item) => item.type === 'icon-button' && item.name === 'lineType',
    ) as EdgeToolbarElementIconButton;
    lineType.menu?.items.forEach((item) => {
      if (!('color' in item)) {
        item.selected = item.value === this.lineType;
        if (item.selected) {
          lineType.icon = item.icon;
        }
      }
    });

    const more = defaultLayout.find(
      (item) => item.type === 'icon-button' && item.name === 'more',
    ) as EdgeToolbarElementIconButton;

    more.menu?.items.forEach((item) => {
      if (!('color' in item)) {
        item.disabled = !this.isDeletable;
      }
    });

    return defaultLayout;
  }

  static parse(data: EdgeData, omitReferences: boolean = false): Edge {
    let parsedData: Edge;
    if (omitReferences) {
      parsedData = Edge.schema.omit({ source: true, target: true }).parse(data);
    } else {
      parsedData = Edge.schema.parse(data);
    }
    return new Edge(parsedData, omitReferences);
  }

  isValid(): boolean {
    return Edge.schema.safeParse(this).success;
  }

  get isDeletable(): boolean {
    return !(
      (this.source?.type === EntityType.Narrative && this.target?.type === EntityType.NarrativeScript) ||
      (this.source?.type === EntityType.NarrativeScript && this.target?.type === EntityType.Narrative) ||
      (this.source?.type === EntityType.Action && this.target?.type === EntityType.ActionScript) ||
      (this.source?.type === EntityType.ActionScript && this.target?.type === EntityType.Action) ||
      (this.source?.type === EntityType.Attachment && this.target?.type === EntityType.Preview) ||
      (this.source?.type === EntityType.Preview && this.target?.type === EntityType.Attachment)
    );
  }

  swapMarkers(): void {
    const marker = this.markerStart;
    this.markerStart = this.markerEnd;
    this.markerEnd = marker;
  }

  updateMarkerStart(markerStart: string = 'none'): void {
    this.markerStart = markerSchema.parse({ type: markerStart });
  }

  updateMarkerEnd(markerEnd: string = 'none'): void {
    this.markerEnd = markerSchema.parse({ type: markerEnd });
  }

  updateColor(color: string = defaultColor) {
    this.color = color;
  }

  updateLineStyle(lineStyle: string = defaultLineStyle) {
    this.lineStyle = lineStyle;
  }

  updateLineWeight(lineWeight: string = defaultLineWeight) {
    this.lineWeight = lineWeight;
  }

  updateLabelColor(labelColor: string = defaultLabelColor) {
    this.labelColor = labelColor;
  }

  updateFontSize(labelFontSize: number = defaultLabelFontSize) {
    this.labelFontSize = labelFontSize;
  }

  updateLineType(lineType: string = defaultLineType) {
    this.lineType = lineType;
  }

  serialize(reference: boolean = false): unknown {
    if (reference) return super.serialize(reference);
    return {
      ...(super.serialize() as any),
      source: this.source?.serialize(true),
      target: this.target?.serialize(true),
      markerStart: {
        ...this.markerStart,
      },
      markerEnd: {
        ...this.markerEnd,
      },
      color: this.color,
      lineStyle: this.lineStyle,
      lineWeight: this.lineWeight,
      labelColor: this.labelColor,
      labelFontSize: this.labelFontSize,
      lineType: this.lineType,
    };
  }
}

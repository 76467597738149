import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';
import { Thread } from '../../entities/threads/Thread';

interface UnresolveThreadCommandParams extends IParams {
  threadId: string;
}

interface ThreadUnresolvedEventParams extends IParams {
  threadId: string;
}

export class ThreadUnresolvedEvent extends EventBase {
  static eventType = 'ThreadUnresolvedEvent';

  constructor(public readonly params: ThreadUnresolvedEventParams, public readonly source = UnresolveThreadCommand) {
    super();
  }
}

export class UnresolveThreadCommand extends CommandBase<UnresolveThreadCommandParams> {
  execute(params: UnresolveThreadCommandParams): ThreadUnresolvedEvent | CommandError {
    this.unresolveThread(params.threadId);
    return new ThreadUnresolvedEvent({ threadId: params.threadId });
  }

  public unresolveThread(threadId: string) {
    const thread = this.model.entityRepository.get(threadId) as Thread;
    thread.unresolve();
    this.model.entityRepository.update(thread);
    this.model.entityRepository.save();
  }
}

import { Box, SxProps, Theme } from '@mui/material';
import { SpaceItem } from './space-item/space-item';
import { AddSpace } from './add-space/add-space';
import { Workspace } from '../../../gql/graphql';

export interface SpaceListProps {
  spaces: Workspace[];
}

export const SpaceList = (props: SpaceListProps) => {
  const { spaces } = props;

  return (
    <Box sx={spacesListSx}>
      <AddSpace />
      {spaces.map((space) => (
        <SpaceItem key={space.id} {...space} />
      ))}
    </Box>
  );
};

const spacesListSx: SxProps<Theme> = {
  display: 'grid',
  gap: 3,
  gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
};

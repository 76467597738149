import { loader, Monaco } from '@monaco-editor/react';
import { logger } from '@xspecs/logger';
import { UpdateQueryTextCommand } from '@xspecs/single-source-model';
import { editor as monacoEditor, IDisposable, Uri } from 'monaco-editor';
import { initializeMode } from 'monaco-graphql/esm/initializeMode';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSingleSourceStore } from '../../../store/single-source-store/single-source-store';
import { useApplication } from '../../../wrappers/application-context/application-context';
import { PlaceholderContentWidget } from '../../spec/placeholder-content-widget';
import { useMount } from './use-mount';
import { useUpdate } from './use-update';

const getOrCreateModel = (uri: string, value: string) => {
  return monacoEditor.getModel(Uri.file(uri)) ?? monacoEditor.createModel(value, uri.split('.').pop(), Uri.file(uri));
};

const monacoGraphQLAPI = initializeMode({
  schemas: [],
});

const uri = 'operation.graphql';
const createEditor = (ref: HTMLDivElement, options: monacoEditor.IStandaloneEditorConstructionOptions) =>
  monacoEditor.create(ref, options);

export type ActualQueryProps = {
  schema?: string;
  queryId: string;
  value: string;
  getViewport?: () => { minX: number; minY: number; maxX: number; maxY: number };
};

export const ActualQuery = (props: ActualQueryProps) => {
  const { schema, queryId, value, getViewport } = props;

  const setMarkers = useSingleSourceStore.use.setMarkers();

  const { application } = useApplication();

  const [isEditorReady, setIsEditorReady] = useState(false);
  const [isMonacoMounting, setIsMonacoMounting] = useState(true);

  const opsRef = useRef<HTMLDivElement | null>(null);
  const editorRef = useRef<monacoEditor.IStandaloneCodeEditor | null>(null);
  const subscriptionRef = useRef<IDisposable>();
  const preventTriggerChangeEvent = useRef<boolean>(false);
  const valueRef = useRef(value);
  const monacoRef = useRef<Monaco | null>(null);

  useMount(() => {
    const cancelable = loader.init();

    cancelable
      .then((monaco) => (monacoRef.current = monaco) && setIsMonacoMounting(false))
      .catch((error) => error?.type !== 'cancelation' && logger.error('Monaco initialization: error:', error));

    return () => (editorRef.current ? disposeEditor() : cancelable.cancel());
  });

  useEffect(() => {
    if (editorRef.current) return;

    const queryModel = getOrCreateModel(uri, valueRef.current);
    if (!opsRef.current) return;
    const editor = createEditor(opsRef.current, {
      model: queryModel,
      language: 'graphql',
      theme: 'default',
      wordWrap: 'on',
      'semanticHighlighting.enabled': true,
      lineNumbers: 'off',
      minimap: { enabled: false },
      scrollBeyondLastLine: false,
      scrollBeyondLastColumn: 0,
      fontFamily: "'JetBrains Mono'",
      scrollbar: {
        horizontal: 'hidden',
        vertical: 'hidden',
      },
      renderLineHighlight: 'none',
      overviewRulerBorder: false,
      glyphMargin: false,
      defaultColorDecorators: false,
      overviewRulerLanes: 0,
      folding: false,
      guides: {
        indentation: false,
      },
      renderWhitespace: 'none',
      quickSuggestions: true,
    });

    monacoEditor.onDidChangeMarkers(() => {
      const markers = monacoEditor.getModelMarkers({ resource: queryModel.uri });
      setMarkers({ id: queryId, marker: markers.length > 0 });
    });

    new PlaceholderContentWidget('Type in your query...', editor);
    editorRef.current = editor;
    setIsEditorReady(true);

    return () => {
      if (editorRef.current) {
        editorRef.current.dispose();
        editorRef.current = null;
      }
    };
  }, [application?.model.messageBus, queryId, schema, setMarkers]);

  useEffect(() => {
    if (!schema) return;
    monacoGraphQLAPI.setSchemaConfig([{ uri: uri, documentString: schema }]);
    return () => {
      monacoGraphQLAPI.setSchemaConfig([]);
    };
  }, [schema]);

  const onChange = useCallback(
    (value, event) => {
      const viewport = getViewport?.();
      application?.model.messageBus.send(UpdateQueryTextCommand, {
        id: queryId,
        queryText: value,
        viewport,
      });
    },
    [application?.model.messageBus, getViewport, queryId],
  );

  useUpdate(
    () => {
      if (!editorRef.current || value === undefined) return;
      if (!editorRef.current.getModel()) {
        logger.warn("No model found in the editor. Can't update value.");
        return;
      }
      if (!monacoRef.current) {
        logger.error("Monaco editor isn't ready. Can't update value.");
        return;
      }
      if (editorRef.current.getOption(monacoRef.current.editor.EditorOption.readOnly)) {
        editorRef.current.setValue(value);
      } else if (value !== editorRef.current.getValue()) {
        preventTriggerChangeEvent.current = true;
        editorRef.current.executeEdits('', [
          {
            range: editorRef.current.getModel()!.getFullModelRange(),
            text: value,
            forceMoveMarkers: true,
          },
        ]);

        editorRef.current.pushUndoStop();
        preventTriggerChangeEvent.current = false;
      }
    },
    [value],
    isEditorReady,
  );

  valueRef.current = value;
  useEffect(() => {
    if (onChange) {
      subscriptionRef.current?.dispose();
      subscriptionRef.current = editorRef.current?.onDidChangeModelContent((event) => {
        if (!preventTriggerChangeEvent.current) {
          onChange(editorRef.current!.getValue(), event);
        }
      });
    }
  }, [onChange]);

  function disposeEditor() {
    subscriptionRef.current?.dispose();
    editorRef.current!.getModel()?.dispose();
    editorRef.current!.dispose();
  }

  return (
    <div id="wrapper">
      <div id="left-pane" className="pane">
        <div ref={opsRef} className="editor" />
      </div>
    </div>
  );
};

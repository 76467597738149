export const CurvedEdge = () => {
  return (
    <>
      <path
        d="M4 14C4 12.5 5 10 8.5 10C13 10 12.5 14.5 17 14C19.9817 13.6687 20 10 20 10"
        stroke="black"
        strokeOpacity="0.54"
      />
    </>
  );
};

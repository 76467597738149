import { ConstructBase } from './ConstructBase';
import { EntityType } from '../../entities/EntityType';

export class ExternalSystem extends ConstructBase {
  color = '#FFD0DF';

  get type(): EntityType {
    return EntityType.ExternalSystem;
  }

  static parse(data: unknown): ExternalSystem {
    return ConstructBase.parseBase.call(ExternalSystem, data);
  }
}

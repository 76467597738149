import { ListItemButton, styled, Typography } from '@mui/material';
import { ConstructShape, EntityType, DynamicConstruct } from '@xspecs/single-source-model';
import { DragEventHandler, FC, useCallback, useRef } from 'react';
import { useSingleSourceStore } from '../../../../store/single-source-store/single-source-store';
import { Icon } from '@xspecs/design-system';

export type PaletteItemProps = DynamicConstruct;

export const PaletteConstructListItem: FC<PaletteItemProps> = ({ type, label, backgroundColor, shape, textColor }) => {
  const previewRef = useRef(null);

  const setConstructToInsert = useSingleSourceStore.use.setConstructToInsert();
  const setConstructToInsertUsingDrag = useSingleSourceStore.use.setConstructToInsertUsingDrag();

  const onDragStart = useCallback<DragEventHandler>(
    (event) => {
      event.dataTransfer.setData('application/reactflow', type);
      event.dataTransfer.effectAllowed = 'move';
      if (previewRef.current) event.dataTransfer.setDragImage(previewRef.current, 0, 0);
      setConstructToInsertUsingDrag(type);
    },
    [setConstructToInsertUsingDrag, type],
  );

  const onDragEnd = useCallback(() => {
    setConstructToInsertUsingDrag(null);
  }, [setConstructToInsertUsingDrag]);

  const onClick = useCallback(() => {
    setConstructToInsert(type);
  }, [setConstructToInsert, type]);

  return (
    <ListItemButton
      disableRipple
      data-testid={`${type}-palette-item`}
      disableTouchRipple
      sx={{ gap: 1 }}
      draggable
      onDragStart={onDragStart}
      onClick={onClick}
      onDragEnd={onDragEnd}
    >
      {type === EntityType.ActionScript ? (
        <Icon ref={previewRef} name="subscript" height={20} width={20} />
      ) : (
        <Preview ref={previewRef} backgroundColor={backgroundColor} textColor={textColor} shape={shape} />
      )}
      <Typography>{label}</Typography>
    </ListItemButton>
  );
};

export const AspectRatio: Record<ConstructShape, string> = {
  square: '1 / 1',
  rectangle: '1.75 / 1',
};

const Preview = styled('div', {
  shouldForwardProp: (prop) => prop !== 'backgroundColor' && prop !== 'textColor' && prop !== 'shape',
})<{
  backgroundColor: string;
  textColor: string;
  shape: 'rectangle' | 'square';
}>(({ backgroundColor, textColor, shape }) => {
  return {
    color: textColor,
    backgroundColor,
    aspectRatio: AspectRatio[shape],
    height: 20,
    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.25)',
  };
});

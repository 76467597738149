export const markerStartDefault = {
  type: 'none' as const,
} as const;

export const markerEndDefault = {
  type: 'arrow' as const,
} as const;

export const defaultColor = '#D9D9D9' as const;

export const defaultLineStyle = 'solid' as const;

export const defaultLineWeight = '5px' as const;

export const defaultLabelColor = '#212121' as const;

export const defaultName = [
  {
    type: 'p',
    children: [{ text: '' }],
  },
];

export const defaultLabelFontSize = 14 as const;

export const defaultLineType = 'curved';

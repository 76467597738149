import { IconComponentProps } from '../icon';
import { FC } from 'react';

export const RecentFolder: FC<IconComponentProps> = ({ color }) => {
  return (
    <path
      d="M7.00025 20.0001H4.00025C3.46981 20.0001 2.96111 19.7894 2.58603 19.4143C2.21097 19.0393 1.99925 18.5305 1.99925 18.00015V5.0001C1.99925 4.46967 2.21097 3.96098 2.58603 3.58592C2.96111 3.21085 3.46981 3.00015 4.00025 3.00015H7.90025C8.23473 2.99682 8.5647 3.07749 8.85995 3.23472C9.1552 3.39195 9.40629 3.62071 9.59025 3.90009L10.40025 5.10009C10.58235 5.37663 10.83027 5.60361 11.12175 5.76134C11.41322 5.91907 11.73913 6.00004 12.07025 6.00009H20.00025C20.53065 6.00009 20.83946 6.21081 21.41446 6.58591C21.98946 6.96101 22.00025 7.46967 22.00025 7.99992M16.00025 14.00000V16.00000L17.00025 17.00000M22.00025 16.00000C22.00025 19.3137 19.314 22.00000 16.00025 22.00000C12.686 22.00000 10.00025 19.3137 10.00025 16.00000C10.00025 12.6863 12.686 10.00000 16.00025 10.00000C19.314 10.00000 22.00025 12.6863 22.00025 16.00000Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
};

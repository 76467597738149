import { Box, Button, SxProps, Theme, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { Icon } from '@xspecs/design-system';
import AddIcon from '@mui/icons-material/Add';
import { useCallback } from 'react';
import { useActiveOrganization } from '../../../hooks/use-active-organization';
import { useSingleSourceStore } from '../../../store/single-source-store/single-source-store';

export const NoSpaceResults = () => {
  const { formatMessage: f } = useIntl();
  const { organization } = useActiveOrganization();
  const setShowCreateSpaceModal = useSingleSourceStore.use.setShowCreateSpaceModal();

  const openCreateSpaceModal = useCallback(() => {
    if (organization === undefined) return;
    setShowCreateSpaceModal(true);
  }, [organization, setShowCreateSpaceModal]);

  return (
    <Box py={5.5} display="flex" flexDirection="column" alignItems="center">
      <Box p={2} sx={ghostWrapperSx}>
        <Icon name="ghost" />
      </Box>
      <Typography variant="h6" color="text.primary" mb={1}>
        {f({ id: 'no-spaces-yet' })}
      </Typography>
      <Typography variant="subtitle1" color="text.secondary" mb={3} maxWidth={350} textAlign="center">
        {f({ id: 'space-list-description' })}
      </Typography>
      <Button variant="contained" startIcon={<AddIcon />} sx={createSpaceButtonSx} onClick={openCreateSpaceModal}>
        {f({ id: 'create-new-space' })}
      </Button>
    </Box>
  );
};

const ghostWrapperSx: SxProps<Theme> = { backgroundColor: 'base.accent', borderRadius: '50%', mb: 2 };

const createSpaceButtonSx: SxProps<Theme> = { px: 2, py: 1.5, textTransform: 'none', background: 'primary.main' };

import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { TransientStore } from '../../data/Transient';
import { CommandError } from '../../ErrorStore';
import { NotificationTypes } from '../../observable/SingleSourceObserver';
import { EntityBase } from '../../entities/EntityBase';

interface CloseEntityDetailsCommandParams extends IParams {
  entityId: string;
}

interface EntityDetailsCloseEventParams extends IParams {
  entityId: string;
}

export class EntityDetailsCloseEvent extends EventBase {
  static eventType = 'EntityDetailsCloseEvent';

  constructor(
    public readonly params: EntityDetailsCloseEventParams,
    public readonly source = CloseEntityDetailsCommand,
  ) {
    super();
  }
}

export class CloseEntityDetailsCommand extends CommandBase<CloseEntityDetailsCommandParams> {
  execute(params: CloseEntityDetailsCommandParams): EntityDetailsCloseEvent | CommandError {
    const entity = this.model.entityRepository.get(params.entityId);
    if (!entity) return CommandError.of(new Error(`Entity with id ${params.entityId} not found`), 'error');
    this.model.entityDetails.close(params.entityId);
    this.unBlinkParent(entity);
    this.model.store.setConstructToPanTo(undefined);
    TransientStore.provider.set('entityDetailView', undefined);
    return new EntityDetailsCloseEvent({ entityId: params.entityId });
  }

  unBlinkParent(entity: EntityBase) {
    const visibleParent = entity.visibleParent;
    if (visibleParent) {
      visibleParent.blink = false;
      this.model.observer.notify(NotificationTypes.OnTransientChange, {
        added: [],
        updated: [{ entity: visibleParent, modifiedProperties: ['blink'] }],
        deleted: [],
      });
    }
  }
}

import { createContext, FC, ReactNode, useContext } from 'react';

interface EdgeContextProps {
  edgeId: string;
}

const EdgeContext = createContext<EdgeContextProps | undefined>(undefined);

export const EdgeProvider: FC<{ edgeId: string; children: ReactNode }> = ({ edgeId, children }) => {
  return <EdgeContext.Provider value={{ edgeId }}>{children}</EdgeContext.Provider>;
};

export const useEdgeContext = (): EdgeContextProps => {
  const context = useContext(EdgeContext);
  if (!context) {
    throw new Error('useEdgeContext must be used within an EdgeProvider');
  }
  return context;
};

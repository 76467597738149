import { Box } from '@mui/material';
import { NoSpaceResults } from './no-results/no-space-results';
import { SpaceList } from './space-list/space-list';
import { useActiveOrganization } from '../../hooks/use-active-organization';
import { SpacesSidebarLinkValues } from './sidebar/list/links-list';
import { useSingleSourceStore } from '../../store/single-source-store/single-source-store';
import { Workspace } from '../../gql/graphql';

interface SpacesProps {
  type: SpacesSidebarLinkValues;
}

export const Spaces = (props: SpacesProps) => {
  const { type } = props;
  const { organization } = useActiveOrganization();
  if (!organization) return null;

  const allSpaces = organization.workspaces;
  const recentSpaces = useSingleSourceStore.use.recentSpacesByOrg()[organization.id];
  const recentsWithoutDeleted = recentSpaces
    ? recentSpaces.filter((recentWorkspace) => allSpaces.some((allSpace) => recentWorkspace.id === allSpace.id))
    : [];
  const spaces = (type === SpacesSidebarLinkValues.Recents ? recentsWithoutDeleted : allSpaces) as Workspace[];

  if (spaces?.length === 0) {
    return <NoSpaceResults />;
  }

  return (
    <Box p={3} pb={0} overflow="auto" height="100%">
      <SpaceList spaces={spaces} />
    </Box>
  );
};

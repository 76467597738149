import { Box, IconButton, Stack, SxProps, Theme, Tooltip } from '@mui/material';
import { SidebarTab, SidebarTabs, SidebarTabValues } from './sidebar-tabs/sidebar-tabs';
import { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { AssetExplorer } from './asset-explorer/asset-explorer';
import { ProfileDetails } from './profile-details/profile-details';
import { TabContext, TabPanel } from '@mui/lab';
import { Icon } from '@xspecs/design-system';
import { useIntl } from 'react-intl';
import { Explorer } from './explorer/explorer';
import { ExplorerSort, ExplorerState, AssetExplorerState, AssetSort } from '@xspecs/single-source-model';
import { Controls } from './controls/controls';

export type SidebarProps = {
  assetExplorer: AssetExplorerState;
  explorer: ExplorerState;
  profile: {
    name: string;
    email: string;
    picture: string;
  };
  collapsed: boolean;
  onCollapse: () => void;
};

const tabs: SidebarTab[] = [
  {
    value: SidebarTabValues.Explorer,
    label: 'Explorer',
  },
  {
    value: SidebarTabValues.Assets,
    label: 'Assets',
  },
];

export const Sidebar = (props: SidebarProps) => {
  const { assetExplorer, explorer, profile, collapsed, onCollapse } = props;

  const [tab, setTab] = useState<SidebarTabValues>(SidebarTabValues.Explorer);

  const { formatMessage: f } = useIntl();

  const handleTabChange = useCallback((event: SyntheticEvent, newTab: SidebarTabValues) => {
    setTab(newTab);
  }, []);

  const explorerWrapperSx: SxProps = useMemo(
    () => ({ px: collapsed ? 1.5 : 2, flex: 1, pb: 1, overflowY: 'auto', overflowX: 'visible' }),
    [collapsed],
  );

  const openAssetExplorer = useCallback(() => {
    onCollapse();
    setTab(SidebarTabValues.Assets);
  }, [onCollapse]);

  const openExplorer = useCallback(() => {
    onCollapse();
    setTab(SidebarTabValues.Explorer);
  }, [onCollapse]);

  return (
    <Stack sx={sidebarSx} width={collapsed ? '64px' : '100%'}>
      <Box sx={logoSx}>{/*<Logo />*/}</Box>
      <Box sx={controlsWrapperSx}>
        {!collapsed ? (
          <Controls
            type={tab}
            searchValue={tab === SidebarTabValues.Explorer ? explorer.searchQuery : assetExplorer.searchQuery}
            sortValue={tab === SidebarTabValues.Explorer ? explorer.sortQuery : assetExplorer.sortQuery}
            sortOptions={tab === SidebarTabValues.Explorer ? ExplorerSort : AssetSort}
          />
        ) : null}
        <IconButton sx={backButtonSx} onClick={onCollapse}>
          <Icon name={collapsed ? 'show-sidebar' : 'hide-sidebar'} width={24} height={24} />
        </IconButton>
      </Box>

      <Box>
        {collapsed ? (
          <Box sx={collapsedTabsWrapperSx}>
            <Tooltip title={SidebarTabValues.Explorer} placement="right">
              <IconButton sx={assetExplorerButtonSx} onClick={openExplorer}>
                <Icon name="explorer" width={24} height={24} />
              </IconButton>
            </Tooltip>
            <Tooltip title={SidebarTabValues.Assets} placement="right">
              <IconButton sx={assetExplorerButtonSx} onClick={openAssetExplorer}>
                <Icon name="asset-stack" width={24} height={24} />
              </IconButton>
            </Tooltip>
          </Box>
        ) : (
          <Box sx={tabsWrapperSx}>
            <SidebarTabs tabs={tabs} onChange={handleTabChange} value={tab} />
          </Box>
        )}
      </Box>

      <Box sx={explorerWrapperSx}>
        <TabContext value={tab}>
          <TabPanel value={SidebarTabValues.Assets} sx={{ p: 0 }}>
            {!collapsed ? <AssetExplorer assets={assetExplorer.results} scope={assetExplorer.scope} /> : null}
          </TabPanel>
          <TabPanel value={SidebarTabValues.Explorer} sx={{ p: 0 }}>
            {!collapsed ? <Explorer items={explorer.results} /> : null}
          </TabPanel>
        </TabContext>
      </Box>
      <Box>
        <ProfileDetails {...profile} collapsed={collapsed} />
      </Box>
    </Stack>
  );
};

const sidebarSx: SxProps = {
  height: '100%',
  position: 'relative',
};

const logoSx: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  // pt: 3,
  // pr: 2,
  // pb: 5,
  // pl: 2,
};

const backButtonSx: SxProps<Theme> = {
  borderRadius: 2,
  width: 28,
  height: 28,
  color: 'text.primary',
  mx: 'auto',
  alignSelf: 'center',
  padding: 0,
};

const controlsWrapperSx: SxProps<Theme> = { display: 'flex', px: 2, mb: 1.5, mt: 2, gap: 1 };

const tabsWrapperSx: SxProps = {
  px: 2,
  mb: 1,
};

const collapsedTabsWrapperSx: SxProps<Theme> = { px: 1.5, display: 'flex', flexDirection: 'column', gap: 1, mt: 1.5 };

const assetExplorerButtonSx: SxProps<Theme> = {
  borderRadius: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: (theme) => theme.palette.grey[200],
  '&:hover': {
    backgroundColor: (theme) => theme.palette.grey[300],
  },
};

import { CommandBase, IParams } from '../framework/CommandBase';
import { ScriptBase } from '../../entities/scripts/ScriptBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';

interface RenameScriptLaneParams extends IParams {
  scriptId: string;
  laneIndex: number;
  value: string;
}

export interface ScriptLaneRenamedParams extends IParams {
  scriptId: string;
  laneIndex: number;
  value: string;
}

export class ScriptLaneRenamedEvent extends EventBase {
  static eventType = 'ScriptLaneRenamedEvent';

  constructor(public readonly params: ScriptLaneRenamedParams, public readonly source = RenameScriptLaneCommand) {
    super();
  }
}

export class RenameScriptLaneCommand extends CommandBase<RenameScriptLaneParams> {
  execute(params: RenameScriptLaneParams): ScriptLaneRenamedEvent | CommandError | undefined {
    const script = this.model.entityRepository.get<ScriptBase>(params.scriptId)!;
    const isRenamed = script.renameLane(params.laneIndex, params.value);
    if (!isRenamed) return;
    this.model.entityRepository.update(script);
    return new ScriptLaneRenamedEvent({ scriptId: params.scriptId, laneIndex: params.laneIndex, value: params.value });
  }
}

import { useApolloClient, useMutation } from '@apollo/client';
import { useIntl } from 'react-intl';
import { useCallback, useMemo } from 'react';
import { Box, Button, SxProps, Theme, Typography } from '@mui/material';
import { Modal } from '../modal/modal';
import { DELETE_WORKSPACE_MUTATION } from '../../graphql/mutations';
import { ORGANIZATIONS_QUERY } from '../../graphql/queries';
import { AppTypeEvent, useTrackEvents } from '../../hooks/use-track-events';
import { useSnackStack } from '../../wrappers/snack-stack-context';
import { useActiveOrganization } from '../../hooks/use-active-organization';
import { LAST_SELECTED_ORGANIZATION_ID_KEY } from '../../lib/utils';
import { useSingleSourceStore } from '../../store/single-source-store/single-source-store';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

export const SpaceDeleteModal = () => {
  const spaceToDelete = useSingleSourceStore.use.spaceToDelete();
  const setSpaceToDelete = useSingleSourceStore.use.setSpaceToDelete();
  const { organization, refreshActiveOrganization } = useActiveOrganization();

  const apolloClient = useApolloClient();
  const [deleteWorkspace] = useMutation(DELETE_WORKSPACE_MUTATION);

  const { trackEvent } = useTrackEvents();

  const { addToast } = useSnackStack();

  const { formatMessage: f } = useIntl();

  const workspace = useMemo(
    () => organization?.workspaces?.find((workspace) => workspace.id === spaceToDelete),
    [organization, spaceToDelete],
  );

  const onClose = useCallback(() => {
    if (!workspace) return;
    setSpaceToDelete(undefined);
  }, [workspace, setSpaceToDelete]);

  const onDelete = useCallback(async () => {
    if (!workspace || !organization) return;
    const response = await deleteWorkspace({
      variables: {
        args: {
          organizationId: organization?.id,
          workspaceId: workspace?.id,
        },
      },
    });
    if (!response.data) return;
    if (response.data.deleteWorkspace.error) {
      addToast({ message: f({ id: 'space-deletion-error' }), severity: 'error' });
    } else {
      addToast({
        message: f({ id: 'space-deletion-success' }, { name: workspace?.name }),
        severity: 'success',
      });
      trackEvent(AppTypeEvent.WorkspaceDeleted, { workspaceId: workspace?.id, name: workspace?.name });
      await apolloClient.refetchQueries({ include: [ORGANIZATIONS_QUERY] });
      localStorage.removeItem(LAST_SELECTED_ORGANIZATION_ID_KEY);
      await refreshActiveOrganization();
    }
    onClose();
  }, [
    workspace,
    organization,
    deleteWorkspace,
    onClose,
    addToast,
    f,
    trackEvent,
    apolloClient,
    refreshActiveOrganization,
  ]);

  return (
    <Modal
      id="WorkspaceSettingsModal"
      isOpen={!!spaceToDelete}
      onClose={onClose}
      headerSxOverrides={{ display: 'none' }}
      rootSxOverrides={{ overflow: 'visible', borderRadius: '8px' }}
    >
      <CloseOutlinedIcon sx={closeButtonSx} fontSize="small" onClick={onClose} />
      <Box display="flex" alignItems="center" gap={1}>
        <DeleteForeverOutlinedIcon />
        <Typography variant="h6">{f({ id: 'delete-space' })}</Typography>
      </Box>
      <Typography variant="subtitle2" color="text.secondary">
        {f({ id: 'delete-space-confirmation' }, { name: workspace?.name })}
      </Typography>
      <Box sx={buttonWrapperSx}>
        <Button variant="text" onClick={onClose}>
          {f({ id: 'cancel' })}
        </Button>
        <Button data-testid="WorkspaceSettingsModalDeleteButton" variant="contained" color="error" onClick={onDelete}>
          {f({ id: 'yes-i-want-to-delete-it' })}
        </Button>
      </Box>
    </Modal>
  );
};

const closeButtonSx: SxProps<Theme> = {
  position: 'absolute',
  top: 16,
  right: 16,
  color: 'text.secondary',
  cursor: 'pointer',
};

const buttonWrapperSx: SxProps<Theme> = { display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 1.5 };

import { ConstructBase } from './ConstructBase';
import { EntityType } from '../EntityType';

export class Constraints extends ConstructBase {
  color = '#FFF9B1';

  static version = '2.0.0'; // Replaces transitions with edges;

  get type(): EntityType {
    return EntityType.Constraints;
  }

  static parse(data: unknown): Constraints {
    return ConstructBase.parseBase.call(Constraints, data);
  }
}

import { FC, MouseEvent, useState } from 'react';
import { Box, IconButton as MuiIconButton, Menu, MenuItem, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { EdgeToolbarElementIconButton, EdgeToolbarElementIconButtonMenu } from '@xspecs/single-source-model';
import { Icon } from '@xspecs/design-system';
import { useCommandDispatch } from '../../../../../../wrappers/application-context/application-context';
import { useEdgeContext } from '../edge-toolbar-context';

export const IconButton: FC<EdgeToolbarElementIconButton> = (props) => {
  const { icon, iconColor, tooltip, menu, onClick } = props;

  const theme = useTheme();
  const { edgeId } = useEdgeContext();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const dispatch = useCommandDispatch();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    if (onClick) dispatch(onClick.command, { ...onClick.params, id: edgeId });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onOptionSelect = (option: EdgeToolbarElementIconButtonMenu['items'][0]) => () => {
    if (!menu) return;
    handleClose();
    dispatch(menu.onSelect.command, {
      ...menu.onSelect.params,
      [menu.onSelect.params.valueKey]: 'color' in option ? option.color : option.value,
      id: edgeId,
    });
  };

  const iconButton = (
    <Tooltip title={tooltip} placement="top" disableInteractive>
      <MuiIconButton
        sx={{
          ...rootSx,
          backgroundColor: open ? 'action.hover' : '',
          ':hover': {
            backgroundColor: 'action.hover',
          },
        }}
        size="small"
        onClick={handleClick}
        disableRipple
        disableTouchRipple
        disableFocusRipple
      >
        <Icon name={icon} color={iconColor} />
      </MuiIconButton>
    </Tooltip>
  );

  if (!menu) return iconButton;

  const [firstItem] = menu.items;
  return (
    <>
      {iconButton}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          sx:
            'color' in firstItem
              ? {
                  display: 'flex',
                  width: 404,
                  flexWrap: 'wrap',
                  gap: 0.5,
                  p: 1,
                }
              : undefined,
        }}
      >
        {menu.items.map((item, index) => {
          if ('color' in item) {
            return (
              <MenuItem
                key={`IconButtonMenuItem${index}`}
                sx={{ padding: 0, borderRadius: '50%' }}
                onClick={onOptionSelect(item)}
              >
                <div
                  style={{
                    height: 24,
                    width: 24,
                    position: 'relative',
                  }}
                >
                  {item.selected ? (
                    <div
                      style={{
                        position: 'absolute',
                        zIndex: 10,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Icon name="done" color={theme.palette.getContrastText(item.color)} width={20} height={20} />
                    </div>
                  ) : null}
                  <div
                    style={{
                      height: 24,
                      width: 24,
                      backgroundColor: item.color,
                      borderRadius: '50%',
                      position: 'absolute',
                    }}
                  />
                </div>
              </MenuItem>
            );
          }

          return (
            <MenuItem
              key={`IconButtonMenuItem${index}`}
              value={item.value}
              onClick={onOptionSelect(item)}
              disabled={item.disabled}
            >
              <Stack direction="row" alignItems="center" width="100%" gap={1}>
                <Icon name={item.icon} width={16} height={16} strokeWidth={item.iconStrokeWidth} />
                <Typography>{item.label}</Typography>
                {typeof item.selected === 'boolean' && item.selected ? (
                  <Box ml="auto">
                    <Icon name="done" width={12} height={12} />
                  </Box>
                ) : null}
                {item.subtitle ? (
                  <Box ml="auto">
                    <Typography variant="inputLabel" color="text.disabled">
                      {item.subtitle}
                    </Typography>
                  </Box>
                ) : null}
              </Stack>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

const rootSx = {
  borderRadius: '4px',
  p: 0.25,
};

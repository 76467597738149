import { Frame, FrameGroup, Lane, LaneGroup, PermissionAction } from 'narrative-studio-sdk';

export class ScriptConfig {
  constructor(public readonly frameGroups: FrameGroup[], public readonly laneGroups: LaneGroup[]) {}

  public get defaultFrameWidth(): number {
    return this.frameGroups[0]?.frameWidth || 200;
  }

  public get defaultLaneHeight(): number {
    return this.laneGroups[0]?.laneHeight || 200;
  }

  public get defaultLaneColor(): string {
    return this.laneGroups[0]?.style?.backgroundColor || '#F8F8F8';
  }

  public static hasFrameGroupPermission(frameGroup: FrameGroup, requiredAction: PermissionAction): boolean {
    return frameGroup?.permissions?.actions?.some((action) => (action & requiredAction) === requiredAction) ?? false;
  }

  public static hasLaneGroupPermission(laneGroup: LaneGroup, requiredAction: PermissionAction): boolean {
    return laneGroup?.permissions?.actions?.some((action) => (action & requiredAction) === requiredAction) ?? false;
  }

  public static isEntityAllowed(item: LaneGroup | FrameGroup | Lane | Frame, entityType: string): boolean {
    if (item.allowedEntities?.type === 'ALL') return true;
    if (item.allowedEntities?.type === 'NONE') return false;
    if (item.allowedEntities?.type === 'SPECIFIC') {
      return item.allowedEntities.entities.some((e) => e === entityType);
    }
    return false;
  }
}

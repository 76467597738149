import { IconComponentProps } from '../icon';
import { FC } from 'react';

export const InsertBefore: FC<IconComponentProps> = ({ color }) => {
  return (
    <path
      d="M13 15L8 20M8 20L3 15M8 20V8C8 6.93913 8.42143 5.92172 9.17157 5.17157C9.92172 4.42143 10.9391 4 12 4H19M18 8V14M21 11H15"
      stroke={color ?? '#27272A'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
};

import { IconComponentProps } from '../icon';
import { FC } from 'react';

export const Boxes: FC<IconComponentProps> = ({ color }) => {
  return (
    <>
      <path
        d="M9 3H4.00001C3.44772 3 3 3.44772 3 4V9C3 9.55228 3.44772 10 4.00001 10H9C9.55228 10 10 9.55228 10 9V4C10 3.44772 9.55228 3 9 3Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 3H15C14.4427 3 14 3.44772 14 4V9C14 9.55228 14.4427 10 15 10H20C20.2613 10 20.5 9.76131 20.5 9.5V4.00001C20.5 3.73869 20.2613 3 20 3Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 14H15C14.4427 14 14 14.4477 14 15V20C14 20.5523 14.4427 21 15 21H20C20.2613 21 20.5 20.7613 20.5 20.5V15C20.5 14.7387 20.2613 14 20 14Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 14H4.00001C3.44772 14 3 14.4477 3 15V20C3 20.5523 3.44772 21 4.00001 21H9C9.55228 21 10 20.5523 10 20V15C10 14.4477 9.55228 14 9 14Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
};

import { Box, IconButton, Menu, MenuItem, PaperProps, SxProps, Theme, Typography } from '@mui/material';
import { MouseEvent, useCallback, useMemo, useState } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import { useIntl } from 'react-intl';
import { Workspace } from '../../../../state/types';
import { getRoutePathFromParams, RoutePaths } from '../../../../config/route-paths/route-paths';
import { useActiveWorkspace } from '../../../../hooks/use-active-workspace';
import { useNavigate } from 'react-router-dom';
import { useActiveOrganization } from '../../../../hooks/use-active-organization';
import { useSingleSourceStore } from '../../../../store/single-source-store/single-source-store';

export const SpaceItem = (space: Workspace) => {
  const { name } = space;
  const [isHovered, setIsHovered] = useState(false);
  const [moreMenuEl, setMoreMenuEl] = useState<HTMLElement | null>(null);
  const { organization } = useActiveOrganization();
  const { setWorkspace } = useActiveWorkspace();
  const navigate = useNavigate();

  const setSpaceToRename = useSingleSourceStore.use.setSpaceToRename();
  const setSpaceToDelete = useSingleSourceStore.use.setSpaceToDelete();
  const setRecentSpacesByOrg = useSingleSourceStore.use.setRecentSpacesByOrg();

  const { formatMessage: f } = useIntl();

  const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setMoreMenuEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setMoreMenuEl(null);
    setIsHovered(false);
  }, []);

  const handleMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false);
    handleClose();
  }, [handleClose]);

  const onSpaceClick = useCallback(
    (workspace: Workspace) => {
      setWorkspace(workspace);
      if (!organization || !workspace) return;
      setRecentSpacesByOrg(organization.id, workspace);
      navigate(
        getRoutePathFromParams(RoutePaths.Workspace, {
          organizationName: organization.name,
          workspaceName: workspace.name,
        }),
      );
      handleMouseLeave();
    },
    [handleMouseLeave, navigate, organization, setRecentSpacesByOrg, setWorkspace],
  );

  const openSpaceRename = useCallback(() => {
    if (!space) return;
    setSpaceToRename(space.id);
  }, [setSpaceToRename, space]);

  const openSpaceDelete = useCallback(() => {
    if (!space) return;
    setSpaceToDelete(space.id);
  }, [setSpaceToDelete, space]);

  const moreButtonSx = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: '8px',
      right: '8px',
      backgroundColor: moreMenuEl ? 'primary.main' : 'text.primary',
      borderRadius: 1,
      width: '24px',
      height: '24px',
      color: 'white',
      '&:hover': {
        backgroundColor: moreMenuEl ? 'primary.main' : 'text.primary',
      },
    }),
    [moreMenuEl],
  );

  return (
    <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} sx={{ position: 'relative' }}>
      <IconButton
        hidden={!isHovered}
        sx={moreButtonSx}
        onClick={handleClick}
        data-testid={`space-item-${space.name}-more-menu-button`}
      >
        <MoreHorizIcon fontSize="small" />
      </IconButton>
      <Box sx={spaceSx} onClick={() => onSpaceClick(space)}>
        <Typography variant="subtitle1" color="text.primary">
          {name}
        </Typography>
      </Box>
      <Menu
        data-testid={`space-item-${space.name}-more-menu`}
        anchorEl={moreMenuEl}
        open={Boolean(moreMenuEl)}
        onClose={handleClose}
        onClick={handleClose}
        onMouseLeave={handleMouseLeave}
        PaperProps={MenuPaperProps}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        TransitionProps={{
          timeout: { enter: 200, exit: 200 },
          easing: { enter: 'ease-out', exit: 'ease-out' },
        }}
      >
        <MenuItem data-testid="profile-menu-logout" onClick={() => onSpaceClick(space)}>
          <FolderOpenOutlinedIcon color="action" fontSize="small" sx={{ marginRight: 1 }} />
          <Typography variant="body1">{f({ id: 'open' })}</Typography>
        </MenuItem>
        <MenuItem data-testid="profile-menu-logout" onClick={openSpaceDelete}>
          <DeleteForeverOutlinedIcon color="action" fontSize="small" sx={{ marginRight: 1 }} />
          <Typography variant="body1">{f({ id: 'delete' })}</Typography>
        </MenuItem>
        <MenuItem data-testid="profile-menu-logout" onClick={openSpaceRename}>
          <DriveFileRenameOutlineOutlinedIcon color="action" fontSize="small" sx={{ marginRight: 1 }} />
          <Typography variant="body1">{f({ id: 'rename' })}</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

const MenuPaperProps: Partial<PaperProps> = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    width: '200px',
  },
};

const spaceSx: SxProps<Theme> = {
  height: '200px',
  outline: '1px solid',
  outlineColor: 'base.border',
  borderRadius: 2,
  px: 3,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  '&:hover': {
    outline: '2px solid',
    outlineColor: 'primary.main',
    cursor: 'pointer',
  },
};

import { FC } from 'react';
import { IconComponentProps } from '../icon';

export const Ghost: FC<IconComponentProps> = () => {
  return (
    <path
      d="M9 10.5H9.01M15 10.5H15.01M12 2.5C9.87827 2.5 7.84344 3.34285 6.34315 4.84315C4.84285 6.34344 4 8.37827 4 10.5V22.5L7 19.5L9.5 22L12 19.5L14.5 22L17 19.5L20 22.5V10.5C20 8.37827 19.1571 6.34344 17.6569 4.84315C16.1566 3.34285 14.1217 2.5 12 2.5Z"
      stroke="#27272A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
};

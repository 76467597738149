import { Attributes, EntityBase } from '../EntityBase';
import { AttachmentType, EntityType } from '../EntityType';
import { AssetBase } from './AssetBase';
import { EntityParserFactory } from '../EntityParserFactory';
import { ATTACHMENT_DIMENSIONS, attachmentBaseSchema } from './AttachmentBaseSchema';
import { Z_INDEXES } from '../../ZIndexes';
import { Preview } from './Preview';
import { Upload, UploadType } from './Upload';
import { Thread } from '../threads/Thread';

export class Attachment extends EntityBase {
  constructor(
    public id: string,
    public name: string,
    public subType: AttachmentType & string,
    public parent?: EntityBase,
    public position: { x: number; y: number } = { x: 0, y: 0 },
    public scopes: string[] = [],
    public attributes: Attributes = { fontSize: -1 },
    public width = ATTACHMENT_DIMENSIONS.width,
    public height = ATTACHMENT_DIMENSIONS.height,
    public isVisible = true,
    public zIndex = Z_INDEXES.Attachment,
  ) {
    super(id, name, parent, position, scopes, attributes, width, height, isVisible, zIndex);
    this.subType = subType;
  }

  // static version = '1.0.1'; // Rename Tags to Labels;
  // static version = '1.0.2'; // Added upload preview to upload attachment subtypes;
  // static version = '1.0.3'; // New dimensions
  //static version = '1.0.4'; // Adjusts new dimensions to parents
  static version = '1.0.5'; // Adds Edges to preview attachment;

  public asset: AssetBase | undefined;

  public preview: Preview;

  //public gqlEntities: GqlEntityBase[] = [];

  static references = ['asset', 'preview'];

  static iconMap: Record<string, string> = {
    [AttachmentType.Actor]: '/actor.svg',
    [AttachmentType.Doc]: '/doc.svg',
    [AttachmentType.Query]: '/query.svg',
    [AttachmentType.Schema]: '/key-value-pair.svg',
    [AttachmentType.Spec]: '/spec.svg',
    [AttachmentType.Upload]: '/attachment.svg',
  };

  static dataSources: Record<string, string> = {
    [AttachmentType.Actor]: `assets.${AttachmentType.Actor}`,
    [AttachmentType.Doc]: `assets.${AttachmentType.Doc}`,
    [AttachmentType.Query]: `assets.${AttachmentType.Query}`,
    [AttachmentType.Schema]: `assets.${AttachmentType.Schema}`,
    [AttachmentType.Spec]: `assets.${AttachmentType.Spec}`,
    [AttachmentType.Upload]: `assets.${AttachmentType.Upload}`,
  };

  get type(): EntityType {
    return EntityType.Attachment;
  }

  get iconUrl() {
    if (this.subType === AttachmentType.Upload) {
      if (this.asset && this.asset instanceof Upload) {
        if (this.asset.subType === UploadType.Image) return '/photo.svg';
        const urlIcon = getIconForUrl(this.asset.url!);
        if (urlIcon) return urlIcon;
      }
    }

    return Attachment.iconMap[this.subType] ?? '/attachment.svg';
  }

  static parse(data: unknown): Attachment {
    const validatedData = attachmentBaseSchema.parse(data);
    const attachment = new Attachment(
      validatedData.id,
      validatedData.name,
      validatedData.subType,
      validatedData.parent,
      {
        x: validatedData.position?.x ?? 0,
        y: validatedData.position?.y ?? 0,
      },
      validatedData.scopes,
      validatedData.attributes,
      validatedData.width,
      validatedData.height,
      validatedData.isVisible,
      validatedData.zIndex,
    );
    if (validatedData.asset) {
      attachment.linkAsset(EntityParserFactory.parse(validatedData.asset, validatedData.subType) as AssetBase);
    }
    if (validatedData.preview) {
      const parsedPreviewData = EntityParserFactory.parse<Preview>(validatedData.preview, EntityType.Preview);
      attachment.linkPreview(parsedPreviewData);
    }
    return attachment;
  }

  isValid(): boolean {
    return attachmentBaseSchema.safeParse(this);
  }

  public linkAsset(asset: AssetBase): void | Error {
    // if (!(asset instanceof AssetBase)) return new Error('Attachment can only ingest assets');
    this.asset = asset;
    //asset.parent = this;
  }

  public linkPreview(preview: Preview): Error | void {
    if (!(this.asset instanceof Upload)) return new Error('Only Upload assets can have previews');
    this.preview = preview;
    preview.parent = this;
  }

  serialize(reference: boolean = false): unknown {
    if (reference) return super.serialize(reference);
    return {
      ...(super.serialize() as any),
      asset: this?.asset?.serialize(true),
      subType: this.subType,
      preview: this.preview?.serialize(true),
    };
  }

  public recalculateDimensions() {
    this.width = this.parent ? this.parent.width : attachmentBaseSchema.shape.width._def.defaultValue();
    const PADDING = 10;
    this.children.forEach((child) => {
      const box = child.boundingBox;
      if (box.x + box.width > this.width) {
        child.position.x = this.width + this.position.x - PADDING;
      }
      if (box.y + box.height > this.height) {
        child.position.y = this.height + this.position.y - PADDING;
      }
    });
  }

  static registerAssetIcon(params: { assetType: string; url: string }) {
    this.iconMap[params.assetType] = params.url;
  }

  static registerDataSource(params: { assetType: string; dataSource: string }) {
    this.dataSources[params.assetType] = params.dataSource;
  }

  get assetListDataSource(): string | Error {
    const dataSource = Attachment.dataSources[this.subType];
    if (!dataSource) return new Error('No data source found for this attachment type');
    return dataSource;
  }

  ingestEntity(entity: EntityBase): boolean {
    if (entity.type === EntityType.Thread) {
      this.ingestThread(entity as Thread);
      return true;
    }

    return false;
  }

  eject(entity: EntityBase): boolean {
    if (entity.type === EntityType.Thread) {
      this.ejectThread(entity as Thread);
      return true;
    }

    return false;
  }

  static isValidAttachmentType(type: string): boolean {
    // TODO: Need a proper way to do this
    return Object.keys(Attachment.iconMap).includes(type);
  }
}

const getIconForUrl = (url: string) => {
  if (!url) return null;

  if (url.includes('jira')) {
    return '/jira.svg';
  }
  if (url.includes('atlassian.net')) {
    return '/confluence.svg';
  }
  if (url.includes('slack.com')) {
    return '/slack.svg';
  }
  if (url.includes('linear.app')) {
    return '/linear.svg';
  }
  if (url.includes('notion.so')) {
    return '/notion.svg';
  }
  if (url.includes('miro.com')) {
    return '/miro.svg';
  }
  if (url.includes('docs.google.com/spreadsheets')) {
    return '/google-sheets.svg';
  }
  if (url.includes('docs.google.com/document')) {
    return '/google-docs.svg';
  }
  if (url.includes('figma.com')) {
    return '/figma.svg';
  }

  return null;
};

import { IconComponentProps } from '../icon';
import { FC } from 'react';

export const InsertAfter: FC<IconComponentProps> = ({ color }) => {
  return (
    <path
      d="M11 15L16 20M16 20L21 15M16 20V8C16 6.93913 15.5786 5.92172 14.8284 5.17157C14.0783 4.42143 13.0609 4 12 4H5M6 8V14M9 11H3"
      stroke={color ?? '#27272A'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
};

import { Handle, Node, NodeProps, Position } from '@xyflow/react';
import { useEffect, useMemo, useRef } from 'react';
import { DropTargetNodeData } from '@xspecs/single-source-model';

export const DropTargetNode = (props: NodeProps<Node<DropTargetNodeData>>) => {
  const { data } = props;
  const nodeRef = useRef<HTMLDivElement>(null);

  // console.log(props.id, data.subType);
  const isRejected = data.subType === 'rejected';
  const isActual = data.subType === 'actual';
  // const isWanted = data.subType === 'wanted';
  const actualColor = 'rgba(0,255, 0, 1)';
  const rejectedColor = 'rgba(255, 0, 0, 0.5)';
  const color = isActual ? actualColor : isRejected ? rejectedColor : rejectedColor;

  const styles = useMemo(
    () => ({
      backgroundColor: color,
    }),
    [color],
  );

  useEffect(() => {
    if (nodeRef.current) {
      const parentElement = nodeRef.current.parentElement;
      if (parentElement) parentElement.style.pointerEvents = 'none';
    }
  }, []);

  return (
    <>
      <div
        style={{
          position: 'absolute',
          width: `${data.width}px`,
          height: `${data.height}px`,
          pointerEvents: 'none',
          opacity: 0.5,
        }}
        className={`${data.entityType} nodrag`}
      ></div>
      <div
        id={data.id}
        ref={nodeRef}
        style={{
          position: 'absolute',
          width: `${data.width}px`,
          height: `${data.height}px`,
          border: `3px ${color} dashed`,
          pointerEvents: 'none',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        className={`nodrag`}
      >
        {/*{!isActual || !isWanted ? <Icon name="block" color={color} /> : null}*/}
      </div>
      <Handle type="source" position={Position.Top} style={styles} />
      <Handle type="source" position={Position.Bottom} style={styles} />
      <Handle type="source" position={Position.Left} style={styles} />
      <Handle type="source" position={Position.Right} style={styles} />
    </>
  );
};

import { useMutation } from '@apollo/client';
import { Box, TextField, Typography, useTheme } from '@mui/material';
import { ChangeEventHandler, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { NavBar } from '../../components/nav-bar/nav-bar';
import { getRoutePathFromParams, RoutePaths } from '../../config/route-paths/route-paths';
import { ADD_WORKSPACE_MUTATION, ADD_ORGANIZATION_MUTATION } from '../../graphql/mutations';
import { logger } from '@xspecs/logger';
import { LoadingButton } from '@mui/lab';
import { isOrganizationNameValid } from '../../utils';
import { Icon } from '@xspecs/design-system';
import { sid } from '@xspecs/short-id';
import { AppTypeEvent, useTrackEvents } from '../../hooks/use-track-events';

export const CreateOrganization = () => {
  const [organizationName, setOrganizationName] = useState('');
  const [workspaceName, setWorkspaceName] = useState('');
  const [organizationError, setOrganizationError] = useState('');
  const [organizationWarning, setOrganizationWarning] = useState('');
  const [workspaceError, setWorkspaceError] = useState('');

  const { formatMessage: f } = useIntl();
  const navigate = useNavigate();
  const { trackEvent } = useTrackEvents();

  const theme = useTheme();

  const organizationUrl = `${location.origin}/${organizationName ? organizationName : '...'}`;
  const [addOrganization, { client, loading: loadingAddOrganization }] = useMutation(ADD_ORGANIZATION_MUTATION);
  const [addWorkspace, { loading: loadingAddWorkspace }] = useMutation(ADD_WORKSPACE_MUTATION);

  const onOrganizationNameChange = useCallback<ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>>((event) => {
    setOrganizationName(event.target.value);
  }, []);

  const onOrganizationNameBlur = useCallback(() => {
    if (!organizationName) {
      setOrganizationWarning('');
    }
  }, [organizationName]);

  const onWorkspaceNameChange = useCallback<ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>>((event) => {
    setWorkspaceName(event.target.value);
    setWorkspaceError('');
  }, []);

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      const organizationId = uuidv4();
      const workspaceId = sid();
      const organizationResponse = await addOrganization({
        variables: { args: { organizationId: organizationId, name: organizationName } },
      });
      const organizationError = organizationResponse.data?.addOrganization?.error;
      if (organizationError) {
        setOrganizationError(organizationError);
        return;
      }

      trackEvent(AppTypeEvent.OrganizationCreated, { organizationId: organizationId });

      const workspaceResponse = await addWorkspace({
        variables: { args: { organizationId, workspaceId, workspaceName } },
      });
      const workspaceError = workspaceResponse.data?.addWorkspace?.error;
      if (workspaceError) {
        setWorkspaceError(workspaceError);
        logger.error("Workspace couldn't be created", workspaceError);
        return;
      }

      trackEvent(AppTypeEvent.WorkspaceCreated, { workspaceId: workspaceId });

      client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'organizations' });
      navigate(getRoutePathFromParams(RoutePaths.InvitePeople, { organizationName }));
    },
    [addOrganization, organizationName, trackEvent, addWorkspace, workspaceName, client.cache, navigate],
  );

  useEffect(() => {
    if (!organizationName) return;
    if (isOrganizationNameValid(organizationName)) {
      setOrganizationError('');
      setOrganizationWarning('');
    } else {
      setOrganizationWarning(f({ id: 'organization-name-input-warning' }));
    }
  }, [f, organizationName]);

  return (
    <Box
      data-testid="create-organization"
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'background.paper',
      }}
    >
      <Box sx={{ height: '64px' }}>
        <NavBar isLoggedIn isPrimary hideLogo hideOrganizations />
      </Box>
      <Box
        sx={{
          pt: 4.5,
          pb: 4.5,
          px: 2,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          width: { md: '415px' },
          mx: { md: 'auto' },
          mt: { md: 22.25 },
          mb: { md: 'auto' },
          boxShadow: {
            md: '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
          },
          borderRadius: { md: '4px' },
        }}
      >
        <Icon
          data-testid="NarrativeLogo"
          name="narrative-filled"
          height={48}
          width={46}
          color={theme.palette.primary.main}
        />
        <form
          onSubmit={onSubmit}
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography data-testid="create-organization-title" variant="h5">
            {f({ id: 'create-a-organization' })}
          </Typography>
          <TextField
            autoFocus
            data-testid="create-organization-company-textbox"
            error={Boolean(organizationError)}
            fullWidth
            helperText={organizationWarning}
            inputProps={{ 'data-testid': 'create-organization-company-textbox-input' }}
            label={f({ id: 'company-team-ask-name' })}
            onBlur={onOrganizationNameBlur}
            onChange={onOrganizationNameChange}
            sx={{
              mt: 4.5,
              ...(organizationWarning
                ? {
                    '.MuiFormHelperText-root': { mx: 0, color: 'warning.main' },
                    '.MuiOutlinedInput-notchedOutline': { borderColor: `${theme.palette.warning.main} !important` },
                    '.MuiFormLabel-root': { color: `${theme.palette.warning.main} !important` },
                  }
                : {}),
            }}
            value={organizationName}
            variant="outlined"
          />
          <Box sx={{ wordBreak: 'break-word', width: '100%' }}>
            <Typography
              data-testid="create-organization-organization-text"
              variant="inputText"
              color={organizationError ? 'error.main' : undefined}
              sx={{ mt: 1.125, width: '100%' }}
            >
              {organizationError ? f({ id: organizationError }, { name: organizationUrl }) : organizationUrl}
            </Typography>
          </Box>
          <TextField
            data-testid="create-organization-workspace-textbox"
            error={Boolean(workspaceError)}
            fullWidth
            inputProps={{ 'data-testid': 'create-organization-workspace-textbox-input' }}
            label={f({ id: 'space-ask-name' })}
            onChange={onWorkspaceNameChange}
            sx={{ mt: 1.625 }}
            value={workspaceName}
            variant="outlined"
          />
          {workspaceError ? (
            <Typography data-testid="create-organization-workspace-error" variant="inputText" color="error.main">
              {f({ id: workspaceError })}
            </Typography>
          ) : null}
          <LoadingButton
            data-testid="create-organization-next-button"
            type="submit"
            fullWidth
            variant="contained"
            loading={loadingAddOrganization || loadingAddWorkspace}
            disabled={
              !organizationName ||
              !workspaceName ||
              Boolean(organizationError) ||
              Boolean(organizationWarning) ||
              Boolean(workspaceError)
            }
            sx={{ mt: 3, height: '42px' }}
          >
            {f({ id: 'next' })}
          </LoadingButton>
        </form>
      </Box>
    </Box>
  );
};

import { useApolloClient, useMutation } from '@apollo/client';
import { useIntl } from 'react-intl';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, SxProps, TextField, Theme, Typography } from '@mui/material';
import { Modal } from '../modal/modal';
import { RENAME_WORKSPACE_MUTATION } from '../../graphql/mutations';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppTypeEvent, useTrackEvents } from '../../hooks/use-track-events';
import { useSnackStack } from '../../wrappers/snack-stack-context';
import { useSingleSourceStore } from '../../store/single-source-store/single-source-store';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import { useActiveOrganization } from '../../hooks/use-active-organization';

export const SpaceRenameModal = () => {
  const spaceToRename = useSingleSourceStore.use.spaceToRename();
  const setSpaceToRename = useSingleSourceStore.use.setSpaceToRename();
  const { organization, refreshActiveOrganization } = useActiveOrganization();

  const [name, setName] = useState('');

  const apolloClient = useApolloClient();
  const [renameWorkspace] = useMutation(RENAME_WORKSPACE_MUTATION);

  const { trackEvent } = useTrackEvents();

  const { addToast } = useSnackStack();

  const { formatMessage: f } = useIntl();
  const navigate = useNavigate();
  const location = useLocation();

  const workspace = useMemo(
    () => organization?.workspaces?.find((workspace) => workspace.id === spaceToRename),
    [organization, spaceToRename],
  );

  const onClose = useCallback(() => {
    setSpaceToRename(undefined);
  }, [setSpaceToRename]);

  const onNameChange = useCallback((e) => {
    setName(e.target.value);
  }, []);

  const onSave = useCallback(async () => {
    if (!workspace) return;
    const response = await renameWorkspace({
      variables: {
        args: {
          newName: name,
          workspaceId: workspace?.id,
        },
      },
    });
    if (!response.data) return;
    if (response.data.renameWorkspace.error) {
      addToast({ message: f({ id: 'space-rename-error' }), severity: 'error' });
    } else {
      addToast({
        message: f({ id: 'space-rename-success' }, { name: workspace?.name, newName: name }),
        severity: 'success',
      });
      trackEvent(AppTypeEvent.WorkspaceNameChanged, {
        workspaceId: workspace?.id,
        name: workspace?.name,
        newName: name,
      });
      await refreshActiveOrganization();
      onClose();
    }
    onClose();
  }, [workspace, renameWorkspace, name, onClose, addToast, f, trackEvent, refreshActiveOrganization]);

  useEffect(() => {
    setName(workspace?.name ?? '');
  }, [workspace?.name]);

  return (
    <Modal
      id="SpaceRenameModal"
      isOpen={!!spaceToRename}
      onClose={onClose}
      headerSxOverrides={{ display: 'none' }}
      rootSxOverrides={{ overflow: 'visible', borderRadius: '8px' }}
    >
      <CloseOutlinedIcon sx={closeButtonSx} fontSize="small" onClick={onClose} />
      <Box display="flex" alignItems="center" gap={1}>
        <DriveFileRenameOutlineOutlinedIcon />
        <Typography variant="h6">{f({ id: 'rename-space' })}</Typography>
      </Box>
      <Box sx={formWrapperSx}>
        <Typography variant="subtitle2" color="text.primary" sx={{ mt: 1, mb: 1 }}>
          {f({ id: 'space-name' })}
        </Typography>
        <TextField
          inputProps={{ 'data-testid': 'WorkspaceSettingsModalNameTextFieldInput' }}
          value={name}
          sx={textFieldSx}
          onChange={onNameChange}
        />
        <Box sx={buttonWrapperSx}>
          <Button variant="text" onClick={onClose} sx={buttonSx}>
            {f({ id: 'cancel' })}
          </Button>
          <Button variant="contained" disabled={!name || name === workspace?.name} onClick={onSave} sx={buttonSx}>
            {f({ id: 'save' })}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const closeButtonSx: SxProps<Theme> = {
  position: 'absolute',
  top: 16,
  right: 16,
  color: 'text.secondary',
  cursor: 'pointer',
};

const formWrapperSx: SxProps<Theme> = { display: 'flex', flexDirection: 'column' };

const textFieldSx: SxProps<Theme> = { mb: 2, '.MuiFormHelperText-root': { mx: 0 }, '.MuiInputBase-input': { py: 1 } };

const buttonWrapperSx: SxProps<Theme> = { display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 1.5 };

const buttonSx: SxProps<Theme> = { minWidth: 'auto', py: 1, textTransform: 'none' };

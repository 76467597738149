import { Backdrop, Box, useMediaQuery, useTheme } from '@mui/material';
import { CreateWorkspaceContent } from './create-workspace-content';

export const CreateWorkspace = () => {
  const theme = useTheme();

  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box>
      {isMd ? (
        <Backdrop open sx={{ backgroundColor: 'rgba(24, 24, 27, 0.65)' }} data-testid="create-workspace-backdrop">
          <CreateWorkspaceContent />
        </Backdrop>
      ) : (
        <CreateWorkspaceContent />
      )}
    </Box>
  );
};

import {
  Box,
  Checkbox,
  createFilterOptions,
  List,
  ListItem,
  Stack,
  SxProps,
  TextField,
  Theme,
  Typography,
  useAutocomplete,
  UseAutocompleteProps,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { useMemo } from 'react';
import { AssetBase } from '@xspecs/single-source-model';
import { Icon } from '@xspecs/design-system';
import { FilterSelectorBackButton } from '../labels/labels-selector/header/filter-selector-back-button';
import { NoSearchResults } from '../no-search-results/no-search-results';

export type AssetsSelectorProps = {
  type: string;
  assets: AssetBase[];
  selectedAssets: AssetBase[];
  onSelectedAssetsChange: (assets: AssetBase[]) => void;
  placeholder?: string;
  disableFreeSolo?: boolean;
  goBack?: () => void;
};

enum PluralAssets {
  Actor = 'Actors',
  Doc = 'Docs',
  Query = 'Queries',
  Schema = 'Schemas',
  Spec = 'Specs',
  Upload = 'Uploads',
}

const filter = createFilterOptions<AssetBase>();

export const AssetsSelector = (props: AssetsSelectorProps) => {
  const {
    assets,
    onSelectedAssetsChange,
    selectedAssets = [],
    placeholder,
    disableFreeSolo = false,
    type,
    goBack,
  } = props;

  const { formatMessage: f } = useIntl();

  const autoCompleteProps = useMemo<UseAutocompleteProps<AssetBase, true, true, boolean>>(() => {
    return {
      open: true,
      multiple: true,
      freeSolo: !disableFreeSolo,
      value: selectedAssets,
      onChange: (event, value, reason, details) => {
        if (typeof value[0] === 'string') return;

        const newAssets = value as AssetBase[];
        onSelectedAssetsChange(newAssets);
      },
      options: assets.filter((asset) => asset.type === type),
      getOptionLabel: (option) => (typeof option === 'string' ? option : option.name),
      filterOptions: (options, params) => {
        const filtered = filter(options, params);
        if (disableFreeSolo) return filtered;
        return filtered;
      },
    };
  }, [disableFreeSolo, assets, onSelectedAssetsChange, selectedAssets, type]);

  const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } =
    useAutocomplete(autoCompleteProps);

  const inputProps = getInputProps();

  return (
    <Stack gap={1} alignItems="flex-start" {...getRootProps()}>
      <Stack sx={inputWrapperSx} gap={1.5}>
        <TextField
          fullWidth
          placeholder={placeholder ?? f({ id: 'search' })}
          slotProps={{
            htmlInput: inputProps,
            input: { startAdornment: <Icon name="search" /> },
          }}
          size="small"
        />
      </Stack>
      {goBack ? <FilterSelectorBackButton goBack={goBack} /> : null}
      <List {...getListboxProps()} sx={listSx}>
        <Box px={2}>
          {groupedOptions.length === 0 && !inputProps.value ? (
            <Typography variant="body2" color="text.primary">
              {f({ id: 'no-filters-created' }, { filter: PluralAssets[type] })}
            </Typography>
          ) : null}
          {groupedOptions.length === 0 && inputProps.value ? <NoSearchResults /> : null}
          {groupedOptions.map((option, index) => {
            const isOption = Boolean(option.id);
            return (
              <ListItem
                {...getOptionProps({ option, index })}
                key={`assetsSelector-option-${index}`}
                sx={{ p: 0, height: 38, px: isOption ? undefined : 2 }}
              >
                {isOption ? (
                  <Checkbox
                    size="small"
                    sx={{ px: 1 }}
                    checked={Boolean(selectedAssets.find((asset) => asset.id === option.id))}
                  />
                ) : null}
                <Typography variant="body2" sx={optionNameSx}>
                  {option.name}
                </Typography>
              </ListItem>
            );
          })}
        </Box>
      </List>
    </Stack>
  );
};

const optionNameSx: SxProps<Theme> = { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' };

const inputWrapperSx: SxProps<Theme> = {
  px: 2,
  pb: 1,
  width: '100%',
  borderBottom: '1px solid',
  borderColor: 'divider',
};

const listSx: SxProps<Theme> = {
  width: '100%',
  maxHeight: 294,
  height: 294,
  overflowY: 'scroll',
  padding: 0,
  '& li.Mui-focused': {
    backgroundColor: 'action.hover',
    color: 'text.primary',
    cursor: 'pointer',
  },
  '& li:active': {
    backgroundColor: 'action.selected',
    color: 'text.primary',
  },
};

import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, List, MenuItem, Stack, Typography, useTheme } from '@mui/material';
import { AddCircleOutline, MoreHoriz, MoreVert } from '@mui/icons-material';
import { DragHandlers, HeaderProps, InsertHandlers } from '@xspecs/single-source-model';
import { useCommandDispatch } from '../../../../wrappers/application-context/application-context';
import { Icon } from '@xspecs/design-system';
import { ControlledMenu, useClick } from '@szhsin/react-menu';
import {
  autoPlacement,
  autoUpdate,
  offset,
  useClick as useClickFloating,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';

const EMPTY_IMAGE = new Image(1, 1);
EMPTY_IMAGE.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

type HeaderComponentProps = {
  orientation?: 'horizontal' | 'vertical';
  bgColor?: string;
  isGroupHeader?: boolean;
  headerProps: HeaderProps;
  onMenuOpenChange: (isOpen: boolean) => void;
} & DragHandlers &
  InsertHandlers;

export const Header = memo((props: HeaderComponentProps) => {
  const {
    orientation = 'horizontal',
    bgColor = 'rgba(228, 228, 231, 1)',
    isGroupHeader,
    onDrag,
    onDragEnd,
    onDragStart,
    headerProps,
    onMenuOpenChange,
  } = props;
  const { onInsertAfter, onInsertBefore } = headerProps;

  const theme = useTheme();
  const isVertical = orientation === 'vertical';
  const draggable = Boolean(onDragStart && onDrag && onDragEnd);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dispatchCommand = useCommandDispatch();

  const floatingConfig = useMemo(
    () => ({
      middleware: [
        offset(10),
        autoPlacement({
          allowedPlacements: ['bottom-start', 'top-start', 'left-start', 'right-start'],
        }),
      ],
      open: isMenuOpen,
      onOpenChange: setIsMenuOpen,
      whileElementsMounted: autoUpdate,
    }),
    [isMenuOpen],
  );

  const { floatingStyles: menuFloatingStyles, refs: menuRefs, context: menuContext } = useFloating(floatingConfig);

  const click = useClickFloating(menuContext);
  const dismiss = useDismiss(menuContext);
  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);

  const addBeforeMenuButtonRef = useRef(null);
  const [isAddBeforeMenuOpen, setIsAddBeforeMenuOpen] = useState(false);
  const addBeforeMenuAnchorProps = useClick(isAddBeforeMenuOpen, setIsAddBeforeMenuOpen);

  const addAfterMenuButtonRef = useRef(null);
  const [isAddAfterMenuOpen, setIsAddAfterMenuOpen] = useState(false);
  const addAfterMenuAnchorProps = useClick(isAddAfterMenuOpen, setIsAddAfterMenuOpen);

  const direction = useMemo(
    () =>
      ({
        horizontal: 'row',
        vertical: 'column',
      } as const),
    [],
  );

  const sx = useMemo(
    () => ({
      fontSize: isGroupHeader ? 28 : 20,
      cursor: 'pointer',
    }),
    [isGroupHeader],
  );

  const handleMenuItemClick = useCallback(
    (command, params) => {
      dispatchCommand(command, params);
    },
    [dispatchCommand],
  );

  const handleDragStart = useCallback(
    (event) => {
      if (!onDragStart) return;
      event.dataTransfer.setDragImage(EMPTY_IMAGE, 0, 0);
      dispatchCommand(onDragStart.command, {
        ...onDragStart.params,
        [onDragStart.params.valueKey]: { x: event.clientX, y: event.clientY },
      });
    },
    [onDragStart, dispatchCommand],
  );

  const handleDrag = useCallback(
    (event) => {
      if (!onDrag) return;
      dispatchCommand(onDrag.command, {
        ...onDrag.params,
        [onDrag.params.valueKey]: { x: event.clientX, y: event.clientY },
      });
    },
    [onDrag, dispatchCommand],
  );

  const handleDragEnd = useCallback(
    (event) => {
      if (!onDragEnd) return;
      dispatchCommand(onDragEnd.command, {
        ...onDragEnd.params,
        [onDragEnd.params.valueKey]: { x: event.clientX, y: event.clientY },
      });
    },
    [onDragEnd, dispatchCommand],
  );

  useEffect(() => {
    onMenuOpenChange(isMenuOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuOpen]);

  const disableDrag = headerProps.disableDrag;

  const onAddInsertBefore = useCallback(() => {
    if (onInsertBefore && 'command' in onInsertBefore) {
      handleMenuItemClick(onInsertBefore.command, onInsertBefore.params);
    }
  }, [handleMenuItemClick, onInsertBefore]);

  const onClose = useCallback(() => setIsAddBeforeMenuOpen(false), []);

  return (
    <Stack
      direction={direction[orientation]}
      alignItems="center"
      justifyItems="center"
      gap={1}
      width="100%"
      height="100%"
      draggable={!disableDrag && draggable}
      onDragStart={handleDragStart}
      onDrag={handleDrag}
      onDragEnd={handleDragEnd}
    >
      {onInsertBefore && (
        <Box ref={addBeforeMenuButtonRef} {...addBeforeMenuAnchorProps}>
          <AddCircleOutline sx={sx} color="inherit" onClick={onAddInsertBefore} />
          {'items' in onInsertBefore && (
            <ControlledMenu
              state={isAddBeforeMenuOpen ? 'open' : 'closed'}
              anchorRef={addBeforeMenuButtonRef}
              onClose={onClose}
              transition
              direction="bottom"
              gap={12}
              overflow="visible"
              portal
              menuStyle={{ zIndex: 100000 }}
            >
              {onInsertBefore.items.map((item) => (
                <MenuItem key={item.id} onClick={() => handleMenuItemClick(item.command, item.params)}>
                  {item.icon && <Icon name={item.icon} />}
                  {item.label}
                </MenuItem>
              ))}
            </ControlledMenu>
          )}
        </Box>
      )}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        bgcolor={bgColor}
        borderRadius="4px"
        sx={{
          flexGrow: 1,
          cursor: !disableDrag ? '' : 'grab',
          userSelect: 'none',
          height: isGroupHeader ? 28 : 20,
          width: isGroupHeader ? 28 : 20,
          position: 'relative',
        }}
      >
        {!disableDrag && <Icon name="GripHorizontal" />}
        <Box
          ref={menuRefs.setReference}
          position="absolute"
          top={orientation === 'horizontal' ? '50%' : 16}
          right={orientation === 'horizontal' ? 0 : undefined}
          left={isVertical ? '50%' : undefined}
          sx={{
            cursor: 'pointer',
            transform: 'translate(-50%, -50%)',
            backgroundColor: isMenuOpen ? 'primary.main' : '',
            height: isVertical ? undefined : 18,
            width: isVertical ? 18 : undefined,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: isMenuOpen ? 'white' : 'action',
            borderRadius: '4px',
          }}
          {...getReferenceProps()}
        >
          {isVertical ? <MoreVert color="inherit" /> : <MoreHoriz color="inherit" />}
          {headerProps.menu && isMenuOpen && (
            <List
              ref={menuRefs.setFloating}
              style={{
                ...menuFloatingStyles,
                backgroundColor: theme.palette.background.paper,
                boxShadow: theme.shadows[4],
                zIndex: 100000,
              }}
              {...getFloatingProps()}
            >
              {headerProps.menu.items.map((item) => (
                <MenuItem
                  key={item.id}
                  style={{ gap: '8px' }}
                  onClick={() => handleMenuItemClick(item.command, item.params)}
                >
                  {item.icon && <Icon name={item.icon} color="grey" />}
                  <Typography color="text.primary">{item.label}</Typography>
                </MenuItem>
              ))}
            </List>
          )}
        </Box>
      </Box>
      {onInsertAfter && (
        <Box ref={addAfterMenuButtonRef} {...addAfterMenuAnchorProps}>
          <AddCircleOutline
            sx={sx}
            color="inherit"
            onClick={() => {
              if ('command' in onInsertAfter) {
                handleMenuItemClick(onInsertAfter.command, onInsertAfter.params);
              }
            }}
          />
          {'items' in onInsertAfter && onInsertAfter.items && (
            <ControlledMenu
              state={isAddAfterMenuOpen ? 'open' : 'closed'}
              anchorRef={addAfterMenuButtonRef}
              onClose={() => setIsAddAfterMenuOpen(false)}
              transition
              direction="bottom"
              gap={12}
              overflow="visible"
              portal
              menuStyle={{ zIndex: 100000 }}
            >
              {onInsertAfter.items.map((item) => (
                <MenuItem key={item.id} onClick={() => handleMenuItemClick(item.command, item.params)}>
                  {item.icon && <Icon name={item.icon} />}
                  {item.label}
                </MenuItem>
              ))}
            </ControlledMenu>
          )}
        </Box>
      )}
    </Stack>
  );
});
Header.displayName = 'Header';

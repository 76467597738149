import { SingleSourceModel } from '../SingleSourceModel';
import { AddEntitiesToLabelCommand } from '../commands/labels/AddEntitiesToLabelCommand';
import { CreateLabelCommand, CreateLabelParams } from '../commands/labels/CreateLabelCommand';
import { DeleteLabelCommand } from '../commands/labels/DeleteLabelCommand';
import { RemoveEntitiesFromLabelCommand } from '../commands/labels/RemoveEntitiesFromLabelCommand';
import { UpdateLabelCommand, UpdateLabelParams } from '../commands/labels/UpdateLabelCommand';
import { sid } from '@xspecs/short-id';
import { LabelTemp } from '../entities/EntityType';

export class LabelsInteractor {
  constructor(private readonly model: SingleSourceModel) {}

  createAndSelect(label: LabelTemp, entityId: string) {
    this.model.messageBus.send(CreateLabelCommand, {
      id: sid(),
      name: label.name,
      color: label.color,
      createdBy: this.model.modelFile.activeUser.sub,
      entities: [entityId],
    });
  }

  onSelectedChange(newLabels: LabelTemp[], entityId: string) {
    const selectedLabels = this.model.entityRepository.get(entityId)!.labels;
    const labelsToDeselect = selectedLabels.filter((label) => !newLabels.find((newLabel) => newLabel.id === label.id));
    const labelsToSelected = newLabels.filter(
      (label) => !selectedLabels.find((selectedLabel) => selectedLabel.id === label.id),
    );

    labelsToSelected.forEach((label) => {
      this.model.messageBus.send(AddEntitiesToLabelCommand, {
        labelId: label.id,
        entityIds: [entityId],
      });
    });
    labelsToDeselect.forEach((label) => {
      this.model.messageBus.send(RemoveEntitiesFromLabelCommand, {
        labelId: label.id,
        entityIds: [entityId],
      });
    });
  }

  delete(id: string) {
    this.model.messageBus.send(DeleteLabelCommand, {
      id,
    });
  }

  createNew(params: Omit<CreateLabelParams, 'createdBy'>) {
    this.model.messageBus.send(CreateLabelCommand, {
      ...params,
      createdBy: this.model.modelFile.activeUser.sub,
    });
  }

  update(params: UpdateLabelParams) {
    this.model.messageBus.send(UpdateLabelCommand, params);
  }
}
